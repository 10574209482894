import parseISO from "date-fns/parseISO";
import differenceInMonths from "date-fns/differenceInMonths";
import subDays from "date-fns/subDays";
import addMonths from "date-fns/addMonths";
import isBefore from "date-fns/isBefore";
import isDate from "date-fns/isDate";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import addDays from "date-fns/addDays";
import isWithinInterval from "date-fns/isWithinInterval";
import isString from "lodash/isString";
import { ASSUMED_PERPETUAL_DURATION, LineItem } from "../service/lineitems";

export const DATE_FORMATS = ["yyyy-MMM-dd", "yyyy-MM-dd", "MM-dd-yyyy"] as const;
export type DateFormat = typeof DATE_FORMATS[number];
export const DEFAULT_DATE_FORMAT = "yyyy-MMM-dd";

export function formatDate(value: Date | string | null, dateFormat: DateFormat): string {
  if (!value) return "-";
  if (isString(value)) value = parseISO(value);
  return format(value, dateFormat);
}

export function monthsDuration(date1: string, date2: string): number {
  return differenceInMonths(parseISO(date1), parseISO(date2)) + 1;
}

export function nextEndDate(start: string | Date, months: number = 1): Date | null {
  let startDate: Date;
  if (isDate(start)) {
    startDate = start as Date;
  } else {
    try {
      startDate = parseISO(start as string);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  if (!isValid(startDate)) {
    return null;
  }
  return subDays(addMonths(startDate, months), 1);
}

export function validateEndDate(start: string, end: string): boolean | string {
  let startDate: Date, endDate: Date;
  try {
    [startDate, endDate] = [parseISO(start), parseISO(end)];
  } catch (RangeError) {
    // it does not validate the format, ignore parsing error
    return true;
  }
  // start should be before end
  if (!isBefore(startDate, endDate) || startDate == endDate) return "Start date must be before end date";
  return true;
}

export function financialEndDate(lineItem: LineItem): Date {
  if (lineItem.endDate) {
    return parseISO(lineItem.endDate);
  }
  return addMonths(parseISO(lineItem.startDate), ASSUMED_PERPETUAL_DURATION);
}

export function isWithinClosedInterval(date: Date, interval: { start: Date; end: Date }): boolean {
  return isWithinInterval(date, { start: interval.start, end: addDays(interval.end, 1) });
}
