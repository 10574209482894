import * as React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

import { ConfirmedButton } from "../../../../components/ConfirmedButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import makeStyles from "@mui/styles/makeStyles";
import { ContractDocument, ContractDocumentType } from "../../../../service/contracts";
import { useDictionary } from "../../../../service/dictionary";
import { useEffect, useState } from "react";
import { Modal } from "../../../../components/Modal";
import formatISO from "date-fns/formatISO";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { Dictionary } from "../../../../service/dictionary";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { usePermissions } from "../../../../service/auth";
import { ContentBox } from "../../../../components/ContentBox";

export interface Props {
  documents: ContractDocument[];
  onNewDocument: (document: ContractDocument) => void;
  onEditDocument: () => void;
  onDeleteDocument: (index: number) => void;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginBottom: "10px",
  },
  buttons: {
    "text-align": "right",
    paddingRight: "10px",
  },
});

function NewDocumentModal(props: {
  open: boolean;
  onClose: () => void;
  dictionary: Dictionary;
  onNewDocument: (document: ContractDocument) => void;
  onEditDocument: () => void;
  document: ContractDocument | undefined;
}): JSX.Element {
  const [date, setDate] = useState<Date>(new Date());
  const [type, setType] = useState<ContractDocumentType>("order_form");
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    if (props.document) {
      setDate(new Date(props.document.date));
      setType(props.document.type);
      setUrl(props.document.url);
    } else {
      setDate(new Date());
      setType("order_form");
      setUrl("");
    }
  }, [props.document, props.open]);

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div>
        <h2>{props.document ? "Edit Document" : "New Document"}</h2>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DesktopDatePicker
              label={"Date"}
              value={date}
              onChange={setDate as any}
              renderInput={(params: any) => <TextField {...params} />}
              inputFormat="yyyy-MM-dd"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Type" select value={type} onChange={(e) => setType(e.target.value as any)}>
              {Object.entries(props.dictionary.DOCUMENT_TYPES).map((entry) => (
                <MenuItem key={entry[0]} value={entry[0]}>
                  {entry[1]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="URL"
              value={url}
              placeholder="https://drive.google.com/..."
              onChange={(e) => setUrl(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => props.onClose()}>Cancel</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                props.onClose();
                if (!props.document) {
                  props.onNewDocument({
                    date: formatISO(date, { representation: "date" }),
                    type: type,
                    url: url,
                  });
                } else {
                  props.document!.date = formatISO(date, { representation: "date" });
                  props.document!.type = type;
                  props.document!.url = url;
                  props.onEditDocument();
                }
              }}
            >
              {props.document ? "Change" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export function ContractDocuments(props: Props): JSX.Element {
  const { hasPermission } = usePermissions();
  const classes = useStyles();
  const { dictionary } = useDictionary();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalDocument, setModalDocument] = useState<ContractDocument>();

  return (
    <ContentBox header="Documents">
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>PDF</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.documents.length == 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <em>Contract has no documents</em>
                </TableCell>
              </TableRow>
            ) : (
              props.documents.map((document, idx) => (
                <TableRow key={idx}>
                  <TableCell>{document.date}</TableCell>
                  <TableCell>{dictionary.DOCUMENT_TYPES[document.type] || document.type}</TableCell>
                  <TableCell>
                    <Button
                      startIcon={<OpenInNew />}
                      variant="outlined"
                      size="small"
                      href={document.url}
                      target="_blank"
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell className={classes.buttons}>
                    {hasPermission("basicEditing") && (
                      <ButtonGroup color="primary" variant="outlined" aria-label="outlined primary button group">
                        <ConfirmedButton
                          confirmationTitle="Delete"
                          confirmationText={`Are you sure you want to remove the document ${document.url} from this contract?`}
                          startIcon={<DeleteIcon />}
                          size="small"
                          onConfirmed={() => {
                            props.onDeleteDocument(idx);
                          }}
                        >
                          Remove
                        </ConfirmedButton>
                        <Button
                          startIcon={<EditIcon />}
                          size="small"
                          onClick={() => {
                            setModalDocument(document);
                            setModalOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                      </ButtonGroup>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {hasPermission("basicEditing") && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            setModalDocument(undefined);
            setModalOpen(true);
          }}
        >
          Add document
        </Button>
      )}

      <NewDocumentModal
        document={modalDocument}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        dictionary={dictionary}
        onNewDocument={props.onNewDocument}
        onEditDocument={props.onEditDocument}
      />
    </ContentBox>
  );
}
