import { useDictionary } from "../../../../service/dictionary";
import AddIcon from "@mui/icons-material/Add";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import MovingIcon from "@mui/icons-material/Moving";
import CachedIcon from "@mui/icons-material/Cached";
import ConstructionIcon from "@mui/icons-material/Construction";
import SvgIcon from "@mui/material/SvgIcon";
import { DealType } from "../../../../service/contracts";
import Label from "./Label";

export const DEAL_TYPES: {
  [key in DealType]: { label: string; shortLabel: string; color: string; icon?: typeof SvgIcon };
} = {
  new_business: {
    label: "NB",
    shortLabel: "NB",
    color: "#4472c4",
    icon: AddIcon,
  },
  upsell: {
    label: "Upsell",
    shortLabel: "U",
    color: "#ffbf00",
    icon: KeyboardDoubleArrowUpIcon,
  },
  upsell_new: {
    label: "Upsell New",
    shortLabel: "UN",
    color: "#00b2a4",
    icon: KeyboardDoubleArrowUpIcon,
  },
  growth: {
    label: "Growth",
    shortLabel: "G",
    color: "#71ad47",
    icon: MovingIcon,
  },
  renewal: {
    label: "Renewal",
    shortLabel: "R",
    color: "#ed7d32",
    icon: CachedIcon,
  },
  restructure: {
    label: "Restructure",
    shortLabel: "RES",
    color: "#e225b9",
    icon: ConstructionIcon,
  },
};

export const DEFAULT_COLOR = "#8498b0";

export default function DealTypeLabel(props: {
  dealType: DealType;
  filled?: boolean;
  noIcon?: boolean;
  shortLabel?: boolean;
}): JSX.Element {
  const { dictionary } = useDictionary();
  const { dealType } = props;
  const dealTypeConfig = DEAL_TYPES[dealType];
  const dictLabel = dictionary.DEAL_TYPES[dealType];
  const label = (props.shortLabel ? dealTypeConfig?.shortLabel : dealTypeConfig?.label) || dictLabel;
  const color = dealTypeConfig?.color || DEFAULT_COLOR;
  return (
    <Label
      tooltip={dictLabel}
      label={label}
      color={color}
      filled={props.filled}
      icon={props.noIcon ? undefined : dealTypeConfig?.icon}
    />
  );
}
