import * as React from "react";
import { useHistory } from "react-router-dom";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

export function NotFound(params: {}): JSX.Element {
  const history = useHistory();
  return (
    <Alert severity="error">
      <AlertTitle>Object not found</AlertTitle>
      <Button variant="contained" onClick={() => history.goBack()}>
        GO BACK
      </Button>
    </Alert>
  );
}
