import * as React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { usePermissions, PERMISSIONS, Permission } from "../../service/auth";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      paddingBottom: "1rem",
    },
  })
);

type Permissions = Record<Permission, boolean>;

const FakePermissionsCard = () => {
  const { hasPermission, setPermission } = usePermissions();

  const getInitialPermissions = () => {
    const initialPermissions: Partial<Permissions> = {};
    PERMISSIONS.forEach((p) => {
      initialPermissions[p] = hasPermission(p);
    });

    return initialPermissions as Permissions;
  };

  const [permissions, setPermissions] = useState<Permissions>(getInitialPermissions());

  const handleChange = (p: Permission, isChecked: boolean) => {
    setPermissions({ ...permissions, [p]: isChecked });
    setPermission(p, isChecked);
  };

  const getFormControl = (p: Permission) => {
    const getInnerControl = (isDisabled: boolean) => (
      <FormControlLabel
        disabled={isDisabled}
        control={<Checkbox checked={permissions[p]} onChange={(e, isChecked) => handleChange(p, isChecked)} />}
        label={p}
      />
    );

    if (p !== "readonly") return <div key={p}>{getInnerControl(false)}</div>;

    return (
      <Tooltip key={p} title="Users must always have readonly permissions.">
        <span>{getInnerControl(true)}</span>
      </Tooltip>
    );
  };

  return (
    <Card>
      <CardHeader title="Fake permissions" />
      <CardContent>
        <FormGroup>{PERMISSIONS.map(getFormControl)}</FormGroup>
      </CardContent>
    </Card>
  );
};

const DebugScreen = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.header} variant="h4">
        Debug
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <FakePermissionsCard />
        </Grid>
      </Grid>
    </>
  );
};

export { DebugScreen };
