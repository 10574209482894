import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import FileCopy from "@mui/icons-material/FileCopy";

import type { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { LineItem } from "../../../service/lineitems";
import MoneyIcon from "@mui/icons-material/AttachMoney";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useDictionary } from "../../../service/dictionary";
import { usePreferences } from "../../../service/preferences";
import { ConfirmedButton } from "../../../components/ConfirmedButton";
import { LineItemAccordionInfo } from "./LineItemAccordionInfo";
import { LineItemAccordionForm, LineItemFormFields } from "./LineItemAccordionForm";
import { Contract } from "../../../service/contracts";
import { LineItemAccordionConnector } from "./LineItemAccordionConnector";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    column: {
      flexBasis: "33.33%",
    },
    column1: {
      flexBasis: "20%",
    },
    column2: {
      flexBasis: "50%",
    },
    column3: {
      flexBasis: "30%",
      "text-align": "right",
      paddingRight: "10px",
    },
  })
);

export interface LineItemAccordionProps {
  requirements?: { [key: string]: any };
  accountId: string;
  lineItem: LineItem;
  onChange?: (data: LineItemFormFields) => Promise<boolean>;
  onDelete?: () => Promise<boolean>;
  onDuplicate?: (item: LineItem) => Promise<boolean>;
  deleteLabel?: string;
  expanded: boolean;
  onExpand?: (expanded: boolean) => void;
  edit?: boolean;
  connect?: boolean;
  readOnly?: boolean;
  refreshable?: boolean;
  onRefresh?: () => Promise<boolean>;
  newItem?: boolean;
  errors?: { [key: string]: string[] };
  onEdit?: (edit: boolean) => void;
  onConnect?: (connect: boolean) => void;
  goToContract?: any;
  contract: Contract | null;
  inactive?: boolean;
}

export const GROUPED_FIELDS: [string, (keyof LineItem)[]][] = [
  ["product", ["productType", "edition", "addons", "integrationPath", "platforms", "symbologies"]],
  ["useScope", ["userType", "useCasePack", "useCaseSuite", "useCase", "deviceTypes", "geography"]],
  [
    "usage",
    [
      "usageMetric",
      "maxDevicesPerYear",
      "maxDevicesPerMonth",
      "maxScansPerYear",
      "maxActivations",
      "maxScansPerDevicePerYear",
      "maxStores",
      "storeType",
      "storeEngagementTier",
      "avgMonthlyDevicesPerStore",
      "avgScansPerStore",
    ],
  ],
  ["license", ["licenseType", "startDate", "endDate"]],
  ["dataTransmission", ["communicationSalesPackage", "analytics", "anonymizeIp"]],
  ["additional", ["staged", "pilot", "purposeType", "terminated"]],
  ["support", ["supportLevel"]],
  ["fee", ["currency", "salesPrice"]],
];

export const GROUPED_FIELDS_MAINTENANCE: [string, (keyof LineItem)[]][] = [
  ["license", ["startDate", "endDate"]],
  ["additional", ["purposeType"]],
  ["fee", ["currency", "salesPrice"]],
];

export function LineItemAccordion(props: LineItemAccordionProps): JSX.Element {
  const classes = useStyles();
  const { dictionary } = useDictionary();
  const { formatDate, formatMoney } = usePreferences();

  function handleActionClick(e: MouseEvent, handler: () => void) {
    // stop propagation because buttons are in the accordion header
    e.stopPropagation();
    handler();
  }

  const li = props.lineItem;
  return (
    <Accordion
      expanded={props.expanded}
      onChange={(_, expanded) => (props.onExpand && !props.edit && !props.connect ? props.onExpand(expanded) : null)}
      style={
        props.inactive
          ? {
              background: "repeating-linear-gradient(45deg, #eeeeee, #eeeeee 8px, #dedddd 8px, #dedddd 16px)",
            }
          : {}
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <div className={classes.column1}>
          <Typography className={classes.heading}>
            {props.newItem ? (
              <em>New line item</em>
            ) : li.purposeType === "product" ? (
              <>
                {dictionary.PRODUCT_TYPES[li.productType] || li.productType}
                &nbsp;+&nbsp;
                {dictionary.INTEGRATION_PATHS[li.integrationPath] || li.integrationPath}
              </>
            ) : (
              <i>Maintenance plan</i>
            )}
          </Typography>
        </div>
        <div className={classes.column2}>
          <Typography className={classes.secondaryHeading}>
            {!props.newItem && (
              <>
                <strong>From:</strong> {formatDate(li.startDate)}&nbsp;&nbsp;
                <strong>To:</strong> {li.endDate ? formatDate(li.endDate) : "∞"}&nbsp;&nbsp;
                <br />
                <strong>Total fee:</strong> {formatMoney(li.salesPrice, li.currency)}&nbsp;&nbsp;
                <strong>Annual fee:</strong> {formatMoney(li.annualFee, li.currency)}
              </>
            )}
          </Typography>
        </div>
        <div className={classes.column3}>
          <ButtonGroup color="primary" variant="outlined" aria-label="outlined primary button group">
            {props.onEdit && !props.readOnly && (
              <Button
                disabled={props.connect || props.edit}
                startIcon={<EditIcon />}
                size="small"
                onClick={(e) => {
                  handleActionClick(e as any, () => props.onEdit!(true));
                }}
              >
                Edit
              </Button>
            )}
            {props.onEdit && !props.readOnly && (
              <Button
                disabled={props.connect || props.edit}
                startIcon={<FileCopy />}
                size="small"
                onClick={(e) => {
                  handleActionClick(e as any, () => {
                    props.onDuplicate!(props.lineItem);
                  });
                }}
              >
                Duplicate
              </Button>
            )}
            {!props.newItem && props.onDelete && (
              <ConfirmedButton
                confirmationTitle="Confirmation"
                confirmationText={`Are you sure you want to remove the line item ${li.productType} from this subscription?`}
                onConfirmed={() => props.onDelete!()}
                disabled={props.connect || props.edit}
                startIcon={<DeleteIcon />}
                clicksStopPropagation
                size="small"
              >
                {props.deleteLabel || "Remove"}
              </ConfirmedButton>
            )}
            {props.onRefresh && props.refreshable && (
              <Button
                // disabled={props.edit}
                disabled={props.connect || props.edit}
                startIcon={<UpdateIcon />}
                size="small"
                onClick={(e) => {
                  handleActionClick(e as any, () => props.onRefresh!());
                }}
              >
                SFDC refresh
              </Button>
            )}
            {props.onConnect && !props.readOnly && (
              <Tooltip title={props.lineItem.id == null ? "Line item must be created first" : ""}>
                <Button
                  disabled={props.lineItem.id == null || props.connect || props.edit}
                  startIcon={<AccountTreeIcon />}
                  size="small"
                  onClick={(e) => {
                    handleActionClick(e as any, () => props.onConnect!(true));
                  }}
                >
                  Connect
                </Button>
              </Tooltip>
            )}
            {props.goToContract && (
              <Button startIcon={<MoneyIcon />} size="small" onClick={props.goToContract}>
                View Contract
              </Button>
            )}
          </ButtonGroup>
        </div>
      </AccordionSummary>
      {props.connect ? (
        <LineItemAccordionConnector
          lineItem={props.lineItem}
          accountId={props.accountId}
          onClose={() => (props.onConnect ? props.onConnect(false) : null)}
          onCancel={() => (props.onConnect ? props.onConnect(false) : null)}
        />
      ) : props.edit ? (
        <LineItemAccordionForm
          errors={props.errors}
          lineItem={props.lineItem}
          onCancel={() => (props.newItem ? props.onDelete!() : props.onEdit!(false))}
          onChange={props.onChange as any}
          onClose={() => (props.onEdit ? props.onEdit(false) : null)}
          requirements={props.requirements as any}
          contract={props.contract}
        />
      ) : (
        <LineItemAccordionInfo errors={props.errors} lineItem={props.lineItem} />
      )}
    </Accordion>
  );
}
