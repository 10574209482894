import { useServiceResourceList } from "../service/api";
import { objectsById, SalesforceObject, SalesforceQueryResult } from "./client";

export interface SalesforceOpportunityLineItem extends SalesforceObject {
  product2Id: string;
  product2: {
    name: string;
  };
  pricebookEntryId: string;
  currencyIsoCode: string;
  grandTotalFee: number;
  startDate: string;
  endDate: string;
  contractLength: number;
  subscriptionType: "Perpetual" | "Annual" | "Other";
  platform: string;
  typeOfAgreement: "Pilot" | "Production";
  deviceSupport: string;
  supportPlan: string;
  addOns: string;
  symbologies: string;
  integrationPath:
    | "Native SDK"
    | "Web SDK"
    | "Enterprise Browser"
    | "Keyboard Wedge"
    | "Scandit Express"
    | "ID Bolt"
    | "Cloud";
  usageMetric:
    | "Active devices per year by subscription"
    | "Stores"
    | "Active devices per month by subscription"
    | "Scans per year"
    | "Activations"
    | "Active devices per year by license key (legacy)"
    | "Active devices per month by license key (legacy)"
    | "Custom";
  allowedUserTypes:
    | "Worker (B2E)"
    | "Autonomous System (B2E)"
    | "Business Consumer (B2B)"
    | "Individual Consumer (B2C)"
    | "Self-Scanning [of Individual Consumer (B2C)]";
  useCases: string;
  useCaseSuite: string;
  useCasePack: string;
  serverCommunicationSettings: string;
  analyticsSettings: "With analytics" | "No analytics" | "With analytics, No scan content";
  iPAddressSettings: "Default" | "Anonymized IP";
  // https://scandit.my.salesforce.com/00N6M00000AR8aE?setupid=OpportunityLineItemFields
  variant:
    | "ID Scan Core Edition - MRZ"
    | "ID Scan Core Edition - PDF417"
    | "ID Scan Standard Edition"
    | "ID Scan Advanced Edition"
    | "ID Validate"
    | "Core Edition"
    | "Standard Edition"
    | "Advanced Edition"
    | "Mobile Price and Promotion Label Execution"
    | "Autonomous Pallet Localization"
    | "Autonomous Product Localization"
    | "Autonomous Price and Promotion Label Execution"
    | "Other";
  lineItemOfStagedDeal: "True" | "False";
  maxScansPerYear: number;
  maxActiveDevicesPerMonth: number;
  maxActivations: number;
  maxscansperactivedeviceperyear: number;
  numberOfStores: number;
  numberOfScans: number;
  activeDevicesPerPlatform: number;
  storeType: "C-Store & City Market" | "Super Market" | "Large Super Market" | "Hypermarket";
  engagementTier: "Low" | "Middle" | "High" | "Entry" | "Starter" | "Scaler" | "Unlimited";
  territory: string;
}

export function useSalesforceOpportunityLineItems(
  opportunityId: string
): SalesforceQueryResult<SalesforceOpportunityLineItem> {
  const { data, isLoading, isError } = useServiceResourceList<SalesforceOpportunityLineItem>(
    `/v1/salesforce/lineitems/`,
    1,
    { opportunity_id: opportunityId }
  );

  return {
    result: data ?? null,
    resultById: data ? objectsById(data) : null,
    isLoading: isLoading,
    isError: isError,
  };
}
