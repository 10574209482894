import {
  CurrencyValue,
  getRequestUrl,
  ResourceExportResult,
  ResourceListResult,
  ResourceResult,
  useFetcher,
  useServiceResource,
  useServiceResourceExport,
  useServiceResourceList,
} from "./api";

export interface Account {
  id: string;
}

export interface AccountExport extends Account {
  id: string;
  name: string;
  vertical: string;
  isEnterprise: boolean | null;
  arr: CurrencyValue;
  mrr: CurrencyValue;
  ARR_EUR: string;
  ARR_CHF: string;
  ARR_USD: string;
  ARR_GBP: string;
  ARR_JPY: string;
  ARR_PLN: string;
  MRR_EUR: string;
  MRR_CHF: string;
  MRR_USD: string;
  MRR_GBP: string;
  MRR_JPY: string;
  MRR_PLN: string;
}

export function useAccounts(page?: number): ResourceListResult<Account> {
  return useServiceResourceList<Account>("/v1/accounts/", page);
}

export function useAccountsExport(): ResourceExportResult<AccountExport> {
  return useServiceResourceExport<AccountExport>("account");
}

export function useAccount(accountId: string): ResourceResult<Account> {
  return useServiceResource<Account>(`/v1/accounts/${accountId}/`);
}

export function useAccountActions() {
  const fetcher = useFetcher();
  return {
    merge: async (accountIdSource: string, accountIdDestination: string) => {
      const result = await fetcher(getRequestUrl(`/v1/accounts/${accountIdSource}/merge/`), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ destination_account_id: accountIdDestination }),
      });
      return result;
    },
  };
}
