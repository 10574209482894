// based on https://github.com/mui/mui-x/blob/43473bef31d1e212069929668a943f92b2b8218a/packages/grid/x-data-grid-generator/src/renderer/renderProgress.tsx
import * as React from "react";
import clsx from "clsx";
import { styled } from "@mui/material/styles";

interface ProgressBarProps {
  value: number;
}

const Root = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: 26,
  borderRadius: 2,
}));

const Value = styled("div")({
  position: "absolute",
  lineHeight: "24px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const Bar = styled("div")({
  height: "100%",
  "&.low": {
    backgroundColor: "#f44336",
  },
  "&.medium": {
    backgroundColor: "#efbb5aa3",
  },
  "&.high": {
    backgroundColor: "#088208a3",
  },
});

export default function ProgressBar(props: ProgressBarProps) {
  const { value } = props;
  const percentage = Math.round(value * 100);

  return (
    <Root>
      <Value>{`${percentage.toLocaleString()} %`}</Value>
      <Bar
        className={clsx({
          low: percentage < 30,
          medium: percentage >= 30 && percentage <= 70,
          high: percentage > 70,
        })}
        style={{ maxWidth: `${percentage}%` }}
      />
    </Root>
  );
}
