import * as React from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export function ValidationErrors(props: { title: string; errors: { [key: string]: string[] } }): JSX.Element {
  const nonFieldErrors: string[] = props.errors.nonFieldErrors || [];
  const fieldErrors: [string, string[]][] = Object.entries(props.errors).filter((e) => e[0] != "nonFieldErrors");

  return (
    <Alert severity="error">
      <AlertTitle>{props.title}</AlertTitle>
      Please correct the following issues:
      <br />
      {nonFieldErrors.map((e, i) => (
        <p key={i}>{e}</p>
      ))}
      {fieldErrors.map((entry, i) => (
        <ul key={i}>
          <li>
            {entry[0]}
            <ul>
              {entry[1].map((e, i2) => (
                <li key={i2}>{e}</li>
              ))}
            </ul>
          </li>
        </ul>
      ))}
    </Alert>
  );
}
