import { ResourceResult, useServiceResource } from "./api";
import { SubscriptionProduct } from "./subscriptions";

export function useSubscriptionProducts(
  accountId: string,
  subscriptionId: number,
  page?: number,
  params?: { [key: string]: string }
): ResourceResult<SubscriptionProduct[]> {
  params ||= {};
  return useServiceResource<SubscriptionProduct[]>(
    `/v1/accounts/${accountId}/subscriptions/${subscriptionId}/products/`,
    page,
    params
  );
}
