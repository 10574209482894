import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { ContentBox } from "../../../components/ContentBox";
import { Select } from "../../../components/Select";
import { useDictionary } from "../../../service/dictionary";
import { useNotifications } from "../../../notifications";
import { UsageGroup, useUsageGroupsModifier } from "../../../service/usagegroups";
import { Header } from "../../Header";
import { ValidationErrors } from "../../../components/ValidationErrors";
import { ValidationError } from "../../../service/api";
import { useState } from "react";

interface FormValues {
  name: string;
  usageMetric: string;
}

export function Create() {
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();
  const { addNotification } = useNotifications();
  const { dictionary } = useDictionary();
  const { control, handleSubmit } = useForm<FormValues>({});
  const { create } = useUsageGroupsModifier(accountId);
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);

  const onSubmit = async (values: FormValues) => {
    try {
      const usageGroup = await create({
        accountId: accountId,
        name: values.name,
        usageMetric: values.usageMetric,
      });

      history.push({
        pathname: `/accounts/${accountId}/usage-groups/${usageGroup.id}/`,
      });
    } catch (e) {
      if (e instanceof ValidationError) {
        setErrors(e.errors);
        addNotification({
          text: "Usage group could not be created.",
          level: "error",
        });
        return;
      }
      throw e;
    }
  };

  return (
    <>
      <Header accountId={accountId} page="New Usage Group" />
      <ContentBox>
        {errors && <ValidationErrors title="Invalid subscription" errors={errors} />}

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container item spacing={4}>
            <Grid item xs={7}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <TextField
                      error={!!error}
                      helperText={error ? error.message || "Please provide a correct value" : null}
                      required
                      label="Usage Group Name"
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Select
                control={control as any}
                name="usageMetric"
                label="Usage metric"
                required
                rules={{ required: true }}
                default=""
              >
                {
                  [
                    <MenuItem key="" value="">
                      -
                    </MenuItem>,
                  ].concat(
                    Object.entries(dictionary.USAGE_METRICS).map((entry) => (
                      <MenuItem key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </MenuItem>
                    ))
                  ) as any
                }
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Button variant="text" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ContentBox>
    </>
  );
}
