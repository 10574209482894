import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import * as React from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UsecaseFormValues } from "./types";

export const UsageAutocomplete = (props: {
  name: "useCasePack" | "useCaseSuite" | "useCase";
  control: Control<UsecaseFormValues>;
  options: string[];
  defaultValue?: string;
}) => {
  const { t } = useTranslation();

  const onChangeNullabilityHelper = (
    field: ControllerRenderProps<UsecaseFormValues, "useCasePack" | "useCaseSuite" | "useCase">,
    value: string | null
  ) => {
    // The MUI Autocomplete starts with the default value "", but its "clear field" button
    // resets the field to null, not "". Normalizing this behavior here makes the value
    // easier to validate down the road.

    const nonNullValue = value === null ? "" : value;
    return field.onChange(nonNullValue);
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue || ""}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <Autocomplete
            {...field}
            freeSolo
            blurOnSelect
            autoSelect
            options={props.options}
            onChange={(_, value) => onChangeNullabilityHelper(field, value)}
            renderInput={(params) => (
              <TextField {...params} label={t(`fields.${props.name}.label`)} InputLabelProps={{ shrink: true }} />
            )}
          />
        </FormControl>
      )}
    />
  );
};
