import * as React from "react";
import OpenInNew from "@mui/icons-material/OpenInNew";
import type { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { config } from "../config";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkContainer: {
      display: "flex",
    },
    displayValue: {
      alignSelf: "center",
    },
    link: {
      paddingLeft: ".25rem",
      paddingBottom: "1rem",
    },
  })
);

interface SalesforceLinkProps {
  sfdcReference: string | null;
  children: React.ReactNode;
}

export function makeSalesforceLink(sfdcReference: string) {
  return `${config.salesforceUrl}${sfdcReference}`;
}

const SalesforceLink = React.memo((props: SalesforceLinkProps) => {
  const classes = useStyles();

  if (props.sfdcReference === null) {
    return <>{props.children}</>;
  }

  const targetUrl = makeSalesforceLink(props.sfdcReference);

  return (
    <span className={classes.linkContainer}>
      <div className={classes.displayValue}>{props.children}</div>
      <div className={classes.link}>
        <Tooltip title="Go to SFDC">
          <a href={targetUrl} target="_blank" rel="noopener noreferrer">
            <OpenInNew fontSize="small" color="secondary" />
          </a>
        </Tooltip>
      </div>
    </span>
  );
});

export { SalesforceLink };
