import { CurrencyValue, ResourceResult, useServiceResource } from "./api";

export interface RevenueValue {
  value: string;
  year: number;
  month: number;
}

export interface MonthlyRevenue {
  currency: string | null;
  monthly: [RevenueValue];
}

export function useRecurringRevenue(
  accountId: string,
  contractId: string,
  type: string
): ResourceResult<CurrencyValue> {
  return useServiceResource<CurrencyValue>(`/v1/accounts/${accountId}/contracts/${contractId}/${type}/`);
}

export function useMonthlyRevenue(accountId: string, contractId: string): ResourceResult<MonthlyRevenue> {
  return useServiceResource<MonthlyRevenue>(`/v1/accounts/${accountId}/contracts/${contractId}/revenue/`);
}
