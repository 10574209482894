import * as React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { AuthProvider } from "./auth";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import theme from "./theme";
import { NotificationsProvider } from "./notifications";
import { store } from "./store/store";
import { Provider } from "react-redux";

import { InnerApp } from "./InnerApp";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { config } from "./config";
import { LicenseInfo } from "@mui/x-license-pro/utils/licenseInfo";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

if (config.muiLicenseKey) {
  LicenseInfo.setLicenseKey(config.muiLicenseKey);
}

export default function App() {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <GoogleOAuthProvider clientId={config.googleClientId}>
              <AuthProvider>
                <NotificationsProvider>
                  <InnerApp />
                </NotificationsProvider>
              </AuthProvider>
            </GoogleOAuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}
