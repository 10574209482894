import * as React from "react";

import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";

import { useHistory } from "react-router-dom";
import { useNotifications } from "../../notifications";
import { useDispatch } from "react-redux";
import { logout } from "../../store/authSlice";

export default function LogoutButton(): JSX.Element {
  const history = useHistory();
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    addNotification({
      level: "success",
      text: "You were successfully logged out.",
    });
    history.push("/sign-in");
  };

  return (
    <IconButton aria-controls="user-menu" aria-haspopup="true" onClick={handleLogout} color="inherit" size="large">
      <Tooltip title="Logout">
        <LogoutIcon />
      </Tooltip>
    </IconButton>
  );
}
