import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import * as React from "react";
import { useDictionary } from "../../../service/dictionary";
import { LineItem } from "../../../service/lineitems";
import PieChartIcon from "@mui/icons-material/PieChart";

export const DefaultUseCase = (props: { lineitem: LineItem; usage: number }) => {
  const { dictionary } = useDictionary();
  return (
    <Card>
      <CardContent>
        <Grid container item xs={12}>
          <Grid item xs={5}>
            {dictionary.USER_TYPES[props.lineitem.userType] || props.lineitem.userType}
          </Grid>
          <Grid item xs={1}>
            <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start">
              <PieChartIcon fontSize="small" />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {props.usage}%
          </Grid>
        </Grid>
        <Divider />
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <strong>Usecase Pack:</strong>
          </Grid>
          <Grid item xs={6}>
            {props.lineitem.useCasePack}
          </Grid>
          <Grid item xs={4}>
            <strong>Usecase Suite:</strong>
          </Grid>
          <Grid item xs={6}>
            {props.lineitem.useCaseSuite}
          </Grid>
          <Grid item xs={4}>
            <strong>Usecase:</strong>
          </Grid>
          <Grid item xs={6}>
            {props.lineitem.useCase}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
