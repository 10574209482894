import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import * as React from "react";
import { useParams } from "react-router";
import { usePermissions } from "../../../service/auth";
import { ContentBox } from "../../../components/ContentBox";
import { useDictionary } from "../../../service/dictionary";
import { useContract } from "../../../service/contracts";
import { LineItem, useLineItems } from "../../../service/lineitems";
import { Header } from "../../Header";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import { Dictionary } from "../../../service/dictionary";
import { useAnnotationModifier, useAnnotations, UseCaseAnnotation } from "../../../service/usecaseannotations";
import { ValidationError } from "../../../service/api";
import { ValidationErrors } from "../../../components/ValidationErrors";
import { DefaultUseCase } from "./DefaultUseCase";
import { CustomUsecases } from "./CustomUseCases";
import { UsecaseFormValues } from "./types";
import { UseCaseForm } from "./UseCaseForm";

function lineItemName(lineitem: LineItem, dictionary: Dictionary): string {
  return `${dictionary.PRODUCT_TYPES[lineitem.productType] || lineitem.productType} + ${
    dictionary.INTEGRATION_PATHS[lineitem.integrationPath] || lineitem.integrationPath
  }`;
}

function calculateUsage(annotations: UseCaseAnnotation[] | undefined): number {
  return 100 - (annotations?.reduce((prev, curr) => prev + Number(curr.percentage), 0) || 0);
}

function LineItemUsecase(props: {
  lineitem: LineItem;
  creatingAnnotation: number | undefined;
  setCreatingAnnotation: React.Dispatch<React.SetStateAction<number | undefined>>;
  editingAnnotation: UseCaseAnnotation | undefined;
  setEditingAnnotation: React.Dispatch<React.SetStateAction<UseCaseAnnotation | undefined>>;
  accountId: string;
  setErrors: any;
}): JSX.Element {
  const { dictionary } = useDictionary();
  const { hasPermission } = usePermissions();
  const name = lineItemName(props.lineitem, dictionary);

  if (!props.lineitem.id) return <></>;

  const annotationModifier = useAnnotationModifier(props.accountId, props.lineitem.id);
  const { data: customAnnotations, mutate: mutateAnnotations } = useAnnotations(props.accountId, props.lineitem.id);

  const totalUsage = calculateUsage(customAnnotations);

  const handleAdd = () => {
    props.setCreatingAnnotation(props.lineitem.id || undefined);
  };

  const submitForm = async (data: UsecaseFormValues) => {
    const annotation: UseCaseAnnotation = { ...data, lineItem: props.lineitem.id! };
    try {
      await annotationModifier.create(annotation);
      await mutateAnnotations();
      discardForm();
    } catch (error) {
      if (error instanceof ValidationError) {
        props.setErrors(error.errors);
      }
    }
  };

  const discardForm = (): void => {
    props.setCreatingAnnotation(undefined);
  };

  const discardEdit = (): void => {
    props.setEditingAnnotation(undefined);
  };

  const getAddButton = () => {
    const getButton = (disabled: boolean) => (
      <Grid container item xs={1} justifyContent="center" alignItems="center">
        <IconButton onClick={handleAdd} disabled={disabled}>
          <AddCircleIcon fontSize="large" />
        </IconButton>
      </Grid>
    );

    if (totalUsage === 0) {
      return <Tooltip title="Not enough usage percentage available">{getButton(true)}</Tooltip>;
    }

    if (!hasPermission("useCaseAnnotation"))
      return <Tooltip title="You do not have permissions to add annotations.">{getButton(true)}</Tooltip>;

    return getButton(false);
  };

  return (
    <>
      <ContentBox header={name}>
        <Grid style={{ paddingTop: "1em" }} container item xs={12} spacing={4}>
          <Grid item xs={3}>
            <DefaultUseCase lineitem={props.lineitem} usage={totalUsage} />
          </Grid>
          <CustomUsecases
            accountId={props.accountId}
            lineitem={props.lineitem}
            editingAnnotation={props.editingAnnotation}
            setEditingAnnotation={props.setEditingAnnotation}
            onDiscard={discardEdit}
            usageLimit={totalUsage}
            setErrors={props.setErrors}
          />
          {props.creatingAnnotation && props.lineitem.id == props.creatingAnnotation && (
            <Grid item xs={3}>
              <UseCaseForm
                accountId={props.accountId}
                lineitem={props.lineitem}
                onSubmit={submitForm}
                onDiscard={discardForm}
                usageLimit={totalUsage}
              />
            </Grid>
          )}
          {!props.creatingAnnotation && !props.editingAnnotation && getAddButton()}
        </Grid>
      </ContentBox>
    </>
  );
}

export function Show(): JSX.Element {
  const { accountId, contractId } = useParams<{ accountId: string; contractId: string }>();
  const { data: contract, isLoading: contractLoading } = useContract(accountId, parseInt(contractId));
  const { data: lineitems, mutate: mutateLineItems } = useLineItems(accountId, undefined, { contract: contractId });
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);

  const [creatingAnnotation, setCreatingAnnotation] = useState<number>();
  const [editingAnnotation, setEditingAnnotation] = useState<UseCaseAnnotation>();

  return (
    <>
      <Header accountId={accountId} contract={contract} page={"Annotate Usecase Information"} />
      {errors && <ValidationErrors title="Invalid Usecase Annotation" errors={errors} />}
      {lineitems?.map((lineitem) => (
        <LineItemUsecase
          accountId={accountId}
          lineitem={lineitem}
          creatingAnnotation={creatingAnnotation}
          setCreatingAnnotation={setCreatingAnnotation}
          editingAnnotation={editingAnnotation}
          setEditingAnnotation={setEditingAnnotation}
          setErrors={setErrors}
        />
      ))}
    </>
  );
}
