import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import FormControl from "@mui/material/FormControl";
import ButtonGroup from "@mui/material/ButtonGroup";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ContentBox } from "../../../components/ContentBox";
import { CurrencyValue, ValidationError } from "../../../service/api";
import {
  ContractValues,
  DynamicValues,
  useContract,
  useContractValues,
  useContractValuesModifier,
  useDynamicValues,
  useDynamicValuesModifier,
} from "../../../service/contracts";
import { useMonthlyRevenue, useRecurringRevenue } from "../../../service/revenue";
import { Header } from "../../Header";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import TuneIcon from "@mui/icons-material/Tune";
import CancelIcon from "@mui/icons-material/Cancel";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { Control, Controller, useForm } from "react-hook-form";
import formatISO from "date-fns/formatISO";
import { ValidationErrors } from "../../../components/ValidationErrors";
import { usePreferences } from "../../../service/preferences";
import { usePermissions } from "../../../service/auth";
import {
  LineItem,
  RecognizedRevenueOverride,
  useLineItems,
  useRecognizedRevenueOverridesModifier,
  useRecognizedRevenueOverrides,
} from "../../../service/lineitems";
import { useDictionary } from "../../../service/dictionary";

const useStyles = makeStyles({
  tableHead: {
    fontWeight: "bold",
  },
  tableButton: {
    width: "8em",
  },
  tableContainer: {
    marginBottom: "10px",
  },
  financialName: {
    fontWeight: "bold",
  },
  contractButton: {
    width: "10em",
  },
});

interface ContractFormValues {
  tcv?: number;
  acv?: number;
  mcv?: number;
  dcv?: number;
  comment?: string;
}

interface RevenueFormValues {
  startDate?: Date | null;
  endDate?: Date | null;
  arr?: number;
  mrr?: number;
  drr?: number;
  comment?: string;
}

function RecognizedRevenueOverridesForLineItem(props: { accountId: string; lineItem: LineItem }) {
  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const { dateFormat, formatDate, formatMoney } = usePreferences();
  const { dictionary } = useDictionary();
  const canOverride = hasPermission("financialOverride");

  const {
    data: recognizedRevenueOverrides,
    isLoading: isLoadingRecognizedRevenueOverrides,
    mutate: mutateRecognizedRevenueOverrides,
  } = useRecognizedRevenueOverrides(props.accountId, props.lineItem?.id ? props.lineItem.id : -1);
  const RecognizedRevenueOverrideModifier = useRecognizedRevenueOverridesModifier(props.accountId, props.lineItem.id!);

  const [addOverride, setAddOverride] = useState<boolean>(false);

  const today: Date = new Date();
  const tomorrow: Date = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const { control, handleSubmit, reset, setValue } = useForm<RecognizedRevenueOverride>({
    defaultValues: {
      lineItem: props.lineItem.id!,
      currency: props.lineItem.currency,
      startDate: today.toISOString().substring(0, 10),
      endDate: tomorrow.toISOString().substring(0, 10),
      dailyRecognizedRevenue: undefined,
      comment: undefined,
    },
  });

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] } | null>(null);

  const onSubmit = async (formData: RecognizedRevenueOverride) => {
    try {
      await RecognizedRevenueOverrideModifier.create(formData);
      await mutateRecognizedRevenueOverrides();
      reset();
      setAddOverride(false);
      setValidationErrors(null);
    } catch (error) {
      if (error instanceof ValidationError) {
        setValidationErrors(error.errors);
        return;
      }
    }
  };

  const onDelete = async (rro: RecognizedRevenueOverride) => {
    await RecognizedRevenueOverrideModifier.delete(rro);
    await mutateRecognizedRevenueOverrides();
  };

  const lineItemName: string =
    ` [${props.lineItem.id}] ` +
    (dictionary.PRODUCT_TYPES[props.lineItem.productType] || props.lineItem.productType) +
    " + " +
    (dictionary.INTEGRATION_PATHS[props.lineItem.integrationPath] || props.lineItem.integrationPath) +
    " (" +
    formatDate(props.lineItem.startDate) +
    " > " +
    (props.lineItem.endDate ? formatDate(props.lineItem.endDate) : "∞") +
    ")";

  return (
    <ContentBox header={lineItemName}>
      {validationErrors && <ValidationErrors title="Input validation failed" errors={validationErrors} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>From</TableCell>
                <TableCell className={classes.tableHead}>Until</TableCell>
                <TableCell className={classes.tableHead}>Daily Revenue</TableCell>
                <TableCell className={classes.tableHead}>Reason for Override</TableCell>
                <TableCell className={classes.tableHead}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recognizedRevenueOverrides?.map((rro: RecognizedRevenueOverride) => (
                <>
                  <TableRow key={rro.id}>
                    <TableCell>{rro.startDate}</TableCell>
                    <TableCell>{rro.endDate}</TableCell>
                    <TableCell>{rro.dailyRecognizedRevenue}</TableCell>
                    <TableCell>{rro.comment}</TableCell>
                    <TableCell>
                      <Button
                        className={classes.tableButton}
                        variant="outlined"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => onDelete(rro)}
                        disabled={!canOverride}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
              {addOverride && (
                <TableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <DesktopDatePicker
                            {...field}
                            inputFormat={dateFormat}
                            label="Start Date"
                            renderInput={(params: any) => <TextField {...params} />}
                            onChange={(date: any) => {
                              let value;
                              try {
                                value = formatISO(date as any, { representation: "date" });
                              } catch (RangeError) {
                                return;
                              }
                              setValue("startDate", value);
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <DesktopDatePicker
                            {...field}
                            inputFormat={dateFormat}
                            label="End Date"
                            renderInput={(params: any) => <TextField {...params} />}
                            onChange={(date: any) => {
                              let value;
                              try {
                                value = formatISO(date as any, { representation: "date" });
                              } catch (RangeError) {
                                return;
                              }
                              setValue("endDate", value);
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="dailyRecognizedRevenue"
                        control={control}
                        rules={{ min: 0, required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Please provide correct value" : null}
                            type="number"
                            placeholder="0.00"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="comment"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Comment must be set" : null}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Reason"
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <ButtonGroup>
                      <Button
                        className={classes.tableButton}
                        variant="outlined"
                        size="small"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button
                        className={classes.tableButton}
                        variant="outlined"
                        size="small"
                        startIcon={<CancelIcon />}
                        onClick={() => {
                          reset();
                          setAddOverride(false);
                          setValidationErrors(null);
                        }}
                      >
                        Discard
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
      <Button
        startIcon={<TuneIcon />}
        disabled={addOverride || !canOverride}
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setAddOverride(true)}
      >
        Add Override
      </Button>
    </ContentBox>
  );
}

export function DynamicValueBox(props: {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  handleDelete: (values: DynamicValues, index: number) => Promise<void>;
  handleReset: () => void;
  handleAddOverride: () => void;
  values: DynamicValues[] | undefined;
  control: Control<RevenueFormValues, object>;
  addOverride: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { dateFormat } = usePreferences();
  const { hasPermission } = usePermissions();
  const canOverride = hasPermission("financialOverride");

  return (
    <ContentBox header="Override Dynamic Values">
      <form onSubmit={props.handleSubmit}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>From</TableCell>
                <TableCell className={classes.tableHead}>Until</TableCell>
                <TableCell className={classes.tableHead}>ARR</TableCell>
                <TableCell className={classes.tableHead}>MRR</TableCell>
                <TableCell className={classes.tableHead}>DRR</TableCell>
                <TableCell className={classes.tableHead}>Reason for Override</TableCell>
                <TableCell className={classes.tableHead}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.values?.map((value, index) => (
                <TableRow key={index}>
                  <TableCell>{value.startDate}</TableCell>
                  <TableCell>{value.endDate}</TableCell>
                  <TableCell>{value.arr}</TableCell>
                  <TableCell>{value.mrr}</TableCell>
                  <TableCell>{value.drr}</TableCell>
                  <TableCell>{value.comment}</TableCell>
                  <TableCell>
                    <Button
                      className={classes.tableButton}
                      variant="outlined"
                      size="small"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        props.handleDelete(value, index);
                      }}
                      disabled={!canOverride}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {props.addOverride && (
                <TableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="startDate"
                        control={props.control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <DesktopDatePicker
                            {...field}
                            // TODO(RS): Fixme
                            // error={!!error}
                            inputFormat={dateFormat}
                            label="Start Date"
                            renderInput={(params: any) => <TextField {...params} />}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="endDate"
                        control={props.control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <DesktopDatePicker
                            {...field}
                            // TODO(RS): Fixme
                            // error={!!error}
                            inputFormat={dateFormat}
                            label="End Date"
                            renderInput={(params: any) => <TextField {...params} />}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="arr"
                        control={props.control}
                        rules={{ min: 0, required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Please provide correct value" : null}
                            type="number"
                            placeholder="0.00"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="mrr"
                        control={props.control}
                        rules={{ min: 0, required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Please provide correct value" : null}
                            type="number"
                            placeholder="0.00"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="drr"
                        control={props.control}
                        rules={{ min: 0, required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Please provide correct value" : null}
                            type="number"
                            placeholder="0.00"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        name="comment"
                        control={props.control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Comment must be set" : null}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Reason"
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <ButtonGroup>
                      <Button
                        className={classes.tableButton}
                        variant="outlined"
                        size="small"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button
                        className={classes.tableButton}
                        variant="outlined"
                        size="small"
                        startIcon={<CancelIcon />}
                        onClick={props.handleReset}
                      >
                        Discard
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
      <Button
        startIcon={<TuneIcon />}
        disabled={props.addOverride || !canOverride}
        size="small"
        variant="contained"
        color="primary"
        onClick={props.handleAddOverride}
      >
        Add Override
      </Button>
    </ContentBox>
  );
}

export function ValueEdit(): JSX.Element {
  const classes = useStyles();
  const { hasPermission } = usePermissions();
  const canOverride = hasPermission("financialOverride");

  const { accountId, contractId } = useParams<{ accountId: string; contractId: string }>();

  const {
    data: contract,
    mutate: mutateContract,
    isLoading: contractLoading,
  } = useContract(accountId, parseInt(contractId));
  const { data: availableLineItems, isLoading: isLoadingAvaiableLineItems } = useLineItems(accountId, 0, {
    contract: contractId,
  });
  const { data: revenue } = useMonthlyRevenue(accountId, contractId);
  const { data: arr, mutate: mutateArr } = useRecurringRevenue(accountId, contractId, "arr");
  const { data: mrr, mutate: mutateMrr } = useRecurringRevenue(accountId, contractId, "mrr");
  const { data: drr, mutate: mutateDrr } = useRecurringRevenue(accountId, contractId, "drr");
  const { data: contractValues, mutate: mutateContractValues } = useContractValues(accountId, contractId);
  const contractValuesModifier = useContractValuesModifier(accountId, contractId);
  const { data: dynamicValues, mutate: mutateDynamicValues } = useDynamicValues(accountId, contractId);
  const dynamicValuesModifier = useDynamicValuesModifier(accountId, contractId);
  const { data: computedValues } = useContractValues(accountId, contractId, { computed: "true" });

  const [currency, setCurrency] = useState<string>("");
  const [addOverride, setAddOverride] = useState<boolean>(false);
  const [hasContractValues, setHasContractValues] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] } | null>(null);
  const { formatMoney } = usePreferences();

  const {
    control: contractControl,
    handleSubmit: contractSubmit,
    reset: resetContract,
  } = useForm<ContractFormValues>({
    defaultValues: { tcv: undefined, acv: undefined, mcv: undefined, dcv: undefined, comment: undefined },
  });
  const {
    control: revenueControl,
    handleSubmit: revenueSubmit,
    reset: resetRevenue,
  } = useForm<RevenueFormValues>({
    defaultValues: {
      startDate: null,
      endDate: null,
      arr: undefined,
      mrr: undefined,
      drr: undefined,
      comment: undefined,
    },
  });

  const onContractValuesSubmit = async (formData: ContractFormValues) => {
    const data: ContractValues = { ...formData, currency: currency, contract: contract?.id };
    try {
      await contractValuesModifier.create(data);
    } catch (error) {
      if (error instanceof ValidationError) {
        setValidationErrors(error.errors);
        return;
      }
    }
    await mutateContractValues(data);
    await mutateContract();
    setHasContractValues(true);
    resetContract();
  };

  const onRevenueSubmit = async (formData: RevenueFormValues) => {
    const { startDate, endDate, ...values } = { ...formData };
    if (startDate && endDate) {
      const formattedStart = formatISO(startDate, { representation: "date" });
      const formattedEnd = formatISO(endDate, { representation: "date" });
      const data: DynamicValues = {
        ...values,
        startDate: formattedStart,
        endDate: formattedEnd,
        currency: currency,
        contract: contract?.id,
      };
      setAddOverride(false);
      try {
        await dynamicValuesModifier.create(data);
      } catch (error) {
        if (error instanceof ValidationError) {
          setValidationErrors(error.errors);
          return;
        }
      }
      await mutateDynamicValues();
      await mutateContract();
      await mutateArr();
      await mutateMrr();
      await mutateDrr();
      resetRevenue();
    }
  };

  const onContractValuesDelete = async () => {
    if (contractValues?.id) {
      await contractValuesModifier.delete(contractValues);
      // this should only be set when delete was successful
      setHasContractValues(false);
      await mutateContractValues(undefined);
      await mutateContract();
    }
  };

  const onRevenueDelete = async (value: DynamicValues, index: number) => {
    if (value && dynamicValues) {
      await dynamicValuesModifier.delete(value);
      await mutateDynamicValues();
      await mutateContractValues();
      await mutateContract();
      await mutateArr();
      await mutateMrr();
      await mutateDrr();
    }
  };

  useEffect(() => {
    if (revenue) {
      setCurrency(revenue.currency || "");
    }
  }, [revenue]);

  useEffect(() => {
    if (contractValues) {
      setHasContractValues(true);
    } else {
      setHasContractValues(false);
    }
  }, [contractValues]);

  return (
    <>
      <Header accountId={accountId} contract={contract} page="Edit Contract Values"></Header>
      {validationErrors && <ValidationErrors title="Input validation failed" errors={validationErrors} />}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ContentBox header="Current Contract Values">
            <Grid container spacing={4} alignItems="flex-start">
              <Grid container item xs={6} spacing={2}>
                <Grid item xs={12}>
                  <>
                    <b>Total Contract Value</b>
                    <br />
                  </>
                  <span>{formatMoney(contract?.tcv, currency)}</span>
                </Grid>
                <Grid item xs={12}>
                  <>
                    <b>Annual Contract Value</b>
                    <br />
                  </>
                  <span>{formatMoney(contract?.acv, currency)}</span>
                </Grid>
              </Grid>
              <Grid container item xs={6} spacing={2}>
                <Grid item xs={12}>
                  <>
                    <b>Monthly Contract Value</b>
                    <br />
                  </>
                  <span>{formatMoney(contract?.mcv, currency)}</span>
                </Grid>
                <Grid item xs={12}>
                  <>
                    <b>Daily Contract Value</b>
                    <br />
                  </>
                  <span>{formatMoney(contract?.dcv, currency)}</span>
                </Grid>
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox header="Override Contract Values">
            {/* the other stuff is also going to have to be a form */}
            <form onSubmit={contractSubmit(onContractValuesSubmit)}>
              <Grid container spacing={4} xs={12}>
                <Grid item xs={2}>
                  {/* Empty grid for spacing */}
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">Calculated</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">Manual</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4} xs={12}>
                <Grid item xs={2} className={classes.financialName}>
                  Total Contract Value
                </Grid>
                <Grid item xs={2}>
                  {formatMoney(computedValues?.tcv, currency)}
                </Grid>
                {hasContractValues ? (
                  <Grid item xs={2}>
                    {formatMoney(contractValues?.tcv, currency)}
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Controller
                        name="tcv"
                        control={contractControl}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Value must be provided" : null}
                            type="number"
                            placeholder={canOverride ? "0.00" : "Not allowed"}
                            disabled={!canOverride}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={4} xs={12}>
                <Grid item xs={2} className={classes.financialName}>
                  Annual Contract Value
                </Grid>
                <Grid item xs={2}>
                  {formatMoney(computedValues?.acv, currency)}
                </Grid>
                {hasContractValues ? (
                  <Grid item xs={2}>
                    {formatMoney(contractValues?.acv, currency)}
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Controller
                        name="acv"
                        control={contractControl}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Value must be provided" : null}
                            type="number"
                            placeholder={canOverride ? "0.00" : "Not allowed"}
                            disabled={!canOverride}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={4} xs={12}>
                <Grid item xs={2} className={classes.financialName}>
                  Monthly Contract Value
                </Grid>
                <Grid item xs={2}>
                  {formatMoney(computedValues?.mcv, currency)}
                </Grid>
                {hasContractValues ? (
                  <Grid item xs={2}>
                    {formatMoney(contractValues?.mcv, currency)}
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Controller
                        name="mcv"
                        control={contractControl}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Value must be provided" : null}
                            type="number"
                            placeholder={canOverride ? "0.00" : "Not allowed"}
                            disabled={!canOverride}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={4} xs={12}>
                <Grid item xs={2} className={classes.financialName}>
                  Daily Contract Value
                </Grid>
                <Grid item xs={2}>
                  {formatMoney(computedValues?.dcv, currency)}
                </Grid>
                {hasContractValues ? (
                  <Grid item xs={2}>
                    {formatMoney(contractValues?.dcv, currency)}
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Controller
                        name="dcv"
                        control={contractControl}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? "Value must be provided" : null}
                            type="number"
                            placeholder={canOverride ? "0.00" : "Not allowed"}
                            disabled={!canOverride}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              {hasContractValues && (
                <Grid container spacing={4} xs={12}>
                  <Grid item xs={4} className={classes.financialName}>
                    Reason for Override
                  </Grid>
                  <Grid item xs={2}>
                    {contractValues?.comment}
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={4} xs={12}>
                <Grid item xs={4}></Grid>
                <Grid item xs={2}>
                  {hasContractValues ? (
                    <Button
                      startIcon={<DeleteIcon />}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onContractValuesDelete();
                      }}
                      disabled={!canOverride}
                    >
                      Remove Override
                    </Button>
                  ) : (
                    <>
                      <FormControl fullWidth>
                        <Controller
                          name="comment"
                          control={contractControl}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              error={!!error}
                              helperText={error ? "Comment must be set" : null}
                              InputLabelProps={{ shrink: true }}
                              placeholder={canOverride ? "Reason" : "Not allowed"}
                              disabled={!canOverride}
                            />
                          )}
                        />
                      </FormControl>
                    </>
                  )}
                </Grid>
              </Grid>
              {!hasContractValues && canOverride && (
                <Grid container spacing={4} xs={12}>
                  <Grid item xs={4}></Grid>
                  <Grid container item xs={2} justifyContent="center">
                    <ButtonGroup color="primary" variant="contained" aria-label="outlined primary button group">
                      <Button
                        className={classes.contractButton}
                        startIcon={<DesktopAccessDisabledIcon />}
                        size="small"
                        color="primary"
                        type="submit"
                      >
                        Override
                      </Button>
                      <Button
                        className={classes.contractButton}
                        startIcon={<CancelIcon />}
                        size="small"
                        onClick={() => resetContract()}
                      >
                        Clear
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
            </form>
          </ContentBox>
          <ContentBox header="Current Dynamic Values">
            <Grid container spacing={4}>
              <Grid container item xs={4} spacing={2}>
                <Grid item xs={12}>
                  <>
                    <b>Annually Recurring Revenue</b>
                    <br />
                  </>
                  <span>{formatMoney(arr ? arr[currency as keyof CurrencyValue] : 0, currency)}</span>
                </Grid>
              </Grid>
              <Grid container item xs={4} spacing={2}>
                <Grid item xs={12}>
                  <>
                    <b>Monthly Recurring Revenue</b>
                    <br />
                  </>
                  <span>{formatMoney(mrr ? mrr[currency as keyof CurrencyValue] : 0, currency)}</span>
                </Grid>
              </Grid>
              <Grid container item xs={4} spacing={2}>
                <Grid item xs={12}>
                  <>
                    <b>Daily Recurring Revenue</b>
                    <br />
                  </>
                  <span>{formatMoney(drr ? drr[currency as keyof CurrencyValue] : 0, currency)}</span>
                </Grid>
              </Grid>
            </Grid>
          </ContentBox>
          <DynamicValueBox
            addOverride={addOverride}
            control={revenueControl}
            handleAddOverride={() => setAddOverride(true)}
            handleDelete={(value: DynamicValues, index: number) => onRevenueDelete(value, index)}
            handleReset={() => {
              resetRevenue();
              setAddOverride(false);
            }}
            handleSubmit={revenueSubmit(onRevenueSubmit)}
            values={dynamicValues}
          />
          <ContentBox header="Override Revenue Recognition">
            {availableLineItems?.map((li) => (
              <RecognizedRevenueOverridesForLineItem lineItem={li} accountId={accountId} />
            ))}
          </ContentBox>
        </Grid>
      </Grid>
    </>
  );
}
