import { isMaintenance, LineItem } from "../../../service/lineitems";
import { Control, Controller, useForm } from "react-hook-form";
import { useDictionary } from "../../../service/dictionary";
import { useEffect } from "react";
import { nextEndDate, validateEndDate } from "../../../utils/date";
import formatISO from "date-fns/formatISO";

import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { DatePicker } from "../../../components/DatePicker";
import { Props as SelectProps, Select } from "../../../components/Select";
import isArray from "lodash/isArray";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import * as React from "react";
import { ValidationErrors } from "../../../components/ValidationErrors";
import { GROUPED_FIELDS, GROUPED_FIELDS_MAINTENANCE } from "./LineItemAccordion";
import { Trans, useTranslation } from "react-i18next";
import { USAGE_METRIC_ALL_FIELDS, USAGE_METRIC_FIELDS } from "../../../service/api";
import { Contract } from "../../../service/contracts";
import { useNotifications } from "../../../notifications";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export type LineItemFormFields = Pick<
  LineItem,
  | "startDate"
  | "endDate"
  | "currency"
  | "salesPrice"
  | "licenseType"
  | "integrationPath"
  | "deviceTypes"
  | "supportLevel"
  | "productType"
  | "addons"
  | "edition"
  | "platforms"
  | "symbologies"
  | "userType"
  | "useCasePack"
  | "useCaseSuite"
  | "anonymizeIp"
  | "analytics"
  | "maxDevicesPerYear"
  | "maxDevicesPerMonth"
  | "maxScansPerYear"
  | "maxActivations"
  | "maxScansPerDevicePerYear"
  | "maxStores"
  | "avgMonthlyDevicesPerStore"
  | "avgScansPerStore"
  | "pilot"
  | "staged"
  | "terminated"
> & { geography?: string[] | null; communicationSalesPackage?: string };

export interface LineItemFormProps {
  requirements: { [key: string]: any };
  errors?: { [key: string]: string[] };
  lineItem: LineItem;
  onClose: () => void;
  onCancel: () => void;
  onChange: (data: LineItemFormFields) => Promise<boolean>;
  contract: Contract | null;
}

function ConfigValidation(props: {
  productType: string;
  integrationPath: string;
  edition: string | null;
  addons: string[];
  platforms: string[];
  userType: string;
  communicationSalesPackage: string | undefined;
  analytics: "with_analytics" | "with_analytics_no_scan_content" | "no_analytics";
  unresolvedGeographies: string[];
}): JSX.Element {
  const { dictionary } = useDictionary();
  const warnings: string[] = [];
  // Checks integration paths per product type
  if (
    props.productType in dictionary.INTEGRATION_PATHS_PER_PRODUCT_TYPE &&
    !dictionary.INTEGRATION_PATHS_PER_PRODUCT_TYPE[props.productType].includes(props.integrationPath)
  ) {
    warnings.push(
      `Integration path ${dictionary.INTEGRATION_PATHS[props.integrationPath]} not supported by product type ${
        dictionary.PRODUCT_TYPES[props.productType]
      }.`
    );
  }
  // Checks edition per (product type, integration path)
  if (
    props.edition &&
    props.productType in dictionary.EDITION_PER_INTEGRATION_PATH_PER_PRODUCT_TYPE &&
    props.integrationPath in dictionary.EDITION_PER_INTEGRATION_PATH_PER_PRODUCT_TYPE[props.productType] &&
    !dictionary.EDITION_PER_INTEGRATION_PATH_PER_PRODUCT_TYPE[props.productType][props.integrationPath].includes(
      props.edition
    )
  ) {
    warnings.push(
      `Edition ${dictionary.EDITIONS[props.edition]} is not supported by product type ${
        dictionary.PRODUCT_TYPES[props.productType]
      } with integration path ${dictionary.INTEGRATION_PATHS[props.integrationPath]}`
    );
  }
  // Checks addons per integration path
  if (props.integrationPath in dictionary.ADDONS_PER_INTEGRATION_PATH) {
    props.addons.forEach((a) => {
      if (!dictionary.ADDONS_PER_INTEGRATION_PATH[props.integrationPath].includes(a)) {
        warnings.push(
          `Addon ${dictionary.ADDONS[a]} not supported by integration path ${
            dictionary.INTEGRATION_PATHS[props.integrationPath]
          }`
        );
      }
    });
  }
  // Checks platforms per product type
  if (props.productType in dictionary.PLATFORMS_PER_PRODUCT_TYPE) {
    props.platforms.forEach((p) => {
      if (!dictionary.PLATFORMS_PER_PRODUCT_TYPE[props.productType].includes(p)) {
        warnings.push(
          `Platform ${dictionary.PLATFORMS[p]} not supported by product type ${
            dictionary.PRODUCT_TYPES[props.productType]
          }`
        );
      }
    });
  }
  // Checks platforms per integration path
  if (props.integrationPath in dictionary.PLATFORMS_PER_INTEGRATION_PATH) {
    props.platforms.forEach((p) => {
      if (!dictionary.PLATFORMS_PER_INTEGRATION_PATH[props.integrationPath].includes(p)) {
        warnings.push(
          `Platform ${dictionary.PLATFORMS[p]} not supported by integration path ${
            dictionary.INTEGRATION_PATHS[props.integrationPath]
          }`
        );
      }
    });
  }
  // Checks user type per product type
  if (
    props.productType in dictionary.USER_TYPES_PER_PRODUCT_TYPE &&
    !dictionary.USER_TYPES_PER_PRODUCT_TYPE[props.productType].includes(props.userType)
  ) {
    warnings.push(
      `User type ${dictionary.USER_TYPES[props.userType]} not supported by product type ${
        dictionary.PRODUCT_TYPES[props.productType]
      }.`
    );
  }
  // Checks user type per integration path
  if (
    props.integrationPath in dictionary.USER_TYPES_PER_INTEGRATION_PATH &&
    !dictionary.USER_TYPES_PER_INTEGRATION_PATH[props.integrationPath].includes(props.userType)
  ) {
    warnings.push(
      `User type ${dictionary.USER_TYPES[props.userType]} not supported by integration path ${
        dictionary.INTEGRATION_PATHS[props.integrationPath]
      }.`
    );
  }
  // Checks analytics type per communication sales package
  if (
    props.communicationSalesPackage !== undefined &&
    props.communicationSalesPackage in dictionary.ANALYTICS_TYPES_PER_COMMUNICATION_SALES_PACKAGE &&
    !dictionary.ANALYTICS_TYPES_PER_COMMUNICATION_SALES_PACKAGE[props.communicationSalesPackage].includes(
      props.analytics
    )
  ) {
    warnings.push(
      `Analytics settings ${dictionary.ANALYTICS_TYPES[props.analytics]} not supported by communication sales package ${
        dictionary.COMMUNICATION_SALES_PACKAGES[props.communicationSalesPackage]
      }`
    );
  }

  // Inform the user that unresolved geographies will be lost when saving
  if (props.unresolvedGeographies.length > 0) {
    warnings.push(
      `'Geographic territory' contains the following values which could not be matched to a region:
      ${props.unresolvedGeographies.join(", ")}.
      These will be lost when saving the line item. If applicable, please select the matching geographies from the dropdown.
      `
    );
  }

  return warnings.length > 0 ? (
    <Alert severity="warning">
      <AlertTitle>Incorrect configuration</AlertTitle>
      Please be aware of the following configuration issues:
      <ul>
        {warnings.map((e) => (
          <li>{e}</li>
        ))}
      </ul>
    </Alert>
  ) : (
    <></>
  );
}

function LimitField(props: { lineItem: LineItem; name: keyof LineItem; control: Control }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.lineItem[props.name] || ""}
      render={({ field }) => (
        <FormControl fullWidth>
          <TextField
            type="number"
            InputLabelProps={{ shrink: true }}
            placeholder="unlimited"
            label={t(`fields.${props.name}.label`)}
            {...field}
            onChange={(e) => field.onChange(parseInt(e.target.value))}
            onWheel={(_) => (document.activeElement as any).blur()}
          />
        </FormControl>
      )}
    />
  );
}

function ChoiceField(props: {
  lineItem: LineItem;
  name: string;
  choices: [any, string][];
  control: Control;
  selectProps?: SelectProps;
}): JSX.Element {
  const { t } = useTranslation();
  const selectProps = (props.selectProps || {}) as any;
  selectProps.rules ||= {};
  selectProps.rules.required = selectProps.required;
  return (
    <Select control={props.control} name={props.name} label={t(`fields.${props.name}.label`)} {...selectProps}>
      {props.choices.map((entry) => (
        <MenuItem key={entry[0]} value={entry[0]}>
          {entry[1]}
        </MenuItem>
      ))}
    </Select>
  );
}

function AutocompleteField(props: {
  lineItem: LineItem;
  name: keyof LineItem;
  control: Control;
  options: string[];
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.lineItem[props.name] || ""}
      render={({ field }) => (
        <FormControl fullWidth>
          <Autocomplete
            {...field}
            freeSolo
            blurOnSelect
            autoSelect
            options={props.options}
            onChange={(e, value) => field.onChange(value)}
            renderInput={(params) => (
              <TextField {...params} label={t(`fields.${props.name}.label`)} InputLabelProps={{ shrink: true }} />
            )}
          />
        </FormControl>
      )}
    />
  );
}

export function LineItemAccordionForm(props: LineItemFormProps): JSX.Element {
  const { t } = useTranslation();
  const li = props.lineItem;
  const { control, handleSubmit, getValues, watch, setValue, resetField } = useForm<LineItemFormFields>({
    defaultValues: {
      ...omit(li, ["geography", "communicationSalesPackage"]),
    } as LineItemFormFields,
  });

  const { dictionary } = useDictionary();
  const onSubmit = async () => {
    const values = getValues() as LineItemFormFields;
    const result = await props.onChange(
      Object.assign(values, {
        geography: (values.geography || []).filter((v) => v != "").join(","),
        communicationSalesPackage: li.purposeType === "product" ? [values.communicationSalesPackage] : ["default"],
        endDate: values.licenseType == "perpetual" ? null : values.endDate,
      })
    );
    if (result) props.onClose();
  };
  const usageMetric = watch("usageMetric" as any);
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const productType = watch("productType");
  const edition = watch("edition");
  const licenseType = watch("licenseType");
  const usageMetricFields = USAGE_METRIC_FIELDS[usageMetric] || [];
  const symbologies = watch("symbologies");
  const integrationPath = watch("integrationPath");
  const addons = watch("addons");
  const platforms = watch("platforms");
  const userType = watch("userType");
  const communicationSalesPackage = watch("communicationSalesPackage");
  const analytics = watch("analytics");

  const { addNotification } = useNotifications();

  // Match a geography from SFDC to one of the dropdown options
  function matchGeography(input: string): string | undefined {
    if (dictionary.REGIONS.includes(input)) {
      return input;
    }

    const aliasMatch = dictionary.REGIONS_MAPPING[input];
    if (aliasMatch) {
      return aliasMatch;
    }

    const prefixMatches = dictionary.REGIONS.filter((region) => region.startsWith(input));
    // Only match prefix if unambiguous
    if (prefixMatches.length === 1) {
      return prefixMatches[0];
    }

    return undefined;
  }

  function resolveGeographies(geography: string | null): [string[], string[]] {
    if (geography === null || geography === undefined) {
      return [[], []];
    }

    const geographies: string[] = isArray(li.geography)
      ? li.geography
      : (li.geography || "").split(",").map((v) => v.trim());

    let unresolved: string[] = [];
    let resolved: string[] = [];
    for (const geo of geographies) {
      const match = matchGeography(geo);

      if (match !== undefined) {
        resolved.push(match);
      } else {
        unresolved.push(geo);
      }
    }

    resolved = uniq(resolved);
    return [resolved, unresolved];
  }

  const [geographies, unresolvedGeographies] = resolveGeographies(li.geography);

  useEffect(() => {
    if (startDate && !endDate) {
      const newEndDate = nextEndDate(startDate, props.contract?.dealTypes.includes("renewal") ? 12 : 24);
      if (newEndDate && licenseType != "perpetual") {
        setValue("endDate", formatISO(newEndDate, { representation: "date" }));
      }
    }
  }, [startDate, endDate, licenseType]);

  useEffect(() => {
    // By license key metrics are legacy: we display a warning to the user.
    if (usageMetric.endsWith("by-lk")) {
      addNotification({
        level: "warning",
        text: "The selected usage metric is deprecated (legacy) and should only be used for historic data, not new contracts",
      });
    }
  }, [usageMetric]);

  useEffect(() => {
    // When switching between product types that both allow editions,
    // ensure that the currently selected value is allowed in both,
    // otherwise reset the edition field.
    const allowedEditions = dictionary.EDITIONS_PER_PRODUCT_TYPE[productType] || [];
    if (edition && !allowedEditions.includes(edition)) {
      resetField("edition");
    }

    if (!["barcode_scanner", "shelf_view"].includes(productType)) {
      setValue("symbologies", []);
    }
  }, [productType]);

  useEffect(() => {
    if (licenseType == "perpetual") {
      setValue("endDate", "");
    }
  }, [licenseType]);

  const areAllSymbologiesSelected = symbologies.length === Object.keys(dictionary.SYMBOLOGY_NAMES).length;

  const toggleAllSymbologies = () => {
    if (areAllSymbologiesSelected) {
      setValue("symbologies", []);
    } else {
      setValue("symbologies", Object.keys(dictionary.SYMBOLOGY_NAMES));
    }
  };

  // Filter addon choices to exclude matrixscan in case edition is chosen
  const addonChoices = Object.entries(dictionary.ADDONS).filter(
    ([addon, _]) => !edition || !addon.startsWith("matrixscan")
  );

  useEffect(() => {
    const options = addonChoices.map((o) => o[0]);
    // Ensure addon is one of the available options
    setValue(
      "addons",
      addons.filter((addon) => options.includes(addon))
    );
  }, [edition]);

  const fields: { [key: string]: JSX.Element } = {
    productType: (
      <ChoiceField
        lineItem={li}
        name="productType"
        control={control as any}
        selectProps={{ required: true } as any}
        choices={Object.entries(dictionary.PRODUCT_TYPES)}
      />
    ),
    edition: (
      <ChoiceField
        lineItem={li}
        name="edition"
        control={control as any}
        selectProps={
          {
            // If a product type allows no editions, its key is missing in this dictionary.
            disabled: !dictionary.EDITIONS_PER_PRODUCT_TYPE[productType],
          } as any
        }
        choices={(dictionary.EDITIONS_PER_PRODUCT_TYPE[productType] || []).map((e) => [e, dictionary.EDITIONS[e]])}
      />
    ),
    addons: (
      <ChoiceField
        lineItem={li}
        name="addons"
        control={control as any}
        selectProps={{ multiple: true } as any}
        choices={addonChoices}
      />
    ),
    integrationPath: (
      <ChoiceField
        lineItem={li}
        name="integrationPath"
        control={control as any}
        selectProps={{ required: true } as any}
        choices={Object.entries(dictionary.INTEGRATION_PATHS)}
      />
    ),
    platforms: (
      <ChoiceField
        lineItem={li}
        name="platforms"
        control={control as any}
        selectProps={{ required: true, multiple: true } as any}
        choices={Object.entries(dictionary.PLATFORMS)}
      />
    ),
    symbologies: (
      <>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={9}>
            <ChoiceField
              lineItem={li}
              name="symbologies"
              control={control as any}
              selectProps={
                {
                  required: productType == "barcode_scanner",
                  disabled: !["barcode_scanner", "shelf_view"].includes(productType),
                  multiple: true,
                } as any
              }
              choices={Object.entries(dictionary.SYMBOLOGY_NAMES)}
            />
          </Grid>
          <Grid container item xs={3}>
            <Button
              disabled={productType !== "barcode_scanner"}
              variant={areAllSymbologiesSelected ? "contained" : "outlined"}
              size="small"
              color="primary"
              onClick={() => toggleAllSymbologies()}
            >
              All
            </Button>
          </Grid>
        </Grid>
      </>
    ),
    supportLevel: (
      <ChoiceField
        lineItem={li}
        name="supportLevel"
        control={control as any}
        selectProps={{ required: true } as any}
        choices={Object.entries(dictionary.SUPPORT_LEVEL_TYPES)}
      />
    ),
    currency: (
      <ChoiceField
        lineItem={li}
        name="currency"
        control={control as any}
        selectProps={
          {
            required: true,
            rules: {
              required: true,
              validate: (value: any) =>
                props.requirements.currency
                  ? props.requirements.currency != value
                    ? `Must be set to ${props.requirements.currency}`
                    : true
                  : true,
            },
          } as any
        }
        choices={dictionary.CURRENCIES.map((x) => [x, x])}
      />
    ),

    salesPrice: (
      <Controller
        name="salesPrice"
        control={control}
        defaultValue={li.salesPrice}
        rules={{ min: 0, required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              error={!!error}
              helperText={error ? "Please provide correct value" : null}
              onChange={(e) => field.onChange(parseFloat(e.target.value))}
              onWheel={(_) => (document.activeElement as any).blur()}
              type="number"
              label={t("fields.salesPrice.label")}
              placeholder="0.00"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )}
      />
    ),
    licenseType: (
      <ChoiceField
        lineItem={li}
        name="licenseType"
        control={control as any}
        selectProps={{ required: true } as any}
        choices={Object.entries(dictionary.LICENSE_TYPES)}
      />
    ),
    startDate: (
      <DatePicker
        rules={{
          required: true,
        }}
        label={t("fields.startDate.label")}
        control={control as any}
        default={li.startDate}
        name="startDate"
      />
    ),
    endDate:
      licenseType === "perpetual" ? (
        <></>
      ) : (
        <DatePicker
          rules={{
            validate: (value) => licenseType === "perpetual" || validateEndDate(startDate, value),
            required: licenseType !== "perpetual",
          }}
          minDate={(startDate && licenseType != "perpetual" ? nextEndDate(startDate) : null) as any}
          disabled={licenseType === "perpetual"}
          label={t("fields.endDate.label")}
          control={control as any}
          default={licenseType === "perpetual" ? "" : li.endDate || undefined}
          name="endDate"
        />
      ),
    usageMetric: (
      <ChoiceField
        lineItem={li}
        name="usageMetric"
        control={control as any}
        selectProps={{ required: true } as any}
        choices={Object.entries(dictionary.USAGE_METRICS)}
      />
    ),
    maxDevicesPerYear: <LimitField name="maxDevicesPerYear" lineItem={li} control={control as any} />,
    maxDevicesPerMonth: <LimitField name="maxDevicesPerMonth" lineItem={li} control={control as any} />,
    maxScansPerYear: <LimitField name="maxScansPerYear" lineItem={li} control={control as any} />,
    maxActivations: <LimitField name="maxActivations" lineItem={li} control={control as any} />,
    maxScansPerDevicePerYear: <LimitField name="maxScansPerDevicePerYear" lineItem={li} control={control as any} />,
    maxStores: <LimitField name="maxStores" lineItem={li} control={control as any} />,
    storeType: (
      <ChoiceField
        lineItem={li}
        name="storeType"
        control={control as any}
        choices={Object.entries(dictionary.STORE_TYPES)}
      />
    ),
    storeEngagementTier: (
      <ChoiceField
        lineItem={li}
        name="storeEngagementTier"
        control={control as any}
        choices={Object.entries(dictionary.STORE_ENGAGEMENT_TIERS)}
      />
    ),
    avgMonthlyDevicesPerStore: <LimitField name="avgMonthlyDevicesPerStore" lineItem={li} control={control as any} />,
    avgScansPerStore: <LimitField name="avgScansPerStore" lineItem={li} control={control as any} />,
    communicationSalesPackage: (
      <ChoiceField
        lineItem={li}
        name="communicationSalesPackage"
        control={control as any}
        selectProps={
          { required: true, default: li.communicationSalesPackage ? li.communicationSalesPackage[0] : "" } as any
        }
        choices={Object.entries(dictionary.COMMUNICATION_SALES_PACKAGES)}
      />
    ),
    analytics: (
      <ChoiceField
        lineItem={li}
        name="analytics"
        control={control as any}
        selectProps={{ required: true } as any}
        choices={Object.entries(dictionary.ANALYTICS_TYPES)}
      />
    ),
    anonymizeIp: (
      <>
        <Grid item xs={6}>
          <Trans>fields.anonymizeIp.label</Trans>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="anonymizeIp"
            control={control}
            defaultValue={li.anonymizeIp}
            render={({ field }) => (
              <Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
            )}
          />
        </Grid>
      </>
    ),
    staged: (
      <>
        <Grid item xs={6}>
          <Trans>fields.staged.label</Trans>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="staged"
            control={control}
            defaultValue={li.staged}
            render={({ field }) => (
              <Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
            )}
          />
        </Grid>
      </>
    ),
    pilot: (
      <>
        <Grid item xs={6}>
          <Trans>fields.pilot.label</Trans>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="pilot"
            control={control}
            defaultValue={li.pilot}
            render={({ field }) => (
              <Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
            )}
          />
        </Grid>
      </>
    ),
    purposeType: (
      <ChoiceField
        lineItem={li}
        name="purposeType"
        control={control as any}
        choices={Object.entries(dictionary.LINE_ITEM_PURPOSE_TYPES)}
        selectProps={
          {
            disabled: true,
          } as any
        }
      />
    ),
    terminated: (
      <>
        <Grid item xs={6}>
          <Trans>fields.terminated.label</Trans>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="terminated"
            control={control}
            defaultValue={li.terminated}
            render={({ field }) => (
              <Switch
                {...field}
                checked={field.value ?? undefined}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
      </>
    ),
    userType: (
      <ChoiceField
        lineItem={li}
        name="userType"
        control={control as any}
        selectProps={
          {
            required: true,
            rules: {
              required: true,
              validate: (value: any) =>
                props.requirements.userType
                  ? props.requirements.userType != value
                    ? `Must be set to ${dictionary.USER_TYPES[props.requirements.userType]}`
                    : true
                  : true,
            },
          } as any
        }
        choices={Object.entries(dictionary.USER_TYPES)}
      />
    ),
    useCasePack: (
      <AutocompleteField
        control={control as any}
        name="useCasePack"
        lineItem={li}
        options={dictionary.USE_CASE_PACKS}
      />
    ),
    useCaseSuite: (
      <AutocompleteField
        control={control as any}
        name="useCaseSuite"
        lineItem={li}
        options={dictionary.USE_CASE_SUITES}
      />
    ),
    useCase: <AutocompleteField control={control as any} name="useCase" lineItem={li} options={dictionary.USE_CASES} />,
    deviceTypes: (
      <ChoiceField
        lineItem={li}
        name="deviceTypes"
        control={control as any}
        selectProps={{ required: true } as any}
        choices={Object.entries(dictionary.DEVICE_TYPES)}
      />
    ),
    geography: (
      <ChoiceField
        lineItem={li}
        name="geography"
        control={control as any}
        selectProps={
          {
            multiple: true,
            default: geographies,
          } as any
        }
        choices={dictionary.REGIONS.map((x) => [x, x])}
      />
    ),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AccordionDetails>
        <Grid container spacing={4}>
          {props.errors && (
            <Grid item xs={12}>
              <ValidationErrors title="Line item invalid" errors={props.errors} />
            </Grid>
          )}

          <Grid item xs={12}>
            <ConfigValidation
              productType={productType}
              integrationPath={integrationPath}
              edition={edition}
              addons={addons}
              platforms={platforms}
              userType={userType}
              communicationSalesPackage={communicationSalesPackage}
              analytics={analytics}
              unresolvedGeographies={unresolvedGeographies}
            />
          </Grid>

          {(isMaintenance(li) ? GROUPED_FIELDS_MAINTENANCE : GROUPED_FIELDS).map(([group, groupFields]) => (
            <Grid item xs={4} key={group}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <Trans>lineItem.fieldGroups.{group}.label</Trans>
                  </Typography>
                </Grid>
                {groupFields
                  .filter((f) => !USAGE_METRIC_ALL_FIELDS.includes(f) || usageMetricFields.includes(f))
                  .map((field) => (
                    <Grid key={field} container item xs={12}>
                      {fields[field]}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button
          size="small"
          onClick={() => {
            props.onClose();
            props.onCancel();
          }}
        >
          Cancel
        </Button>
        <Button size="small" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </AccordionActions>
    </form>
  );
}
