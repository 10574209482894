import {
  ResourceListResult,
  ResourceModifier,
  ResourceResult,
  useServiceResource,
  useServiceResourceList,
  useServiceResourceModifier,
} from "./api";

export interface UsageGroup {
  id?: string;
  accountId: string;
  name: string;
  usageMetric: string;
}

export function useUsageGroups(accountId: string, page?: number): ResourceListResult<UsageGroup> {
  return useServiceResourceList<UsageGroup>(`/v1/accounts/${accountId}/usage-groups/`, page);
}

export function useUsageGroup(accountId: string, groupId: number): ResourceResult<UsageGroup> {
  return useServiceResource<UsageGroup>(`/v1/accounts/${accountId}/usage-groups/${groupId}/`);
}

export function useUsageGroupsModifier(accountId: string): ResourceModifier<UsageGroup> {
  return useServiceResourceModifier<UsageGroup>(`/v1/accounts/${accountId}/usage-groups/`);
}
