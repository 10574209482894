import React, { useRef, useEffect } from "react";
import { init, getInstanceByDom, EChartOption } from "echarts";
import type { CSSProperties } from "react";
import type { EChartsOption, ECharts, SetOptionOpts } from "echarts";
import { Contract } from "../service/contracts";
import { useDictionary } from "../service/dictionary";
import { useHistory } from "react-router-dom";
import { usePreferences } from "../service/preferences";

interface ContractGraphNode {
  value: string;
  category: string;
  id: string;
  opportunityId: string;
  accountId: string;
  contractName: string;
}

interface ContractGraphEdge {
  // indexes of contract graph nodes
  source: number;
  target: number;
  value?: number;
}

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
  onClick?: (e: any) => void;
}

export function ReactECharts({ option, style, settings, loading, theme, onClick }: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);
      if (onClick !== undefined) chart.on("click", "series.graph", onClick);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)!;
      chart.setOption(option as EChartOption, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)!;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading, theme]);

  return <div ref={chartRef} style={{ width: "100%", height: "100%", ...style }} />;
}

export function AccountContractsGraph(props: { contracts: Contract[] }) {
  const { showContractGraph } = usePreferences();
  const { dictionary } = useDictionary();
  const history = useHistory();

  // UT-5846: parent-contract relationships are changed to LineItem relationships: this graph needs migration.
  if (!showContractGraph) return <></>;

  function formatNodeName(name: string | undefined) {
    if (name === undefined) return;
    if (name.length < 32) return name;
    return name.substring(0, 16) + " ... " + name.substring(name.length - 16, name.length);
  }

  function onlyUnique<T>(value: T, index: number, self: T[]) {
    return self.indexOf(value) === index;
  }

  // on chart element click
  function handleChartClick(e: any) {
    if ("data" in e) {
      // click on a graph node
      if ("contractName" in e.data) {
        history.push(`/accounts/${e.data.accountId}/contracts/${e.data.id}`);
      }
    }
  }

  // Select contracts with an ID and a single deal type
  const contractsFiltered: Contract[] = props.contracts.filter((c) => c.id !== null && c.dealTypes.length === 1);
  // Sort them by close date to display the right time ordering
  contractsFiltered.sort((a, b) => (a.closeDate < b.closeDate ? -1 : 1));
  // Compute the unique deal types in those contracts (for the legend, colors, etc.)
  const uniqueDealTypes: { name: string }[] = contractsFiltered
    .map((c: Contract) => c.dealTypes[0])
    .filter(onlyUnique)
    .map((uniqueDealType: string) => ({ name: dictionary.DEAL_TYPES[uniqueDealType] }));

  const axisData: string[] = [];
  const nodes: ContractGraphNode[] = [];
  const edges: ContractGraphEdge[] = [];

  contractsFiltered.forEach((c: Contract, idx: number) => {
    axisData.push(c.closeDate);
    nodes.push({
      // value is the y-axis
      value: c.dealTypes.length > 0 ? dictionary.DEAL_TYPES[c.dealTypes[0]] : "None",
      // category is the color-coding
      category: c.dealTypes.length > 0 ? dictionary.DEAL_TYPES[c.dealTypes[0]] : "None",
      id: c.id!.toString(),
      opportunityId: c.opportunityId ?? "-",
      accountId: c.accountId,
      contractName: c.name,
    });
    if (c.parentContract !== null) {
      // find parent
      const parentContract: Contract | undefined = contractsFiltered.find((c2) => c2.id === c.parentContract);
      if (parentContract !== undefined)
        // add to links
        edges.push({
          source: contractsFiltered.indexOf(parentContract),
          target: idx,
        });
    }
  });

  const option: EChartsOption = {
    title: {
      text: "Graph",
      subtext: "By Close Date & Deal Type",
    },
    backgroundColor: "#fff",
    tooltip: {
      position: "bottom",
      //formatter: "{b}",
      formatter: (elem: any) => {
        // tooltip lines/arrows
        if ("source" in elem.data) return `${elem.data.source} → ${elem.data.target}`;
        // tooltip for nodes
        return `<b> Contract #${elem.data.id} </b> - ${elem.data.category} <br /> ${formatNodeName(
          elem.data.contractName
        )} <br /> <i> Opportunity: ${elem.data.opportunityId} </i>`;
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: axisData,
      show: false,
    },
    yAxis: {
      type: "category",
      boundaryGap: true,
      show: false,
    },
    legend: [
      {
        data: uniqueDealTypes.map((dt: { name: string }) => dt.name),
        orient: "horizontal",
        top: "top",
        left: "right",
      },
    ],
    series: [
      {
        categories: uniqueDealTypes,
        type: "graph",
        layout: "none",
        coordinateSystem: "cartesian2d",
        symbolSize: 20,
        edgeSymbol: ["circle", "arrow"],
        edgeSymbolSize: [4, 10],
        data: nodes,
        links: edges,
        label: {
          show: true,
          position: "top",
          color: "black",
          fontSize: 10,
        },
        lineStyle: {
          color: "source",
          curveness: 0.3,
          width: 2,
          opacity: 0.75,
        },
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: "400px" }} onClick={handleChartClick} />;
}
