import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Permission } from "../service/auth";
import { UserAuth } from "../service/api";
import { RootState } from "./store";
import parseISO from "date-fns/parseISO";

export interface UserState extends Omit<UserAuth, "expires"> {
  expires: string;
}

export interface AuthState {
  user: UserState | null;
}
export interface SetPermissionPayload {
  permission: Permission;
  isAvailable: boolean;
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
  } as AuthState,
  reducers: {
    setPermission: (state: AuthState, action: PayloadAction<SetPermissionPayload>) => {
      const { permission: p, isAvailable } = action.payload;
      if (!state.user) return;

      if (isAvailable && !state.user.permissions.includes(p)) {
        state.user.permissions.push(p);
      } else if (!isAvailable && state.user.permissions.includes(p)) {
        state.user.permissions = state.user.permissions.filter((x) => x !== p);
      }
    },
    setUser: (state: AuthState, action: PayloadAction<UserState>) => {
      state.user = action.payload;
    },
    logout: (state: AuthState) => {
      state.user = null;
    },
  },
});

export const selectUserSessionExpiryDate = createSelector(
  (state: RootState) => (state.auth.user ? state.auth.user.expires : null),
  (expires: string | null) => (expires ? parseISO(expires) : null)
);

export const selectUserPermissions = createSelector(
  (state: RootState) => (state.auth.user ? state.auth.user.permissions : null),
  (permissions: Permission[] | null) => permissions || []
);

export const selectUserAccessToken = createSelector(
  (state: RootState) => (state.auth.user ? state.auth.user.token : null),
  (token: string | null) => token!
);

export const { setPermission, setUser, logout } = authSlice.actions;
export default authSlice.reducer;
