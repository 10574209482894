import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type ColumnData = { group: string | null; fields: [string, string | JSX.Element][] }[];

interface Props {
  columns: ColumnData[];
}

function FieldsColumn(props: { data: ColumnData }): JSX.Element {
  return (
    <Grid container item xs={4} alignContent="flex-start">
      {props.data.map(({ group, fields }, idx) => (
        <React.Fragment key={idx}>
          {group && (
            <Grid item xs={12}>
              <Typography variant="h6">{group}</Typography>
            </Grid>
          )}
          {fields.map(([label, value], fieldIdx) => (
            <React.Fragment key={fieldIdx}>
              <Grid item xs={4}>
                <strong>{label}</strong>
              </Grid>
              <Grid item xs={8}>
                {value}
              </Grid>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </Grid>
  );
}

export function FieldsCollection(props: Props): JSX.Element {
  return (
    <Grid container spacing={4}>
      {props.columns.map((column, idx) => (
        <FieldsColumn key={idx} data={column} />
      ))}
    </Grid>
  );
}
