import * as React from "react";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

export interface Props {
  columns: {
    id: string;
    label: string;
    minWidth: number;
    align?: "left" | "center" | "right" | "inherit" | "justify" | undefined;
    format?: (value: any) => string;
  }[];
  rows: {
    id: string;
    values: any[];
  }[];
  page: number;
  totalRows: number;
  pageLimit: number;
  onChange: (page: number) => void;
  isLoading: boolean;
  isError: boolean;
  allowOrderByCols?: string[];
  order?: "asc" | "desc" | undefined;
  orderBy?: string;
  handleRequestSort?: (columnId: string) => void;
}

export function ObjectsTable(props: Props): JSX.Element {
  const handleChangePage = (event: unknown, newPage: number) => {
    props.onChange(newPage + 1);
  };

  const tableData = () =>
    props.rows.length > 0 ? (
      props.rows.map((row) => (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
          {props.columns.map((column, idx) => {
            const value = row.values[idx];
            return (
              <TableCell key={column.id} align={column.align}>
                {column.format && value ? column.format(value) : value}
              </TableCell>
            );
          })}
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={props.columns.length} align="center">
          No data
        </TableCell>
      </TableRow>
    );

  const loadingIndicator = () => (
    <TableRow>
      <TableCell colSpan={props.columns.length} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  const error = () => (
    <TableRow>
      <TableCell colSpan={props.columns.length} align="center">
        <Alert severity="error">Could not load data because of error</Alert>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sortDirection={props.orderBy === column.id ? props.order! : false}
                >
                  {props.allowOrderByCols !== undefined && props.allowOrderByCols.includes(column.id) ? (
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.orderBy === column.id ? props.order! : "asc"}
                      onClick={(_) => props.handleRequestSort!(column.id)}
                    >
                      <strong>{column.label}</strong>
                    </TableSortLabel>
                  ) : (
                    <strong>{column.label}</strong>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{props.isLoading ? loadingIndicator() : props.isError ? error() : tableData()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[props.pageLimit]}
        component="div"
        count={props.totalRows}
        rowsPerPage={props.pageLimit}
        page={props.page - 1}
        onPageChange={handleChangePage}
      />
    </>
  );
}
