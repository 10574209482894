import currency from "currency.js";

export type MoneyFormat = {
  separator: string;
  decimal: string;
  precision?: number;
};
export const MONEY_FORMATS: MoneyFormat[] = [
  { separator: ",", decimal: "." },
  { separator: "", decimal: "." },
  { separator: ",", decimal: ".", precision: 1 },
  { separator: "", decimal: ".", precision: 1 },
  { separator: ",", decimal: ".", precision: 0 },
  { separator: "", decimal: ".", precision: 0 },
];
export const DEFAULT_MONEY_FORMAT = MONEY_FORMATS[0];

export function formatMoney(
  format: MoneyFormat,
  value: string | number | null | undefined,
  currencyIsoCode?: string | null
): string {
  return currency(value || "", {
    symbol: currencyIsoCode ?? "",
    // use a custom pattern to space out symbol ('!') and amt ('#')
    pattern: "! #",
    ...format,
  }).format();
}
