import {
  ResourceListResult,
  ResourceModifier,
  ResourceResult,
  useServiceResource,
  useServiceResourceList,
  useServiceResourceModifier,
} from "./api";

export interface SubscriptionProduct {
  startDate: string;
  endDate: string;
  productType: string;
  isProductionLicense: boolean;
  supportLevel: string;
  logoHidden: boolean;
  contentShared: boolean;
  activationMessage: boolean;
  activityPing: boolean;
  activityPingWithStats: boolean;
  keyRevocation: boolean;
  localization: boolean;
  maxDevicesPerYear: number | null;
  maxDevicesPerMonth: number | null;
  maxDevicesPerStorePerMonth: number | null;
  maxScansPerYear: number | null;
  maxScansPerDevicePerYear: number | null;
  maxScansPerStorePerMonth: number | null;
  maxActivations: number | null;
  maxStores: number | null;
  geography: string[];
  addons: string[];
  symbologies: string[];
  edition: string | null;
  usageMetric: string;
  platforms: string[] | null;
  useCasePacks: string[] | null;
  useCaseSuites: string[] | null;
  useCases: string[] | null;
  deviceTypes: string[] | null;
}

export interface Subscription {
  id: number | null;

  accountId: string;
  name: string;

  startDate: string | null;
  endDate: string | null;
  platforms: string[];

  availableFeatures: SubscriptionProduct[];

  integrationPath: string;
  userType: string;
  isProduction: boolean;
  usageMetric: string;
  usageGroupId?: string;
  canceled: boolean | null;
}

export function useSubscriptions(
  accountId: string,
  page?: number,
  params?: { [key: string]: string }
): ResourceListResult<Subscription> {
  params ||= {};
  return useServiceResourceList<Subscription>(`/v1/accounts/${accountId}/subscriptions/`, page, params);
}

export function useSubscription(accountId: string, subscriptionId: number): ResourceResult<Subscription> {
  return useServiceResource<Subscription>(`/v1/accounts/${accountId}/subscriptions/${subscriptionId}/`);
}

export function useSubscriptionModifier(accountId: string): ResourceModifier<Subscription> {
  return useServiceResourceModifier<Subscription>(`/v1/accounts/${accountId}/subscriptions/`);
}
