import { Connection } from "jsforce";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../config";
import { selectUserPermissions, setPermission } from "../store/authSlice";
import { UserAuth } from "./api";

export const PERMISSIONS = [
  "basicEditing",
  "financialExport",
  "financialOverride",
  "readonly",
  "useCaseAnnotation",
] as const;
export type Permission = typeof PERMISSIONS[number];

export interface User {
  auth: UserAuth | null;
}

interface Context {
  isAuthenticated: boolean;
}

export const AuthContext = React.createContext<Context | undefined>(undefined);

export function useSalesforce(): {
  login: () => Promise<Connection>;
} {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [initialized2, setInitialized2] = useState<boolean>(false);
  const [connection, setConnection] = useState<Connection | null>(null);
  const [loginListener, setLoginListener] = useState<{ listener: (c: Connection) => void } | null>(null);
  // For some reason it does not work with useEffect
  let redirectUri: string = "";
  if (window.location.port != "") {
    redirectUri = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/index.html`;
  } else {
    redirectUri = `${window.location.protocol}//${window.location.hostname}/index.html`;
  }
  if (!initialized2) {
    jsforce.browser.init({
      loginUrl: config.salesforceUrl,
      clientId: config.salesforceClientId,
      redirectUri: redirectUri,
    });
    setInitialized2(true);
  }
  useEffect(() => {
    if (connection && loginListener) {
      const listener = loginListener;
      setLoginListener(null);
      listener.listener(connection);
    }
  }, [connection]);

  useEffect(() => {
    if (!initialized) {
      jsforce.browser.on("connect", function (conn: any) {
        setConnection(conn);
      });
      setInitialized(true);
    }
  });

  return {
    login: () => {
      if (connection) setConnection(null);
      (jsforce.browser as any).login();
      return new Promise<Connection>((resolve) => {
        setLoginListener({ listener: (c) => resolve(c) });
      });
    },
  };
}

const usePermissions = () => {
  const permissions = useSelector(selectUserPermissions);
  const dispatch = useDispatch();

  const setPermissionWrapper = (permission: Permission, isAvailable: boolean) => {
    dispatch(setPermission({ permission, isAvailable }));
  };

  return {
    hasPermission: (p: Permission) => !!permissions.includes(p),
    setPermission: setPermissionWrapper,
  };
};
export { usePermissions };

// Note: Trying to replace this with Redux-based state management leads to infinite loops
// Try this again after moving away from SFDC-based authentication.
export function useAuth(): Context {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
