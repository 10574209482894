import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export enum Mode {
  PARENTS = "parents",
  CHILDREN = "children",
}

export enum Grouping {
  CONTRACT,
  LINE_ITEM,
}

function Selector<T>(props: {
  value: T;
  onChange: (v: T) => void;
  options: { label: string | JSX.Element; value: T }[];
}): JSX.Element {
  const handleButtonChange = (e: any, value: T | null) => (value != null ? props.onChange(value) : null);
  return (
    <ToggleButtonGroup
      size="small"
      value={props.value}
      exclusive
      onChange={handleButtonChange}
      aria-label="text alignment"
    >
      {props.options.map((option, i) => (
        <ToggleButton key={i} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export function ModeSelector(props: { mode: Mode; onChange: (mode: Mode) => void }): JSX.Element {
  return (
    <Selector
      value={props.mode}
      onChange={props.onChange}
      options={[
        {
          value: Mode.PARENTS,
          label: (
            <>
              <b>▶</b>&nbsp;&nbsp;Parents
            </>
          ),
        },
        {
          value: Mode.CHILDREN,
          label: (
            <>
              <b>◀</b>&nbsp;&nbsp;Children
            </>
          ),
        },
      ]}
    />
  );
}

export function GroupingSelector(props: { grouping: Grouping; onChange: (grouping: Grouping) => void }): JSX.Element {
  return (
    <Selector
      value={props.grouping}
      onChange={props.onChange}
      options={[
        { value: Grouping.CONTRACT, label: "Group by contract" },
        { value: Grouping.LINE_ITEM, label: "Group by line item" },
      ]}
    />
  );
}
