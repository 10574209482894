import { SnackbarProvider, useSnackbar, VariantType } from "notistack";
import makeStyles from "@mui/styles/makeStyles";

export interface Notification {
  text: string | React.ReactNode;
  level?: "error" | "warning" | "info" | "success";
}

const useStyles = makeStyles((_) => ({
  snackbar: {
    paddingTop: "3.5rem",
  },
}));

export function NotificationsProvider(props: { children: React.ReactNode }): JSX.Element {
  const styles = useStyles();
  return (
    <SnackbarProvider
      classes={{ containerRoot: styles.snackbar }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      maxSnack={10}
    >
      {props.children}
    </SnackbarProvider>
  );
}

export function useNotifications(): { addNotification: (notification: Notification) => void } {
  const { enqueueSnackbar } = useSnackbar();
  return {
    addNotification: (n) => {
      enqueueSnackbar(n.text, { variant: n.level ? (n.level as VariantType) : "default" });
    },
  };
}
