import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { useAuth } from "../service/auth";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { obtainTokenUsingGoogle, obtainTokenUsingSF } from "../service/api";
import { useNotifications } from "../notifications";
import { User, useSalesforce } from "../service/auth";
import { setUser } from "../store/authSlice";
import { useDispatch } from "react-redux";
import formatISO from "date-fns/formatISO";
import { UserAuth } from "../service/api";
import { useGoogleLogin } from "@react-oauth/google";

export default function SignIn() {
  const { isAuthenticated } = useAuth();

  const { login: salesforceLogin } = useSalesforce();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const { addNotification } = useNotifications();

  const login = (user: User) => {
    const formatExpiryISO = (user: UserAuth) => {
      return {
        ...user!,
        expires: formatISO(user!.expires),
      };
    };

    dispatch(setUser(formatExpiryISO(user.auth!)));
  };

  const handleSignInSalesforce = async () => {
    setLoading(true);
    const connection = await salesforceLogin();
    const token = await obtainTokenUsingSF(
      connection.accessToken,
      connection.userInfo!.id,
      connection.userInfo!.organizationId
    );
    if (token == null) {
      console.warn("Could not obtain service token");
      addNotification({
        text: "Login failed, please try again",
        level: "error",
      });
      setLoading(false);
      return;
    }

    login({ auth: token });
    history.replace(((location.state || {}) as any).source || "/");
  };

  const onSuccessGoogle = async (resp: any) => {
    setLoading(true);
    const token = await obtainTokenUsingGoogle(resp.code);
    if (token == null) {
      console.warn("Could not obtain service token");
      addNotification({
        text: "Login failed, please try again",
        level: "error",
      });
      setLoading(false);
      return;
    }
    login({ auth: token });
    history.replace(((location.state || {}) as any).source || "/");
  };

  // we use the Authorization code flow, as justified in the following comment:
  // https://github.com/MomenSherif/react-oauth/issues/12#issuecomment-1131408898
  const handleSignInGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => onSuccessGoogle(tokenResponse),
    flow: "auth-code",
  });

  useEffect(() => {
    if (isAuthenticated && !loading) {
      history.replace(((location.state || {}) as any).source || "/");
    }
  }, [isAuthenticated, loading]);

  if (isAuthenticated) {
    // This reduces flickering on refresh when already logged in.
    return <></>;
  }

  if (loading) {
    return (
      <div
        style={{
          margin: "auto",
          width: "50%",
          textAlign: "center",
          padding: "24px",
        }}
      >
        Loading...
        <br />
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Sign-In</Typography>
        <Box sx={{ mt: 1 }}>
          <Button
            data-testid="login-button-salesforce"
            disabled={loading}
            onClick={() => handleSignInSalesforce()}
            type="submit"
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
          >
            {/* TODO: load from assets folder instead. But first fix svg loader */}
            <img
              src="https://c1.sfdcstatic.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg"
              alt="salesforce"
              style={{ height: "30px" }}
            />
            &nbsp;&nbsp; Salesforce
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            data-testid="login-button-google"
            onClick={() => handleSignInGoogle()}
            type="submit"
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
          >
            {/* TODO: load from assets folder instead. But first fix svg loader */}
            <img
              src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
              alt="salesforce"
              style={{ height: "30px" }}
            />
            &nbsp;&nbsp; Google
          </Button>
          <br />
        </Box>
      </Box>
    </Container>
  );
}
