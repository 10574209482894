import * as React from "react";
import type { Theme } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { isMaintenance, LineItem } from "../../../service/lineitems";
import { usePreferences } from "../../../service/preferences";
import { useDictionary } from "../../../service/dictionary";
import { USAGE_METRIC_ALL_FIELDS, USAGE_METRIC_FIELDS } from "../../../service/api";
import { ValidationErrors } from "../../../components/ValidationErrors";
import { Trans } from "react-i18next";
import { GROUPED_FIELDS, GROUPED_FIELDS_MAINTENANCE } from "./LineItemAccordion";

export function LineItemAccordionInfo(props: {
  lineItem: LineItem;
  errors?: { [key: string]: string[] };
}): JSX.Element {
  const { formatDate, formatMoney } = usePreferences();
  const { dictionary } = useDictionary();
  const li = props.lineItem;

  const usageMetricFields = USAGE_METRIC_FIELDS[li.usageMetric] || [];

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      spacing: {
        background:
          "linear-gradient(to right, transparent 16px, #AAA 0%, #AAA 95%, transparent 16px) 0 100% / 100% 1px no-repeat",
      },
      title: {
        fontWeight: "bold",
      },
    })
  );

  const classes = useStyles();

  const values: { [key: string]: string | JSX.Element } = {
    productType: dictionary.PRODUCT_TYPES[li.productType],
    addons: li.addons.map((a) => dictionary.ADDONS[a] || a).join(", ") || "-",
    edition: li.edition ? dictionary.EDITIONS[li.edition] || li.edition : "-",
    integrationPath: dictionary.INTEGRATION_PATHS[li.integrationPath],
    platforms: li.platforms.map((p) => dictionary.PLATFORMS[p] || p).join(", "),
    symbologies: li.symbologies.map((s) => dictionary.SYMBOLOGY_NAMES[s] || s).join(", "),
    supportLevel: dictionary.SUPPORT_LEVEL_TYPES[li.supportLevel],
    currency: li.currency,
    salesPrice: formatMoney(li.salesPrice, li.currency),
    licenseType: dictionary.LICENSE_TYPES[li.licenseType],
    startDate: formatDate(li.startDate),
    endDate: formatDate(li.endDate),
    usageMetric: dictionary.USAGE_METRICS[li.usageMetric],
    maxDevicesPerYear: li.maxDevicesPerYear != null ? String(li.maxDevicesPerYear) : <i>unlimited</i>,
    maxDevicesPerMonth: li.maxDevicesPerMonth != null ? String(li.maxDevicesPerMonth) : <i>unlimited</i>,
    maxScansPerYear: li.maxScansPerYear != null ? String(li.maxScansPerYear) : <i>unlimited</i>,
    maxActivations: li.maxActivations != null ? String(li.maxActivations) : <i>unlimited</i>,
    maxScansPerDevicePerYear:
      li.maxScansPerDevicePerYear != null ? String(li.maxScansPerDevicePerYear) : <i>unlimited</i>,
    maxStores: li.maxStores != null ? String(li.maxStores) : <i>unlimited</i>,
    storeType: li.storeType != null ? dictionary.STORE_TYPES[li.storeType] : "-",
    storeEngagementTier: li.storeEngagementTier ? dictionary.STORE_ENGAGEMENT_TIERS[li.storeEngagementTier] : "-",
    avgMonthlyDevicesPerStore:
      li.avgMonthlyDevicesPerStore != null ? String(li.avgMonthlyDevicesPerStore) : <i>unlimited</i>,
    avgScansPerStore: li.avgScansPerStore != null ? String(li.avgScansPerStore) : <i>unlimited</i>,
    communicationSalesPackage: li.communicationSalesPackage
      .map((v) => dictionary.COMMUNICATION_SALES_PACKAGES[v] || v)
      .join(", "),
    analytics: dictionary.ANALYTICS_TYPES[li.analytics] || "-",
    anonymizeIp: li.anonymizeIp ? "On" : "Off",
    staged: li.staged ? "Yes" : "No",
    pilot: li.pilot ? "Yes" : "No",
    userType: dictionary.USER_TYPES[li.userType],
    useCasePack: li.useCasePack || "-",
    useCaseSuite: li.useCaseSuite || "-",
    useCase: li.useCase || "-",
    deviceTypes: dictionary.DEVICE_TYPES[li.deviceTypes],
    geography: li.geography && li.geography.length > 0 ? li.geography : <i>worldwide</i>,
    purposeType: dictionary.LINE_ITEM_PURPOSE_TYPES[li.purposeType],
    terminated: li.terminated === null ? "-" : li.terminated ? "Yes" : "No",
  };

  return (
    <AccordionDetails>
      <Grid container spacing={4}>
        {props.errors && (
          <Grid item xs={12}>
            <ValidationErrors title="Line item invalid" errors={props.errors} />
          </Grid>
        )}

        {(isMaintenance(li) ? GROUPED_FIELDS_MAINTENANCE : GROUPED_FIELDS).map(([group, groupFields], index) => (
          // todo: this is magic-numbery, find a better way to solve this.
          <Grid item xs={4} key={group} className={GROUPED_FIELDS.length - index >= 3 ? classes.spacing : undefined}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  <Trans>lineItem.fieldGroups.{group}.label</Trans>
                </Typography>
              </Grid>
              {groupFields
                .filter((f) => !USAGE_METRIC_ALL_FIELDS.includes(f) || usageMetricFields.includes(f))
                .map((field) => (
                  <Grid key={field} container item xs={12}>
                    <Grid item xs={6}>
                      <Trans>fields.{field}.label</Trans>
                    </Grid>
                    <Grid item xs={6}>
                      {values[field]}
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </AccordionDetails>
  );
}
