import * as React from "react";
import { useState } from "react";

import Button from "@mui/material/Button";
import type { ButtonTypeMap } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import uniqueId from "lodash/uniqueId";
import omit from "lodash/omit";

export function ConfirmedButton(
  props: ButtonTypeMap["props"] & {
    confirmationTitle: string;
    confirmationText: string;
    onConfirmed: () => void;
    clicksStopPropagation?: boolean;
  }
): JSX.Element {
  const [componentId] = useState<string>(uniqueId());
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClick = (e: Event, handler?: () => void) => {
    if (props.clicksStopPropagation) e.stopPropagation();
    if (handler) handler();
  };

  const handleClose = (confirmed?: boolean) => {
    setDialogOpen(false);
    if (confirmed === true) props.onConfirmed();
  };

  return (
    <>
      <Button
        {...omit(props, ["confirmationTitle", "confirmationText", "onConfirmed", "clicksStopPropagation"])}
        onClick={(e) => handleClick(e as any, () => setDialogOpen(true))}
      />
      <Dialog
        open={dialogOpen}
        onClose={() => handleClose()}
        onClick={(e) => handleClick(e as any)}
        aria-labelledby={`${componentId}-title`}
        aria-describedby={`${componentId}-description`}
      >
        <DialogTitle id={`${componentId}-title`}>{props.confirmationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id={`${componentId}-description`}>{props.confirmationText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClick(e as any, handleClose)}>Cancel</Button>
          <Button onClick={(e) => handleClick(e as any, () => handleClose(true))} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
