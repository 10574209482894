import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import preferencesReducer from "./preferencesSlice";

// Note: When returning undefined from this method, Redux will instead
// call getInitialState() on all its reducers and still initialize
// the default state of the store correctly that way.
const preloadState = () => {
  const value = localStorage.getItem("subms");
  return value ? JSON.parse(value) : undefined;
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    preferences: preferencesReducer,
  },
  preloadedState: preloadState(),
});

store.subscribe(() => {
  localStorage.setItem("subms", JSON.stringify(store.getState()));
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
