import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Subscription,
  SubscriptionProduct,
  useSubscription,
  useSubscriptionModifier,
} from "../../../service/subscriptions";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import { LineItem, useLineItemModifier, useLineItems } from "../../../service/lineitems";
import { useState } from "react";
import { BooleanEditableField, ChoiceEditableField, StringEditableField } from "../../../components/editable";
import { useDictionary } from "../../../service/dictionary";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { USAGE_METRIC_ALL_FIELDS, USAGE_METRIC_FIELDS, ValidationError } from "../../../service/api";
import { ValidationErrors } from "../../../components/ValidationErrors";
import { useNotifications } from "../../../notifications";
import { LineItemAccordion } from "../../components/lineitems/LineItemAccordion";
import { usePreferences } from "../../../service/preferences";
import { FieldsCollection } from "../../../components/FieldsCollection";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../service/auth";
import { Header } from "../../Header";
import { ContentBox, ControlContentBox } from "../../../components/ContentBox";
import { useSubscriptionProducts } from "../../../service/subscriptionproducts";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";
import { financialEndDate, isWithinClosedInterval } from "../../../utils/date";
import { ConfirmedButton } from "../../../components/ConfirmedButton";
import { LineItemSelect } from "../../components/lineitems/LineItemSelect";
import Switch from "@mui/material/Switch";
import { config } from "../../../config";

export function makeCATLink(sfdcReference: string, subscriptionId: number | null) {
  return `${config.catUrl}search/subms?account_id=${sfdcReference}&subscription_id=${subscriptionId}`;
}

export function ActiveConfiguration(props: { config: SubscriptionProduct; showTitle: boolean }): JSX.Element {
  const { t } = useTranslation();
  const { formatDate } = usePreferences();
  const { dictionary } = useDictionary();

  const usageMetricFields = USAGE_METRIC_FIELDS[props.config.usageMetric] || [];
  const usageMetricFieldsData: [string, string][] = USAGE_METRIC_ALL_FIELDS.filter((fieldName) =>
    usageMetricFields.includes(fieldName)
  ).map((fieldName) => [t(`fields.${fieldName}.label`), (props.config as any)[fieldName] || "unlimited"]);

  const getEditionText = () => {
    if (!dictionary.EDITIONS_PER_PRODUCT_TYPE[props.config.productType]) return "N/A";
    if (!props.config.edition) return "None";
    return dictionary.EDITIONS[props.config.edition];
  };

  // note: If and when this needs to be changed, consider an implementation similar to LineItemAccordionInfo
  // which is considerably more flexible and open to extension instead of using FieldsCollection
  return (
    <>
      <p>{props.showTitle && <strong>Current active configuration for product</strong>}</p>
      <FieldsCollection
        columns={[
          [
            {
              group: null,
              fields: [
                ["Start date", formatDate(props.config.startDate)],
                ["End date", formatDate(props.config.endDate)],
                ["Support", dictionary.SUPPORT_LEVEL_TYPES[props.config.supportLevel]],
                [
                  "Type of hardware devices",
                  props.config.deviceTypes?.map((s) => dictionary.DEVICE_TYPES[s]).join(", ") || "None",
                ],
                ["Usecase Packs", (props.config.useCasePacks || []).join(", ")],
              ],
            },
          ],
          [
            {
              group: null,
              fields: [
                ["Addons", (props.config.addons || []).map((v) => dictionary.ADDONS[v]).join(", ") || "None"],
                ["Edition", getEditionText()],
                ["Barcode symbologies", props.config.symbologies.map((s) => dictionary.SYMBOLOGY_NAMES[s]).join(", ")],
                ["Platforms", props.config.platforms?.map((s) => dictionary.PLATFORMS[s]).join(", ") || "None"],
                ["Usecase Suites", (props.config.useCaseSuites || []).join(", ")],
              ],
            },
          ],
          [
            {
              group: null,
              fields: [["Licensed usage metric", dictionary.USAGE_METRICS[props.config.usageMetric]]]
                .concat(usageMetricFieldsData)
                .concat([["Geo. territory", (props.config.geography || []).join(", ") || "worldwide"]])
                .concat([["Usecases", (props.config.useCases || []).join(", ")]]) as any,
            },
          ],
        ]}
      />
    </>
  );
}

function HeaderControls(props: { stateDate: Date; setDate: React.Dispatch<React.SetStateAction<Date>> }): JSX.Element {
  const { dateFormat } = usePreferences();

  const onChange = (date: any, value?: string | null | undefined) => {
    if (isValid(date)) {
      props.setDate(date!);
    }
  };

  return (
    <DesktopDatePicker
      inputFormat={dateFormat}
      value={props.stateDate}
      onChange={onChange}
      renderInput={(params: any) => <TextField {...params} />}
    />
  );
}

function LineItemsByProduct(props: {
  subscription: Subscription;
  lineItems: LineItem[];
  onDelete: (lineItem: LineItem) => Promise<void>;
  stateDate: Date;
}): JSX.Element {
  const { hasPermission } = usePermissions();
  const canChange = hasPermission("basicEditing");
  const [open, setOpen] = useState<number | null>(null);
  const { dictionary } = useDictionary();
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();

  // group line items by product, make it an array of [product type, line items] and sort by product type
  const byProduct: [string, LineItem[]][] = sortBy(
    Object.entries(groupBy(props.lineItems, (li: LineItem) => li.productType)),
    (e: [string, LineItem]) => e[0]
  ) as any;

  // BA-55 lineItems are unordered - we order them by asc. start date (oldest on top)
  // using ISO dates lexicographic order
  byProduct.forEach((value: [string, LineItem[]]) => {
    value[1].sort((a: LineItem, b: LineItem): number => (a.startDate < b.startDate ? -1 : 1));
  });

  const { data: activeProducts } = useSubscriptionProducts(
    props.subscription.accountId,
    props.subscription.id!,
    undefined,
    {
      date: formatISO(props.stateDate, { representation: "date" }),
    }
  );

  const activeConfigByProduct = Object.fromEntries<SubscriptionProduct>(
    (activeProducts ?? []).map((sp) => [sp.productType, sp]) || []
  );

  async function onDeleteHelper(lineItem: LineItem): Promise<boolean> {
    props.onDelete(lineItem);
    return true;
  }

  return (
    <>
      {byProduct.map(([productType, productLineItems]) => (
        <Box key={productType} my={3}>
          <ContentBox key={productType} headerVariant="subtitle1" header={dictionary.PRODUCT_TYPES[productType]}>
            {activeConfigByProduct[productType] ? (
              <ActiveConfiguration showTitle={true} config={activeConfigByProduct[productType]} />
            ) : (
              <Alert severity="info">Subscription has no active configuration for this product</Alert>
            )}

            <p>
              <strong>Line items</strong>
            </p>

            {productLineItems.map((lineItem) => (
              <LineItemAccordion
                expanded={open == lineItem.id}
                onExpand={(state) => (state ? setOpen(lineItem.id) : setOpen(null))}
                accountId={accountId}
                lineItem={lineItem}
                onDelete={canChange ? () => onDeleteHelper(lineItem) : undefined}
                deleteLabel="Remove from subscription"
                key={lineItem.id}
                goToContract={() => history.push(`/accounts/${accountId}/contracts/${lineItem.contractId}`)}
                contract={null}
                inactive={
                  !isWithinClosedInterval(props.stateDate, {
                    start: parseISO(lineItem.startDate),
                    end: financialEndDate(lineItem),
                  })
                }
              />
            ))}
          </ContentBox>
        </Box>
      ))}
    </>
  );
}

function LineItems(props: {
  subscription: Subscription;
  lineItems: LineItem[];
  onChange: (lineItem: LineItem) => Promise<void>;
  onDelete: (lineItem: LineItem) => Promise<void>;
  stateDate: Date;
}): JSX.Element {
  const { hasPermission } = usePermissions();
  const canChange = hasPermission("basicEditing");
  const { dictionary } = useDictionary();
  const [selectedLineItem, setSelectedLineItem] = useState<LineItem | null>(null);
  const { formatDate, formatMoney } = usePreferences();
  const {
    data: availableLineItems,
    isLoading: isLoadingProductLineItems,
    mutate: mutateProductLineItems,
  } = useLineItems(props.subscription.accountId, 0, {
    integration_path: props.subscription.integrationPath,
    user_type: props.subscription.userType,
    usage_metric: props.subscription.usageMetric,
    // having subscription empty, line items which are not assigned yet
    subscription: "",
    // product line items only
    purpose_type: "product",
  });

  const {
    data: maintenanceLineItems,
    isLoading: isLoadingMaintenanceLineItems,
    mutate: mutateMaintenanceLineItems,
  } = useLineItems(props.subscription.accountId, 0, {
    // having subscription empty, line items which are not assigned yet
    subscription: "",
    // maintenance pan line items only
    purpose_type: "maintenance_plan",
  });

  const totalLineItems = [...(availableLineItems ?? []), ...(maintenanceLineItems ?? [])];

  async function handleAdd() {
    if (!selectedLineItem) return;
    await props.onChange(selectedLineItem);
    await mutateProductLineItems();
    await mutateMaintenanceLineItems();
    setSelectedLineItem(null);
  }

  async function handleDelete(lineItem: LineItem) {
    await props.onDelete(lineItem);
    await mutateProductLineItems();
    await mutateMaintenanceLineItems();
  }

  return (
    <>
      {canChange && (
        <Grid item xs={12}>
          <ContentBox header="Add line items">
            <Grid container item xs={12}>
              {isLoadingProductLineItems || isLoadingMaintenanceLineItems ? (
                <CircularProgress size="15px" />
              ) : (totalLineItems || []).length == 0 ? (
                <Alert severity="warning">No line items available matching this subscription</Alert>
              ) : (
                <>
                  <Grid item xs={3}>
                    <LineItemSelect
                      availableLineItems={totalLineItems}
                      selectedLineItem={selectedLineItem}
                      setSelectedLineItem={setSelectedLineItem}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button onClick={handleAdd} disabled={selectedLineItem == null}>
                      Add
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </ContentBox>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="h5">Products included in subscription</Typography>
        <LineItemsByProduct
          stateDate={props.stateDate}
          subscription={props.subscription}
          lineItems={props.lineItems}
          onDelete={handleDelete}
        />
      </Grid>
    </>
  );
}

function SubscriptionForm(props: {
  subscription: Subscription;
  onChange: (changes: { [key: string]: any }) => Promise<void>;
  errors: { [key: string]: string[] } | null;
  stateDate: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}): JSX.Element {
  const { hasPermission } = usePermissions();
  const canChange = hasPermission("basicEditing");
  const { dictionary } = useDictionary();
  const { addNotification } = useNotifications();
  const subscription = props.subscription;
  const {
    data: lineItems,
    isLoading: lineItemsLoading,
    mutate: mutateLineItems,
  } = useLineItems(props.subscription.accountId, 0, {
    subscription: String(subscription.id),
  });
  const { mutate: mutateSubscriptionProducts } = useSubscriptionProducts(
    props.subscription.accountId,
    props.subscription.id!,
    undefined,
    {
      date: formatISO(props.stateDate, { representation: "date" }),
    }
  );
  const { update: updateLineItem } = useLineItemModifier(subscription.accountId);

  async function updateLineItemSubscription(lineItem: LineItem, subscriptionId: number | null): Promise<void> {
    await updateLineItem(
      Object.assign<LineItem, LineItem, {}>({} as LineItem, lineItem, { subscriptionId: subscriptionId })
    );
    await mutateLineItems();
  }

  async function handleLineItem(lineItem: LineItem): Promise<void> {
    try {
      await updateLineItemSubscription(lineItem, subscription.id);
    } catch (error) {
      if (error instanceof ValidationError) {
        addNotification({
          text: (
            <span>
              Line item could not be added because it isn't valid.
              <br />
              Please go to the contract page and edit the line item.
            </span>
          ),
          level: "error",
        });
        return;
      }
      throw error;
    }
    addNotification({
      text: "Line item added to subscription",
      level: "success",
    });
  }

  async function handleLineItemDelete(lineItem: LineItem): Promise<void> {
    try {
      await updateLineItemSubscription(lineItem, null);
    } catch (error) {
      addNotification({
        text: "Line item could be removed due to unexpected error, please try again",
        level: "error",
      });
      return;
    }
    addNotification({
      text: "Line item removed from subscription",
      level: "success",
    });
  }

  async function handleChangeUsageMetricBySubscription(
    subscription: Subscription,
    lineItems: LineItem[]
  ): Promise<void> {
    const changedUsageMetric = subscription.usageMetric.replace("by-lk", "by-sub");
    try {
      // change subscription and line items usage metrics from by-lk to by-sub, atomically
      await props.onChange({ usageMetric: changedUsageMetric });
      // refresh line items and subscription products
      await Promise.all([mutateLineItems(), mutateSubscriptionProducts()]);
    } catch (error) {
      addNotification({
        text: "Subscription and line items usage metric could not be changed due to unexpected error",
        level: "error",
      });
    }
  }

  return (
    <Grid container spacing={4}>
      {props.errors && (
        <Grid item xs={12}>
          <ValidationErrors title="Subscription invalid" errors={props.errors} />
        </Grid>
      )}

      <Grid item xs={12}>
        <ControlContentBox
          controls={<HeaderControls stateDate={props.stateDate} setDate={props.setDate} />}
          controlWidth={3}
          header="Subscription Overview"
        >
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12}>
              <StringEditableField
                label="Name"
                value={subscription.name}
                required
                onChange={(v) => props.onChange({ name: v })}
                editable={canChange}
              />
            </Grid>
            <Grid item xs={12}>
              <ChoiceEditableField
                label="Integration path"
                value={subscription.integrationPath}
                choices={dictionary.INTEGRATION_PATHS}
                onChange={(v) => props.onChange({ integrationPath: v })}
                editable={canChange && lineItems && lineItems.length == 0}
              />
            </Grid>
            <Grid item xs={12}>
              <ChoiceEditableField
                label="User type"
                value={subscription.userType}
                choices={dictionary.USER_TYPES}
                onChange={(v) => props.onChange({ userType: v })}
                editable={canChange && lineItems && lineItems.length == 0}
              />
            </Grid>
            <Grid item xs={12}>
              <ChoiceEditableField
                label="License usage metric"
                value={subscription.usageMetric}
                choices={dictionary.USAGE_METRICS}
                onChange={(v) => props.onChange({ usageMetric: v })}
                editable={canChange && lineItems && lineItems.length == 0}
              />
              {subscription.usageMetric.endsWith("by-lk") && canChange && lineItems && lineItems.length > 0 ? (
                <ConfirmedButton
                  variant="outlined"
                  confirmationTitle="Change Usage Metric"
                  confirmationText={`Editing the usage metric of the subscription to ${dictionary.USAGE_METRICS[
                    subscription.usageMetric
                  ].replace(
                    "by license key",
                    "by subscription"
                  )} will also change the usage metric on the line items. Do you want to proceed?`}
                  size="small"
                  onConfirmed={() => {
                    handleChangeUsageMetricBySubscription(subscription, lineItems);
                  }}
                >
                  Change to {dictionary.USAGE_METRICS[subscription.usageMetric].split("by")[0]} by subscription
                </ConfirmedButton>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <BooleanEditableField
                label="Production subscription"
                value={subscription.isProduction}
                onChange={(v) => props.onChange({ isProduction: v })}
                editable={canChange}
              />
            </Grid>

            <Grid item xs={12}>
              <div>
                <Typography variant="h6">
                  <b>Canceled</b>
                </Typography>
                <Switch
                  checked={!!subscription.canceled}
                  disabled={!canChange}
                  onChange={(v) => {
                    props.onChange({ canceled: !subscription.canceled });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <a href={makeCATLink(subscription.accountId, subscription.id)} target="_blank" rel="noopener noreferrer">
                <Button color="primary" size="small" variant="contained">
                  Open in CAT
                </Button>
              </a>
            </Grid>
          </Grid>
        </ControlContentBox>
      </Grid>

      {lineItemsLoading || lineItems === undefined ? (
        <CircularProgress size="15px" />
      ) : (
        <LineItems
          lineItems={lineItems}
          stateDate={props.stateDate}
          onChange={handleLineItem}
          onDelete={handleLineItemDelete}
          subscription={subscription}
        />
      )}
    </Grid>
  );
}

export function Show(): JSX.Element {
  const { accountId, subscriptionId } = useParams<{ accountId: string; subscriptionId: string }>();
  const { update: updateSubscription } = useSubscriptionModifier(accountId);
  const {
    isLoading,
    data: subscription,
    mutate: mutateSubscription,
  } = useSubscription(accountId, parseInt(subscriptionId));
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);
  const [stateDate, setStateDate] = useState<Date>(new Date());
  const { addNotification } = useNotifications();

  async function onChange(changes: { [key: string]: any }): Promise<void> {
    // By license key metrics are legacy: we display a warning to the user.
    if ("usageMetric" in changes && changes.usageMetric.endsWith("by-lk")) {
      addNotification({
        level: "warning",
        text: "The selected usage metric is deprecated (legacy) and should only be used for historic data, not new contracts",
      });
    }

    setErrors(null);
    const newSubscription = cloneDeep<Subscription>(subscription!);
    Object.entries(changes).forEach((entry) => {
      (newSubscription as any)[entry[0]] = entry[1];
    });
    let updatedSubscription;
    try {
      updatedSubscription = await updateSubscription(newSubscription);
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.errors);
        return;
      }
      throw error;
    }
    await mutateSubscription(updatedSubscription);
    addNotification({
      text: "Subscription changed",
      level: "success",
    });
  }

  return (
    <>
      <Header accountId={accountId} subscription={subscription} loading={isLoading} />

      {isLoading && <CircularProgress size="15px" />}
      {subscription && (
        <SubscriptionForm
          setDate={setStateDate}
          stateDate={stateDate}
          onChange={onChange}
          errors={errors}
          subscription={subscription}
        />
      )}
    </>
  );
}
