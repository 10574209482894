import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDictionary } from "../../../service/dictionary";
import { isMaintenance, LineItem } from "../../../service/lineitems";
import { usePreferences } from "../../../service/preferences";

export function LineItemSelect(props: {
  availableLineItems: LineItem[] | undefined;
  selectedLineItem: LineItem | null;
  setSelectedLineItem: React.Dispatch<React.SetStateAction<LineItem | null>>;
}) {
  const { formatDate, formatMoney } = usePreferences();
  const { dictionary } = useDictionary();

  return (
    <FormControl fullWidth>
      <InputLabel id="line-items-label">Available line items</InputLabel>
      <Select
        value={props.selectedLineItem || ""}
        onChange={(e) => props.setSelectedLineItem(e.target.value == "" ? null : (e.target.value as LineItem))}
        labelId="line-items-label"
        label="Available line items"
      >
        {[
          <MenuItem key="" value="">
            -
          </MenuItem>,
        ].concat(
          (props.availableLineItems || []).map((lineItem) => (
            <MenuItem key={lineItem.id} value={lineItem as any}>
              {isMaintenance(lineItem) ? (
                <>
                  <i>Maintenance plan</i>&nbsp;&nbsp;
                </>
              ) : (
                <>
                  &nbsp;
                  {dictionary.PRODUCT_TYPES[lineItem.productType] || lineItem.productType}
                  &nbsp;+&nbsp;
                  {dictionary.INTEGRATION_PATHS[lineItem.integrationPath] || lineItem.integrationPath}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
              <strong>From:</strong>&nbsp;{formatDate(lineItem.startDate)}&nbsp;&nbsp;
              <strong>To:</strong>&nbsp;{lineItem.endDate ? formatDate(lineItem.endDate) : "∞"}
              &nbsp;&nbsp;
              <strong>Total value:</strong>&nbsp;{formatMoney(lineItem.salesPrice, lineItem.currency)}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
}
