import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import * as React from "react";
import { useDictionary } from "../../../service/dictionary";
import { LineItem } from "../../../service/lineitems";
import EditIcon from "@mui/icons-material/Edit";
import PieChartIcon from "@mui/icons-material/PieChart";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAnnotationModifier, useAnnotations, UseCaseAnnotation } from "../../../service/usecaseannotations";
import { ValidationError } from "../../../service/api";
import { UsecaseFormValues } from "./types";
import { UseCaseForm } from "./UseCaseForm";
import { usePermissions } from "../../../service/auth";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: "0px",
      margin: "0px",
    },
  })
);

export const CustomUsecases = (props: {
  accountId: string;
  lineitem: LineItem;
  editingAnnotation: UseCaseAnnotation | undefined;
  setEditingAnnotation: React.Dispatch<React.SetStateAction<UseCaseAnnotation | undefined>>;
  onDiscard: () => void;
  usageLimit: number;
  setErrors: any;
}) => {
  const classes = useStyles();
  const { dictionary } = useDictionary();
  const { hasPermission } = usePermissions();

  if (!props.lineitem.id) return <></>;

  const { data: annotations, mutate: mutateAnnotations } = useAnnotations(props.accountId, props.lineitem.id);
  const annotationModifier = useAnnotationModifier(props.accountId, props.lineitem.id);

  const onDelete = async (annotation: UseCaseAnnotation | undefined) => {
    if (!annotation) return;

    try {
      await annotationModifier.delete(annotation);
    } catch (error) {
      if (error instanceof ValidationError) {
        props.setErrors(error.errors);
      }
    }

    mutateAnnotations();
  };

  const onEdit = async (annotation: UseCaseAnnotation | undefined) => {
    props.setEditingAnnotation(annotation);
  };

  const onSubmitEdit = async (data: UsecaseFormValues) => {
    const edited: UseCaseAnnotation = { ...data, lineItem: props.lineitem.id!, id: props.editingAnnotation?.id };
    try {
      await annotationModifier.update(edited);
      await mutateAnnotations();
      props.onDiscard();
    } catch (error) {
      if (error instanceof ValidationError) {
        props.setErrors(error.errors);
      }
    }
  };

  return (
    <>
      {annotations?.map((annotation) =>
        annotation === props.editingAnnotation ? (
          <Grid item xs={3} key={annotation.id}>
            <UseCaseForm
              accountId={props.accountId}
              lineitem={props.lineitem}
              onSubmit={onSubmitEdit}
              onDiscard={props.onDiscard}
              editValues={annotation}
              usageLimit={props.usageLimit}
            />
          </Grid>
        ) : (
          <Grid item xs={3} key={annotation.id}>
            <Card>
              <CardContent>
                <Grid container item xs={12}>
                  <Grid item xs={5}>
                    <Typography gutterBottom>
                      {dictionary.USER_TYPES[props.lineitem.userType] || props.lineitem.userType}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start">
                      <PieChartIcon fontSize="small" />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    {annotation.percentage}%
                  </Grid>
                  <Grid container item xs={1} justifyContent="flex-end" alignItems="flex-start">
                    <IconButton
                      disabled={!hasPermission("useCaseAnnotation")}
                      onClick={() => onEdit(annotation)}
                      className={classes.button}
                      size="small"
                      edge="end"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid container item xs={1} justifyContent="flex-end" alignItems="flex-start">
                    <IconButton
                      disabled={!hasPermission("useCaseAnnotation")}
                      onClick={() => onDelete(annotation)}
                      className={classes.button}
                      size="small"
                      edge="end"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    <strong>Usecase Pack:</strong>
                  </Grid>
                  <Grid item xs={6}>
                    {annotation.useCasePack}
                  </Grid>
                  <Grid item xs={4}>
                    <strong>Usecase Suite:</strong>
                  </Grid>
                  <Grid item xs={6}>
                    {annotation.useCaseSuite}
                  </Grid>
                  <Grid item xs={4}>
                    <strong>Usecase:</strong>
                  </Grid>
                  <Grid item xs={6}>
                    {annotation.useCase}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      )}
    </>
  );
};
