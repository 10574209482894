import * as React from "react";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";

interface ContractLinkProps {
  accountId: string;
  // contractId can be a contract id (-> clickable link) or null (-> no clickable link)
  contractId: number | null;
  children: React.ReactNode;
}

const ContractLink = React.memo((props: ContractLinkProps) => {
  const link = `/accounts/${props.accountId}/contracts/${props.contractId}`;

  // If there are no children props, component will display the actual link instead
  return props.contractId !== null ? (
    <MuiLink to={link} component={Link}>
      {props.children}
    </MuiLink>
  ) : (
    <></>
  );
});

export { ContractLink };
