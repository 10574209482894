import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import cloneDeep from "lodash/cloneDeep";
import { LineItem } from "../../../../service/lineitems";
import { LineItemAccordion } from "../../../components/lineitems/LineItemAccordion";
import { LineItemFormFields } from "../../../components/lineitems/LineItemAccordionForm";
import { usePermissions } from "../../../../service/auth";
import { ContentBox } from "../../../../components/ContentBox";
import { Contract } from "../../../../service/contracts";
import { useDictionary } from "../../../../service/dictionary";
import ButtonGroup from "@mui/material/ButtonGroup";

export interface Props {
  errors?: { [key: number]: { [key: string]: string[] } };
  lineItems: LineItem[];
  onChange: (lineItem: LineItem, idx: number) => Promise<boolean>;
  onDelete: (lineItem: LineItem, idx: number) => Promise<boolean>;
  allowSalesforceRefresh: boolean;
  contract: Contract | null;
  accountId: string;
}

export function ContractLineItems(props: Props): JSX.Element {
  const { hasPermission } = usePermissions();
  const canChange = hasPermission("basicEditing");
  const [open, setOpen] = useState<number | null>(null);
  const [edit, setEdit] = useState<number | null>(null);
  const [connect, setConnect] = useState<number | null>(null);
  const [pending, setPending] = useState<LineItem | null>(null);
  const { dictionary } = useDictionary();

  // defaults for new line items - applicable only for "product" line items.
  const userType: string | null = props.lineItems.map((li) => li.userType)[0] || null;
  const integrationPath: string | null = props.lineItems.map((li) => li.integrationPath)[0] || null;
  const currency: string | null = props.lineItems.map((li) => li.currency)[0] || null;

  const requirements: any = {};

  const sortedLineItems = [...props.lineItems];

  if (props.lineItems.length > 1) {
    // BA-126 Only require currency to be consistent across all line items if there are 2 or more.
    requirements["currency"] = currency;
    // BA-55 lineItems are unordered - we order them by asc. start date (oldest on top)
    // using ISO dates lexicographic order
    sortedLineItems.sort((a: LineItem, b: LineItem): number => (a.startDate < b.startDate ? -1 : 1));
  }

  function handleNewLineItem(purposeType: "product" | "maintenance_plan") {
    // handles the "hidden fields" of maintenance plan LineItems by
    // initializing a LineItem with a compatible configuration
    const isProduct = purposeType === "product";
    const firstKey = (d: Object) => Object.keys(d)[0];
    const newLineItem: any = {
      id: null,
      priceBookId: null,
      productId: null,
      addons: [],
      analytics: "with_analytics",
      anonymizeIp: false,
      avgMonthlyDevicesPerStore: null,
      avgScansPerStore: null,
      communicationSalesPackage: ["default"],
      contractId: null,
      currency: isProduct ? currency || "" : firstKey(dictionary.CURRENCIES),
      deviceTypes: isProduct ? "" : firstKey(dictionary.DEVICE_TYPES),
      endDate: null,
      geography: null,
      edition: null,
      integrationPath: isProduct ? integrationPath || "" : firstKey(dictionary.INTEGRATION_PATHS),
      isProductionLicense: false,
      licenseType: isProduct ? "" : firstKey(dictionary.LICENSE_TYPES),
      maxActivations: null,
      maxDevicesPerMonth: null,
      maxDevicesPerStorePerMonth: null,
      maxDevicesPerYear: null,
      maxScansPerDevicePerYear: null,
      maxScansPerStorePerMonth: null,
      maxScansPerYear: null,
      maxStores: null,
      platforms: isProduct ? [] : [firstKey(dictionary.PLATFORMS)],
      productType: isProduct ? "" : firstKey(dictionary.PRODUCT_TYPES),
      salesPrice: 0,
      startDate: "",
      subscriptionId: null,
      supportLevel: isProduct ? "" : firstKey(dictionary.SUPPORT_LEVEL_TYPES),
      symbologies: [],
      usageMetric: isProduct ? "" : firstKey(dictionary.USAGE_METRICS),
      useCase: null,
      useCasePack: null,
      useCaseSuite: null,
      userType: isProduct ? userType || "" : firstKey(dictionary.USER_TYPES),
      purposeType: purposeType,
    };
    setPending(newLineItem);
    setEdit(null);
    setOpen(null);
  }

  async function handleDuplicate(lineItem: LineItem, index: number): Promise<boolean> {
    const newItem = cloneDeep(lineItem);
    newItem.id = null;
    // When cloning a line item, we don't want to preserve its subscription membership too.
    newItem.subscriptionId = null;
    // also reset children and parents that should not be cloned
    newItem.children = [];
    newItem.parents = [];
    const result = await props.onChange(newItem, index);
    if (result) {
      setEdit(null);
      setOpen(null);
    }
    return true;
  }

  async function handleChange(lineItem: LineItem, changes: LineItemFormFields, idx: number): Promise<boolean> {
    const changedLineItem = cloneDeep(lineItem);
    Object.assign(changedLineItem, changes);
    const result = await props.onChange(changedLineItem, idx);
    if (result) {
      setEdit(null);
      setOpen(null);
    }
    return true;
  }

  async function handleDelete(lineItem: LineItem, idx: number): Promise<boolean> {
    return await props.onDelete(lineItem, idx);
  }

  async function setPendingNull(): Promise<boolean> {
    setPending(null);
    return true;
  }

  async function onRefresh(): Promise<boolean> {
    return true;
  }

  return (
    <ContentBox header="Line items">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {pending && (
            <LineItemAccordion
              requirements={requirements}
              errors={(props.errors || {})[-1]}
              newItem={true}
              edit={true}
              expanded={true}
              accountId={props.accountId}
              lineItem={pending}
              onChange={(changes) => handleChange(pending!, changes, -1)}
              onDelete={() => setPendingNull()}
              contract={props.contract}
            />
          )}
          {sortedLineItems.map((lineItem, idx) => (
            <LineItemAccordion
              refreshable={props.allowSalesforceRefresh}
              onRefresh={canChange ? onRefresh : undefined}
              requirements={requirements}
              edit={edit == idx}
              connect={connect == idx}
              onEdit={
                canChange
                  ? (state) => {
                      if (state) {
                        setEdit(idx);
                        setOpen(idx);
                      } else {
                        setEdit(null);
                        setOpen(null);
                      }
                    }
                  : undefined
              }
              onConnect={
                canChange
                  ? (state) => {
                      setEdit(null);
                      if (state) {
                        setConnect(idx);
                        setOpen(idx);
                      } else {
                        setConnect(null);
                        setOpen(null);
                      }
                    }
                  : undefined
              }
              errors={(props.errors || {})[idx]}
              expanded={!!(open == idx || (props.errors || {})[idx])}
              onExpand={(state) => {
                if (state) {
                  if (open != idx) setEdit(null);
                  if (open != idx) setConnect(null);
                  setOpen(idx);
                } else {
                  setOpen(null);
                }
              }}
              accountId={props.accountId}
              lineItem={lineItem}
              onChange={canChange ? (changes) => handleChange(lineItem, changes, idx) : undefined}
              onDelete={canChange ? () => handleDelete(lineItem, idx) : undefined}
              onDuplicate={canChange ? () => handleDuplicate(lineItem, idx) : undefined}
              key={lineItem.id || idx}
              contract={props.contract}
            />
          ))}
        </Grid>
        {canChange && (
          <Grid item xs={12}>
            <ButtonGroup
              color="primary"
              size="small"
              variant="contained"
              disabled={pending != null}
              aria-label="outlined primary button group"
            >
              <Button onClick={() => handleNewLineItem("product")}>Add line item</Button>
              <Button onClick={() => handleNewLineItem("maintenance_plan")}>Add maintenance line item</Button>
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
    </ContentBox>
  );
}
