import {
  ResourceListResult,
  ResourceModifier,
  ResourceResult,
  useServiceResource,
  useServiceResourceList,
  useServiceResourceModifier,
} from "./api";

export interface UseCaseAnnotation {
  id?: number;
  lineItem: number;
  useCase: string;
  useCasePack: string;
  useCaseSuite: string;
  percentage: string;
}

export function useAnnotations(
  accountId: string,
  lineItemId: number,
  page?: number
): ResourceListResult<UseCaseAnnotation> {
  return useServiceResourceList<UseCaseAnnotation>(
    `/v1/accounts/${accountId}/lineitems/${lineItemId}/annotations/`,
    page
  );
}

export function useAnnotation(
  accountId: string,
  lineItemId: number,
  annotationId: number
): ResourceResult<UseCaseAnnotation> {
  return useServiceResource<UseCaseAnnotation>(
    `/v1/accounts/${accountId}/lineitems/${lineItemId}/annotations/${annotationId}/`
  );
}

export function useAnnotationModifier(accountId: string, lineItemId: number): ResourceModifier<UseCaseAnnotation> {
  return useServiceResourceModifier<UseCaseAnnotation>(
    `/v1/accounts/${accountId}/lineitems/${lineItemId}/annotations/`
  );
}
