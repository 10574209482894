export interface SalesforceObject {
  id: string;
}

export interface SalesforceQueryResult<T> {
  isLoading: boolean;
  isError: boolean;
  result: T[] | null;
  resultById: { [key: string]: T } | null;
  count?: number;
}
export interface SalesforceCountQueryResult {
  isLoading: boolean;
  isError: boolean;
  total: number | null;
}
export interface SalesforceObjectResult<T> {
  isLoading: boolean;
  isError: boolean;
  result: T | null;
}

export function objectsById<T extends SalesforceObject>(objects: T[]): { [key: string]: T } {
  const byId: { [key: string]: T } = {};
  objects.forEach((obj) => {
    byId[obj.id] = obj;
  });
  return byId;
}
