import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { ContentBox } from "../../components/ContentBox";
import { ObjectsTable } from "../../components/ObjectsTable";
import MuiLink from "@mui/material/Link";
import { useForEachPage, useServiceResourceList } from "../../service/api";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import { DatePicker } from "../../components/DatePicker";
import { Link } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CsvDownloader from "react-csv-downloader";
import { usePermissions } from "../../service/auth";
import { useNotifications } from "../../notifications";
import LoadingButton from "@mui/lab/LoadingButton";

import { makeSalesforceLink } from "../../components/SalesforceLink";

export interface ExpiringSubscription {
  subscriptionId: string;
  subscriptionName: string;
  accountId: string;
  endDate: string;
}

export interface ExpiringContract {
  opportunityId: string;
  contractId: string;
  contractName: string;
  accountId: string;
  accountName: string;
  endDate: string;
  status: string;
  acv: string;
  groupFee: string;
}

function accColumn(accObject: ExpiringSubscription | ExpiringContract): JSX.Element | string {
  return (
    <MuiLink component={Link} color="primary" to={`/accounts/${accObject.accountId}`}>
      {accObject.accountId}
    </MuiLink>
  );
}

function subscriptionColumn(sub: ExpiringSubscription): JSX.Element | string {
  return (
    <MuiLink component={Link} color="primary" to={`/accounts/${sub.accountId}/subscriptions/${sub.subscriptionId}`}>
      {sub.subscriptionName}
    </MuiLink>
  );
}

function contractColumn(contract: ExpiringContract): JSX.Element | string {
  return (
    <MuiLink component={Link} color="primary" to={`/accounts/${contract.accountId}/contracts/${contract.contractId}`}>
      {contract.contractName}
    </MuiLink>
  );
}

function RenewalsTopBar<TResult>(props: {
  resourceId: string;
  startDate: Date;
  endDate: Date;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  columnNames?: { [key: string]: string };
  postprocessReceivedData?: (results: TResult[]) => any;
}): JSX.Element {
  const { addNotification } = useNotifications();
  const { hasPermission } = usePermissions();
  const { control, handleSubmit, watch } = useForm<{ startDate: string; endDate: string }>({
    defaultValues: {
      startDate: formatISO(new Date(), { representation: "date" }),
      endDate: formatISO(new Date(), { representation: "date" }),
    },
  });
  const [receivedData, setReceivedData] = useState<TResult[] | null>(null);
  const [progress, setProgress] = useState<number | null>(null);
  const forEachPage = useForEachPage<{ start_date: string; end_date: string }, TResult[]>(props.resourceId);

  const startDate = watch("startDate");
  const endDate = watch("endDate");

  const submitDateRange: SubmitHandler<{ startDate: Date; endDate: Date }> = async (data: {
    startDate: Date;
    endDate: Date;
  }) => {
    // callbacks to update table
    props.setStartDate(data.startDate);
    props.setEndDate(data.endDate);
    props.setPage(1);
    // flush received data for CSV export
    setProgress(null);
    setReceivedData(null);
  };

  useEffect(() => {
    if (startDate && endDate) {
      try {
        const [parsedStartDate, parsedEndDate] = [parseISO(startDate), parseISO(endDate)];
        submitDateRange({ startDate: parsedStartDate, endDate: parsedEndDate });
      } catch (RangeError) {
        // it does not validate the format, ignore
      }
    }
  }, [startDate, endDate]);

  const submitCsvExport: SubmitHandler<{ startDate: string; endDate: string }> = async (formFields: {
    startDate: string;
    endDate: string;
  }) => {
    setReceivedData(null);
    setProgress(0.0);

    const aggregate: TResult[] = [];

    forEachPage(
      { start_date: formFields.startDate, end_date: formFields.endDate },
      async (results: TResult[], page: number, pages: number) => {
        aggregate.push(...results);
        setProgress(page / pages);
        if (page == pages) {
          addNotification({
            level: "success",
            text: "All files generated successfully",
          });
        }
        setReceivedData(aggregate);
      }
    );
  };

  const columns = props.columnNames
    ? Object.entries(props.columnNames).map(([fieldKey, colDisplayName]) => ({
        id: fieldKey,
        displayName: colDisplayName,
      }))
    : undefined;

  return (
    <Box component="form" onSubmit={handleSubmit(submitCsvExport)} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <DatePicker
            rules={{
              required: true,
            }}
            helperText=""
            label="Start Date"
            control={control}
            name="startDate"
          />
        </Grid>
        <Grid item xs={5}>
          <DatePicker
            rules={{
              required: true,
            }}
            helperText=""
            label="End date"
            control={control}
            name="endDate"
          />
        </Grid>
        <Grid item xs={2}>
          {hasPermission("financialExport") ? (
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              {progress && progress >= 1.0 ? (
                <CsvDownloader
                  columns={columns}
                  datas={
                    (props.postprocessReceivedData && receivedData
                      ? props.postprocessReceivedData(receivedData)
                      : receivedData) as any
                  }
                  wrapColumnChar={'"'}
                  filename={`${formatISO(new Date(), { representation: "complete" })}-${props.resourceId.replace(
                    "/",
                    "_"
                  )}.csv`}
                >
                  <Button variant="outlined" startIcon={<CloudDownloadIcon />} style={{ height: "100%" }}>
                    Export as CSV
                  </Button>
                </CsvDownloader>
              ) : (
                <LoadingButton
                  variant="contained"
                  loading={progress != null && progress < 1.0}
                  loadingIndicator={(progress ? progress * 100.0 : 0).toFixed(1) + "%"}
                  type="submit"
                >
                  Generate export
                </LoadingButton>
              )}
            </ButtonGroup>
          ) : (
            <Button variant="outlined" disabled startIcon={<CloudDownloadIcon />} style={{ height: "100%" }}>
              Permission required
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

function ExpiringSubscriptions(props: {}): JSX.Element {
  const [expand, setExpand] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const columns = [
    { id: "account", label: "Account", minWidth: 200 },
    { id: "subscription", label: "Subscription", minWidth: 400 },
    { id: "endDate", label: "End Date", minWidth: 150 },
  ];

  const [page, setPage] = useState<number>(1);
  const {
    data: dataExpiringSubscriptions,
    isLoading: isLoadingExpiringSubscriptions,
    isError: isErrorExpiringSubscriptions,
    mutate: mutateExpiringSubscriptions,
    count: countExpiringSubscriptions,
  } = useServiceResourceList<ExpiringSubscription>("/v1/renewals/expiring-subscriptions/", page, {
    order: "asc",
    start_date: formatISO(startDate, { representation: "date" }),
    end_date: formatISO(endDate, { representation: "date" }),
  });
  const rowsExpiringSubscriptions = dataExpiringSubscriptions?.map((sub: ExpiringSubscription) => ({
    id: sub.subscriptionId,
    values: [accColumn(sub), subscriptionColumn(sub), sub.endDate],
  }));

  return (
    <>
      <ContentBox header="Subscriptions Renewals">
        <RenewalsTopBar
          resourceId="renewals/expiring-subscriptions"
          setPage={setPage}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          columnNames={{
            subscriptionId: "Subscription ID",
            subscriptionName: "Subscription Name",
            accountId: "Account ID",
            endDate: "End Date",
          }}
        />
        <br />
        <Accordion expanded={expand} onChange={(_, expanded) => setExpand(expanded)} disableGutters={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <Typography variant="subtitle2" sx={{}}>
              Results ({countExpiringSubscriptions ?? "loading..."})
            </Typography>
          </AccordionSummary>

          <ObjectsTable
            isLoading={isLoadingExpiringSubscriptions}
            isError={isErrorExpiringSubscriptions}
            columns={columns}
            rows={rowsExpiringSubscriptions ?? []}
            totalRows={countExpiringSubscriptions ?? 0}
            pageLimit={20}
            page={page}
            onChange={(n) => {
              setPage(n);
              mutateExpiringSubscriptions();
            }}
          />
        </Accordion>
      </ContentBox>
    </>
  );
}

function ExpiringContracts(props: {}): JSX.Element {
  const [expand, setExpand] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const columns = [
    { id: "account", label: "Account", minWidth: 200 },
    { id: "contract", label: "Contract", minWidth: 300 },
    { id: "endDate", label: "End Date", minWidth: 200 },
    { id: "renewalEmoji", label: "Renewals", minWidth: 50 },
  ];

  const [page, setPage] = useState<number>(1);
  const {
    data: dataExpiringContracts,
    isLoading: isLoadingExpiringContracts,
    isError: isErrorExpiringContracts,
    mutate: mutateExpiringContracts,
    count: countExpiringContracts,
  } = useServiceResourceList<ExpiringContract>("/v1/renewals/expiring-contracts/", page, {
    order: "asc",
    start_date: formatISO(startDate, { representation: "date" }),
    end_date: formatISO(endDate, { representation: "date" }),
  });
  const rowsExpiringContracts = dataExpiringContracts?.map((contract: ExpiringContract) => ({
    id: contract.contractId,
    values: [accColumn(contract), contractColumn(contract), contract.endDate, contract.status],
  }));

  return (
    <>
      <ContentBox header="Contracts Renewals">
        <RenewalsTopBar<ExpiringContract>
          resourceId="renewals/expiring-contracts"
          setPage={setPage}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          columnNames={{
            opportunityId: "Opportunity ID",
            contractId: "Contract ID",
            contractName: "Contract Name",
            accountId: "Account ID",
            accountName: "Account Name",
            endDate: "End Date",
            status: "Status",
            currency: "Currency",
            acv: "Renewal ACV",
            groupFee: "Expiring ACV",
            sfdcLink: "SFDC Link",
          }}
          postprocessReceivedData={(contracts: ExpiringContract[]) => {
            return contracts.map((c: ExpiringContract) => ({
              ...c,
              sfdcLink: c.opportunityId ? makeSalesforceLink(c.opportunityId) : ``,
            }));
          }}
        />
        <br />
        <Accordion expanded={expand} onChange={(_, expanded) => setExpand(expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <Typography variant="subtitle2" sx={{}}>
              Results ({countExpiringContracts ?? "loading..."})
            </Typography>
          </AccordionSummary>
          <br />
          <ObjectsTable
            isLoading={isLoadingExpiringContracts}
            isError={isErrorExpiringContracts}
            columns={columns}
            rows={rowsExpiringContracts ?? []}
            totalRows={countExpiringContracts ?? 0}
            pageLimit={20}
            page={page}
            onChange={(n) => {
              setPage(n);
              mutateExpiringContracts();
            }}
          />
        </Accordion>
      </ContentBox>
    </>
  );
}

export function Renewals(props: {}): JSX.Element {
  //
  return (
    <>
      <ExpiringSubscriptions />
      <ExpiringContracts />
    </>
  );
}
