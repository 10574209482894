import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import BaseModal from "@mui/material/Modal";
import type { Theme } from "@mui/material/styles";
import type { ModalProps } from "@mui/material/Modal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export function Modal(props: ModalProps): JSX.Element {
  const classes = useStyles();

  const modalStyle = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
  return (
    <BaseModal {...props}>
      <div style={modalStyle} className={classes.paper}>
        {props.children}
      </div>
    </BaseModal>
  );
}
