import * as React from "react";
import { Redirect, useLocation } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { usePermissions } from "../../service/auth";

const Fallback = (): JSX.Element => {
  const { hash } = useLocation();
  const { hasPermission } = usePermissions();

  if (hash.startsWith("#/")) {
    const actualLocation = hash.substring(1);
    // TODO(RS): Remove this fallback when there are 0 exceptions left.
    Sentry.captureException(new Error("Hash-style URL accessed"));
    return <Redirect to={actualLocation} />;
  }

  if (!hasPermission("readonly")) {
    // in case user does not have basic readonly permission redirect to profile screen
    return <Redirect to="/profile" />;
  }

  return <Redirect to="/accounts" />;
};

export { Fallback };
