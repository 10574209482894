import { useServiceResource, useServiceResourceList } from "../service/api";
import { objectsById, SalesforceObject, SalesforceObjectResult, SalesforceQueryResult } from "./client";

export interface SalesforceAccount extends SalesforceObject {
  name: string;
  accountNumber: string;
  vertical: string;
  enterpriseAccount: boolean;
}

export function useSalesforceAccounts(page: number): SalesforceQueryResult<SalesforceAccount> {
  const { data, isLoading, isError } = useServiceResourceList<SalesforceAccount>(`/v1/salesforce/accounts/`, page, {});

  return {
    result: data ?? null,
    resultById: data ? objectsById(data) : null,
    isLoading: isLoading,
    isError: isError,
  };
}

export function useSalesforceAccountsSearch(search: string): SalesforceQueryResult<SalesforceAccount> {
  const { data, isLoading, isError } = useServiceResourceList<SalesforceAccount>(`/v1/salesforce/accounts/`, 1, {
    search: search,
  });

  return {
    result: data ?? null,
    resultById: data ? objectsById(data) : null,
    isLoading: isLoading,
    isError: isError,
  };
}

export function useSalesforceAccount(accountId: string): SalesforceObjectResult<SalesforceAccount> {
  const { data, isLoading, isError } = useServiceResource<SalesforceAccount>(`/v1/salesforce/accounts/${accountId}/`);

  return {
    result: data ?? null,
    isLoading: isLoading,
    isError: isError,
  };
}
