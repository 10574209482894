import * as React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignIn from "./public/SignIn";

import { DashboardLayout } from "./dashboard/layout";
import { List as AccountsList } from "./dashboard/screens/accounts/List";
import { Show as AccountShow } from "./dashboard/screens/accounts/Show";
import { Show as SubscriptionShow } from "./dashboard/screens/subscriptions/Show";
import { Create as SubscriptionCreate } from "./dashboard/screens/subscriptions/Create";
import { Show as ContractShow } from "./dashboard/screens/contracts/Show";
import { Preview as ContractPreview } from "./dashboard/screens/contracts/Preview";
import { Create as ContractCreate } from "./dashboard/screens/contracts/Create";
import { OpportunityRedirect as ContractOpportunityRedirect } from "./dashboard/screens/contracts/OpportunityRedirect";
import { Show as UseCaseShow } from "./dashboard/screens/use-cases/Show";
import { Create as UsageGroupCreate } from "./dashboard/screens/usage-group/Create";
import { Show as UsageGroupShow } from "./dashboard/screens/usage-group/Show";
import { Export } from "./dashboard/screens/Export";
import { Fallback } from "./dashboard/screens/Fallback";
import { ProfileScreen } from "./dashboard/screens/profile/ProfileScreen";
import { config } from "./config";
import { DebugScreen } from "./dashboard/screens/DebugScreen";
import { AuthenticatedRoute } from "./authenticatedRoute";
import { ValueEdit } from "./dashboard/screens/contracts/ValueEdit";
import { Renewals } from "./dashboard/screens/Renewals";

const InnerApp = () => {
  return (
    <Router>
      <Switch>
        <Route path="/sign-in" render={() => <SignIn />} />

        <DashboardLayout>
          <Switch>
            <AuthenticatedRoute path="/export" exact render={() => <Export />} requiredPermission="financialExport" />
            <AuthenticatedRoute path="/renewals" exact render={() => <Renewals />} />
            <AuthenticatedRoute path="/profile" exact render={() => <ProfileScreen />} />

            <AuthenticatedRoute path="/accounts/:accountId" exact render={() => <AccountShow />} />
            <AuthenticatedRoute path="/accounts" exact render={() => <AccountsList />} />

            <AuthenticatedRoute
              path="/accounts/:accountId/usage-groups/new"
              exact
              render={() => <UsageGroupCreate />}
            />

            <AuthenticatedRoute
              path="/accounts/:accountId/usage-groups/:usageGroupId(\d+)"
              exact
              render={() => <UsageGroupShow />}
            />

            <AuthenticatedRoute
              path="/accounts/:accountId/subscriptions/new"
              exact
              render={() => <SubscriptionCreate />}
            />
            <AuthenticatedRoute
              path="/accounts/:accountId/subscriptions/:subscriptionId(\d+)"
              exact
              render={() => <SubscriptionShow />}
            />

            <AuthenticatedRoute path="/accounts/:accountId/contracts/new" exact render={() => <ContractCreate />} />
            <AuthenticatedRoute
              path="/accounts/:accountId/contracts/sfdcLanding/:opportunityId"
              exact
              render={() => <ContractOpportunityRedirect />}
            />
            <AuthenticatedRoute
              path="/accounts/:accountId/contracts/preview"
              exact
              render={() => <ContractPreview />}
            />
            <AuthenticatedRoute
              path="/accounts/:accountId/contracts/:contractId(\d+)"
              exact
              render={() => <ContractShow />}
            />
            <AuthenticatedRoute
              path="/accounts/:accountId/contracts/:contractId(\d+)/annotations"
              exact
              render={() => <UseCaseShow />}
            />
            <AuthenticatedRoute
              path="/accounts/:accountId/contracts/:contractId(\d+)/values"
              exact
              render={() => <ValueEdit />}
            />

            {config.sentryEnvironment === null && (
              <AuthenticatedRoute path="/debug" exact render={() => <DebugScreen />} />
            )}

            <Route path="/" exact render={() => <Fallback />} />
            <AuthenticatedRoute path="/test/" exact render={() => <i>ok</i>} />
            <Route render={() => <Fallback />} />
          </Switch>
        </DashboardLayout>
      </Switch>
    </Router>
  );
};

export { InnerApp };
