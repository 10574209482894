import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import * as React from "react";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";

import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { usePreferences } from "../../../service/preferences";
import { useDictionary } from "../../../service/dictionary";
import { config } from "../../../config";

import { DATE_FORMATS, DateFormat, formatDate } from "../../../utils/date";
import { formatMoney, MONEY_FORMATS } from "../../../utils/money";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { PERMISSIONS, Permission, usePermissions } from "../../../service/auth";
import { useSelector } from "react-redux";
import { selectUserSessionExpiryDate } from "../../../store/authSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      paddingBottom: "1rem",
    },
  })
);

const permissionDescriptions: Record<Permission, string> = {
  basicEditing: "Edit data",
  financialExport: "Run financial export",
  financialOverride: "Add financial overrides",
  readonly: "View data",
  useCaseAnnotation: "Annotate use cases",
};

const PermissionsCard = () => {
  const { hasPermission } = usePermissions();

  // Sort first by availability of permissions, then alphabetically by description.
  const sortedPermissions = sortBy(PERMISSIONS, [
    (p: Permission) => (hasPermission(p) ? 0 : 1),
    (p: Permission) => permissionDescriptions[p],
  ]);

  return (
    <Card>
      <CardHeader title="Permissions" />
      <CardContent>
        {sortedPermissions.every((p) => !hasPermission(p)) && (
          <Alert severity="warning">
            You don't have permissions. In order to get access to the system please ask at Slack channel{" "}
            <a href="https://scandit.slack.com/archives/C87EBMH2T" target="_blank" rel="noopener noreferrer">
              #eng-infra-support
            </a>
          </Alert>
        )}
        <List>
          {sortedPermissions.map((p) => {
            return (
              <ListItem disabled={!hasPermission(p)} key={p}>
                <ListItemIcon>{hasPermission(p) ? <CheckIcon /> : <ClearIcon />}</ListItemIcon>
                <ListItemText primary={permissionDescriptions[p]} />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

const PreferencesCard = () => {
  const {
    dateFormat,
    setDateFormat,
    moneyFormat,
    setMoneyFormat,
    showContractGraph,
    setShowContractGraph,
    fullWidthUI,
    setFullWidthUI,
  } = usePreferences();

  return (
    <Card>
      <CardHeader title="Preferences" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="profile-date-format-label">Date format</InputLabel>
              <Select
                labelId="profile-date-format-label"
                label="Date format"
                onChange={(e) => setDateFormat(e.target.value as DateFormat)}
                value={dateFormat}
              >
                {DATE_FORMATS.map((fmt) => (
                  <MenuItem key={fmt} value={fmt}>
                    {formatDate(new Date(), fmt)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="profile-money-format-label">Number format</InputLabel>
              <Select
                labelId="profile-money-format-label"
                label="Number format"
                onChange={(e) => setMoneyFormat(MONEY_FORMATS[parseInt(e.target.value as string)])}
                value={MONEY_FORMATS.findIndex((f) => isEqual(f, moneyFormat))}
              >
                {MONEY_FORMATS.map((fmt, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {formatMoney(fmt, 12345678.1234)} &nbsp;/&nbsp; {formatMoney(fmt, 12345678.1234, "EUR")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormControlLabel
                value="profile-show-graph-label"
                label="Show Contract Graph"
                control={
                  <Checkbox
                    id="profile-show-graph-label"
                    checked={showContractGraph}
                    onChange={(e) => setShowContractGraph(e.target.checked)}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormControlLabel
                label="Full width UI (good for wide screens)"
                control={<Checkbox checked={fullWidthUI} onChange={(e) => setFullWidthUI(e.target.checked)} />}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const DeploymentInfoCard = () => {
  const { dictionary } = useDictionary();
  const { formatDate } = usePreferences();

  const relevantFields = [
    dictionary.DEPLOYMENT.VERSION,
    dictionary.DEPLOYMENT.TIMESTAMP,
    config.deployment.version,
    config.deployment.timestamp,
  ];

  // Fallback for local development and if CI breaks:
  // If one field isn't set to something truthy, hide the whole component.
  if (!relevantFields.every(Boolean)) {
    return <></>;
  }

  const formatTimestamp = (timestamp: string) => {
    const date = parseISO(timestamp);
    return `${formatDate(date)} ${format(date, "k:mm O")}`;
  };

  return (
    <Card>
      <CardHeader title="Deployment" />
      <CardContent>
        <>
          Backend version {dictionary.DEPLOYMENT.VERSION} built {formatTimestamp(dictionary.DEPLOYMENT.TIMESTAMP)}
          <br />
          Frontend version {config.deployment.version} built {formatTimestamp(config.deployment.timestamp)}
        </>
      </CardContent>
    </Card>
  );
};

const SessionInfoCard = () => {
  const expiryDate = useSelector(selectUserSessionExpiryDate);

  if (!expiryDate) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader title="Session" />
      <CardContent>
        <div>Session expires: {formatDistance(expiryDate, new Date(), { addSuffix: true })}</div>
      </CardContent>
    </Card>
  );
};

const ProfileScreen = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.header} variant="h4">
        User Profile
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <PreferencesCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PermissionsCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SessionInfoCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DeploymentInfoCard />
        </Grid>
      </Grid>
    </>
  );
};

export { ProfileScreen };
