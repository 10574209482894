import * as React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Contract, ContractDocument, useContractModifier, useContracts } from "../../../service/contracts";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MuiLink from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";

import { useEffect, useState } from "react";
import { ObjectsTable } from "../../../components/ObjectsTable";
import { config } from "../../../config";
import { Subscription, useSubscriptionModifier, useSubscriptions } from "../../../service/subscriptions";
import { Dictionary, useDictionary } from "../../../service/dictionary";
import { ConfirmedButton } from "../../../components/ConfirmedButton";
import { useNotifications } from "../../../notifications";
import { usePreferences } from "../../../service/preferences";
import { usePermissions } from "../../../service/auth";
import { Header } from "../../Header";
import { ContentBox } from "../../../components/ContentBox";
import { UsageGroup, useUsageGroups, useUsageGroupsModifier } from "../../../service/usagegroups";
import { SalesforceLink } from "../../../components/SalesforceLink";
import { AccountContractsGraph } from "../../../components/AccountContractsGraph";
import ArticleIcon from "@mui/icons-material/Article";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import type { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { LineItem, useLineItems } from "../../../service/lineitems";
import { useAccountActions } from "../../../service/accounts";
import { makeCATLink } from "../subscriptions/Show";
import WarningIcon from "@mui/icons-material/Warning";

function ContractDocumentsTooltipIcon(props: { contract: Contract; dictionary: Dictionary }): JSX.Element {
  // Tooltip + File icon to quickly understand a contract's documents
  // When documents are present: Full icon, and tooltip lists the type of documents in the contract
  // When no documents are present: Outlined icon with tooltip displaying "None"
  return (
    <Tooltip
      title={
        props.contract.documents.length > 0 ? (
          <ul>
            {props.contract.documents.map((d: ContractDocument) => (
              <li key={d.url}>{props.dictionary.DOCUMENT_TYPES[d.type]}</li>
            ))}
          </ul>
        ) : (
          <i>None</i>
        )
      }
    >
      {props.contract.documents.length > 0 ? <ArticleIcon /> : <ArticleOutlinedIcon />}
    </Tooltip>
  );
}

function CommentTooltipIcon(props: { contract: Contract; dictionary: Dictionary }): JSX.Element {
  // Tooltip + File icon to quickly understand a contract's documents
  // When documents are present: Full icon, and tooltip lists the type of documents in the contract
  // When no documents are present: Outlined icon with tooltip displaying "None"
  return (
    <Tooltip title={props.contract.comment.length > 0 ? props.contract.comment : <i>None</i>}>
      {props.contract.comment.length > 0 ? <CommentIcon /> : <ModeCommentOutlinedIcon />}
    </Tooltip>
  );
}

function WarningsTooltipIcon(props: { contract: Contract; lineItems: LineItem[] }) {
  /* For Warnings about the state of some Contract in the Account page */

  // check if contracts has lineitems not associated to subscriptions
  const hasOrphanLineItems = props.lineItems.find(
    (li) => li.contractId === props.contract.id && li.subscriptionId === null
  );

  if (hasOrphanLineItems)
    return (
      <Tooltip title={"Some LineItems in this active contract are not in any Subscription"}>
        <WarningIcon />
      </Tooltip>
    );

  return <></>;
}

// Currently supported currency-value computations to display in account overview
const CURRENCY_VALUES_STRING_MODES = ["TCV", "ACV"] as const; // TODO(NOSTORY) "Rolled-up annual";
type CurrencyValueStringMode = typeof CURRENCY_VALUES_STRING_MODES[number];
// Currently supported columns to order by
const ORDER_BY_COLUMN_IDS: string[] = ["start_date", "end_date", "close_date"];

function ComputeContractCurrencyValueString(
  contract: Contract,
  accountLineItems: LineItem[],
  mode: CurrencyValueStringMode
): string {
  const currency = accountLineItems.find((li) => li.contractId === contract.id)?.currency ?? "";
  switch (mode) {
    case "TCV":
      return `${currency} ${contract.tcv ?? 0}`;
    case "ACV":
      return `${currency} ${contract.acv ?? 0}`;
    default:
      return "-";
  }
}

function FieldValueFromOrderByColumn(contract: Contract, orderByColumnId: string): string | null {
  switch (orderByColumnId) {
    case "start_date":
      return contract.startDate;
    case "end_date":
      return contract.endDate;
    case "close_date":
      return contract.closeDate;
    default:
      return null;
  }
}

function ContractsList(props: { accountId: string }): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const { data, count, isLoading, isError, mutate } = useContracts(props.accountId, page);
  const { delete: deleteContract } = useContractModifier(props.accountId);
  const { dictionary } = useDictionary();
  const { addNotification } = useNotifications();
  const { formatDate } = usePreferences();
  const { hasPermission } = usePermissions();

  // Fetch line items for this account to compute contract values
  const { data: lineItems, isLoading: isLineItemsLoading } = useLineItems(props.accountId, 0);
  const [contractValues, setContractValues] = useState<string[]>([]);
  const [currencyValueStringMode, setCurrencyValueStringMode] = useState<CurrencyValueStringMode>("TCV");
  const handleCurrencyValueStringModeChange = (event: SelectChangeEvent<CurrencyValueStringMode>) => {
    setCurrencyValueStringMode(event.target.value as CurrencyValueStringMode);
  };

  // Track order, orderBy based on user clicking on column headers, sort data accordingly
  const [order, setOrder] = React.useState<"asc" | "desc" | undefined>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("start_date");
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function sortData(data: Contract[], order: "asc" | "desc" | undefined, orderByColumnId: string) {
    let dataSorted = [...data];
    if (order !== undefined && ORDER_BY_COLUMN_IDS.includes(orderByColumnId)) {
      // asc order && date(a) < date(b) -> -1
      // asc order && date(a) > date(b) -> 1
      // desc order && date(a) < date(b) -> 1
      // desc order && date(a) > date(b) -> -1
      let orderSign: number = order === "asc" ? 1 : -1;
      const date = (c: Contract) => FieldValueFromOrderByColumn(c, orderByColumnId);
      dataSorted.sort((a, b) =>
        date(a) === null ? -1 : date(b) === null ? 1 : date(a)! < date(b)! ? -orderSign : orderSign
      );
    }
    return dataSorted;
  }

  useEffect(() => {
    if (data === undefined) return;
    if (!isLineItemsLoading && lineItems !== undefined && lineItems.length > 0) {
      setContractValues(
        sortData(data, order, orderBy).map((c: Contract) =>
          ComputeContractCurrencyValueString(c, lineItems, currencyValueStringMode)
        )
      );
    } else {
      setContractValues(data.map((c: Contract) => "-"));
    }
  }, [data, isLineItemsLoading, currencyValueStringMode, order, orderBy]);

  async function handleDelete(contract: Contract) {
    try {
      await deleteContract(contract);
    } catch (e) {
      addNotification({
        level: "error",
        text: "Contract could not be deleted due to error, please try again",
      });
      console.error(e);
      return;
    }
    await mutate();
    addNotification({
      level: "success",
      text: "Contract successfully deleted",
    });
  }

  const columns = [
    { id: "docs", label: "Docs", minWidth: 40 },
    { id: "comment", label: "Comment", minWidth: 40 },
    { id: "opportunity", label: "Opportunity", minWidth: 170 },
    { id: "name", label: "Name", minWidth: 170 },
    { id: "start_date", label: "Start Date", minWidth: 140 },
    { id: "end_date", label: "End Date", minWidth: 140 },
    { id: "close_date", label: "Close Date", minWidth: 140 },
    { id: "type", label: "Type", minWidth: 170 },
    { id: "value", label: currencyValueStringMode, minWidth: 170 },
    { id: "actions", label: "Actions", minWidth: 140 },
  ];

  const rows = data
    ? sortData(data, order, orderBy).map((contract, i) => ({
        id: contract.id?.toString() ?? "",
        values: [
          <ContractDocumentsTooltipIcon key={i} contract={contract} dictionary={dictionary} />,
          <CommentTooltipIcon contract={contract} dictionary={dictionary} />,
          <SalesforceLink sfdcReference={contract.opportunityId}>{contract.opportunityId || "-"}</SalesforceLink>,
          <MuiLink to={`/accounts/${props.accountId}/contracts/${contract.id}`} component={Link} color="secondary">
            {contract.name}
          </MuiLink>,
          formatDate(contract.startDate),
          formatDate(contract.endDate),
          formatDate(contract.closeDate),
          contract.dealTypes.map((dt) => dictionary.DEAL_TYPES[dt] || dt).join(", "),
          contractValues[i],
          <>
            <ButtonGroup size="small" variant="contained" aria-label="small contained primary button group">
              {hasPermission("basicEditing") && (
                <ConfirmedButton
                  confirmationTitle="Contract deletion"
                  confirmationText={`Are you sure you want to delete contract ${contract.name}?`}
                  onConfirmed={() => handleDelete(contract)}
                >
                  DELETE
                </ConfirmedButton>
              )}
            </ButtonGroup>
            {lineItems && (
              <>
                &nbsp;&nbsp;
                <WarningsTooltipIcon contract={contract} lineItems={lineItems} />
                &nbsp;&nbsp;
              </>
            )}
          </>,
        ],
      }))
    : [];

  return (
    <>
      {data !== undefined ? <AccountContractsGraph contracts={data} /> : <></>}

      <Stack direction="row" justifyContent="end">
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-standard-label">Show value</InputLabel>
          <Select value={currencyValueStringMode} label="Show value" onChange={handleCurrencyValueStringModeChange}>
            {CURRENCY_VALUES_STRING_MODES.map((mode: CurrencyValueStringMode) => (
              <MenuItem value={mode}>{mode}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <ObjectsTable
        isLoading={isLoading}
        isError={isError}
        columns={columns}
        rows={rows}
        totalRows={count || 0}
        pageLimit={config.servicePaginationLimit}
        page={page}
        onChange={setPage}
        allowOrderByCols={ORDER_BY_COLUMN_IDS}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
      />
      <Typography variant="caption" display="block" align="right" gutterBottom sx={{ fontStyle: "italic" }}>
        <ArticleIcon fontSize="small" /> = has documents,
        <ArticleOutlinedIcon fontSize="small" /> = no documents
      </Typography>
    </>
  );
}

function SubscriptionsList(props: { accountId: string }): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const { data, count, isLoading, isError, mutate } = useSubscriptions(props.accountId, page);
  const { delete: deleteSubscription } = useSubscriptionModifier(props.accountId);
  const { dictionary } = useDictionary();
  const { addNotification } = useNotifications();
  const { formatDate } = usePreferences();
  const { hasPermission } = usePermissions();

  async function handleDelete(subscription: Subscription) {
    try {
      await deleteSubscription(subscription);
    } catch (e) {
      addNotification({
        level: "error",
        text: "Subscription could not be deleted due to error, please try again",
      });
      console.error(e);
      return;
    }
    await mutate();
    addNotification({
      level: "success",
      text: "Subscription successfully deleted",
    });
  }

  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "integration_path", label: "Integration Path", minWidth: 170 },
    { id: "user_type", label: "User Type", minWidth: 170 },
    { id: "start_date", label: "Start Date", minWidth: 170 },
    { id: "end_date", label: "End Date", minWidth: 170 },
    { id: "cat_link", label: "CAT", minWidth: 50 },
    { id: "actions", label: "Actions", minWidth: 170 },
  ];
  const rows = data
    ? data.map((subscription) => ({
        id: subscription.id?.toString() ?? "",
        values: [
          <MuiLink
            to={`/accounts/${props.accountId}/subscriptions/${subscription.id}`}
            component={Link}
            color="secondary"
          >
            {subscription.name}
          </MuiLink>,
          dictionary.INTEGRATION_PATHS[subscription.integrationPath] || subscription.integrationPath,
          dictionary.USER_TYPES[subscription.userType] || subscription.userType,
          formatDate(subscription.startDate),
          formatDate(subscription.endDate),
          <Tooltip title="Go to CAT">
            <a href={makeCATLink(props.accountId, subscription.id)} target="_blank" rel="noopener noreferrer">
              <OpenInNew color="secondary" />
            </a>
          </Tooltip>,
          <ButtonGroup size="small" variant="contained" aria-label="small contained primary button group">
            {hasPermission("basicEditing") && (
              <ConfirmedButton
                confirmationTitle="Subscription deletion"
                confirmationText={`Are you sure you want to delete subscription ${subscription.name}?`}
                onConfirmed={() => handleDelete(subscription)}
              >
                DELETE
              </ConfirmedButton>
            )}
          </ButtonGroup>,
        ],
      }))
    : [];

  return (
    <ObjectsTable
      isLoading={isLoading}
      isError={isError}
      columns={columns}
      rows={rows}
      totalRows={count || 0}
      pageLimit={config.servicePaginationLimit}
      page={page}
      onChange={setPage}
    />
  );
}

export function UsageGroupList(props: { accountId: string }): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const { addNotification } = useNotifications();
  const { data, count, isLoading, isError, mutate } = useUsageGroups(props.accountId, page);
  const { delete: deleteUsageGroup } = useUsageGroupsModifier(props.accountId);
  const { hasPermission } = usePermissions();
  const { dictionary } = useDictionary();

  const handleDelete = async (usageGroup: UsageGroup) => {
    try {
      await deleteUsageGroup(usageGroup);
      await mutate();
      addNotification({
        level: "success",
        text: `Usage Group ${usageGroup.name} deleted`,
      });
    } catch (e) {
      addNotification({
        level: "error",
        text: `Usage Group ${usageGroup.name} could not be deleted, please try again.`,
      });
    }
  };

  const colums = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "metric", label: "Usage Metric", minWidth: 170 },
    { id: "action", label: "Actions", minWidth: 170 },
  ];

  const rows = data
    ? data.map((usageGroup) => ({
        id: usageGroup.id?.toString() ?? "",
        values: [
          <MuiLink to={`/accounts/${props.accountId}/usage-groups/${usageGroup.id}`} component={Link} color="secondary">
            {usageGroup.name}
          </MuiLink>,
          dictionary.USAGE_METRICS[usageGroup.usageMetric] || usageGroup.usageMetric,
          <ButtonGroup size="small" variant="contained" aria-label="small contained primary button group">
            {hasPermission("basicEditing") && (
              <ConfirmedButton
                confirmationTitle="Usage Group deletion"
                confirmationText={`Are you sure you want to delete usage group ${usageGroup.name}?`}
                onConfirmed={() => handleDelete(usageGroup)}
              >
                DELETE
              </ConfirmedButton>
            )}
          </ButtonGroup>,
        ],
      }))
    : [];

  return (
    <ObjectsTable
      isLoading={isLoading}
      isError={isError}
      columns={colums}
      rows={rows}
      totalRows={count || rows.length}
      pageLimit={config.servicePaginationLimit}
      page={page}
      onChange={setPage}
    />
  );
}

function ActionsMenu(props: { accountId: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [moveAccountOpen, setMoveAccountOpen] = useState(false);
  const [accountIdDestination, setAccountIdDestination] = useState<string>("");
  const accountActions = useAccountActions();

  function onClickMoveDataToOtherAccount() {
    setAnchorEl(null);
    setMoveAccountOpen(true);
  }

  function onSubmitMergeForm() {
    accountActions.merge(props.accountId, accountIdDestination);
    setMoveAccountOpen(false);
    window.location.reload();
  }

  return (
    <>
      <Dialog open={moveAccountOpen} onClose={() => setMoveAccountOpen(false)}>
        <DialogTitle>Move Data To Other Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Warning: This action will move all contracts, subscriptions, and usage groups from this account to the
            specified account.
          </DialogContentText>
          <TextField
            onChange={(v) => setAccountIdDestination(v.target.value)}
            autoFocus
            margin="dense"
            id="accountId"
            label="Destination Account Id"
            fullWidth
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveAccountOpen(false)}>Cancel</Button>
          <Button onClick={onSubmitMergeForm}>Move all</Button>
        </DialogActions>
      </Dialog>

      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreHorizIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={anchorEl !== null} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onClickMoveDataToOtherAccount} disableRipple>
          Move data to other account
        </MenuItem>
      </Menu>
    </>
  );
}

export function Show(): JSX.Element {
  const { hasPermission } = usePermissions();
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <Header accountId={accountId} />
        </Grid>
        <Grid item xs={1}>
          <ActionsMenu accountId={accountId} />
        </Grid>
      </Grid>

      <ContentBox header="Contracts">
        <ContractsList accountId={accountId} />
        {hasPermission("basicEditing") && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => history.push(`/accounts/${accountId}/contracts/new`)}
          >
            Create contract
          </Button>
        )}
      </ContentBox>

      <ContentBox header="Subscriptions">
        <SubscriptionsList accountId={accountId} />
        {hasPermission("basicEditing") && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => history.push(`/accounts/${accountId}/subscriptions/new`)}
          >
            Create subscription
          </Button>
        )}
      </ContentBox>

      <ContentBox header="Usage Groups">
        <UsageGroupList accountId={accountId} />
        {hasPermission("basicEditing") && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => history.push(`/accounts/${accountId}/usage-groups/new`)}
          >
            Create Usage Group
          </Button>
        )}
      </ContentBox>
    </>
  );
}
