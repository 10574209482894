import { useServiceResource, useServiceResourceList } from "../service/api";
import { objectsById, SalesforceObject, SalesforceObjectResult, SalesforceQueryResult } from "./client";

export interface SalesforceOpportunity extends SalesforceObject {
  name: string;
  closeDate: string;
  type:
    | "Existing Business"
    | "New Business"
    | "New Business (Growth)"
    | "New Business (Upsell)"
    | "Pilot"
    | "Restructure";
  stagedDeal: boolean;
  billingAddress: string;
  linkToOrderForm: string;
  linkToConfirmationOfTierGrowth: string;
  linkToCustomerSpecificTC: string;
  cancellationDate: string | null;
  currencyIsoCode: string;
  autoRenewal: "Yes" | "No" | null;
  contractingEntity: string;
  syncedQuote: {
    noticePeriod: number | "None" | undefined;
  };
  stageName: string;
  amount: number | null;
}

export function useSalesforceOpportunities(accountId: string): SalesforceQueryResult<SalesforceOpportunity> {
  const { data, isLoading, isError } = useServiceResourceList<SalesforceOpportunity>(
    `/v1/salesforce/opportunities/`,
    1,
    { account_id: accountId }
  );

  return {
    result: data ?? null,
    resultById: data ? objectsById(data) : null,
    isLoading: isLoading,
    isError: isError,
  };
}

export function useSalesforceOpportunity(opportunityId: string): SalesforceObjectResult<SalesforceOpportunity> {
  const { data, isLoading, isError } = useServiceResource<SalesforceOpportunity>(
    `/v1/salesforce/opportunities/${opportunityId}/`
  );

  return {
    result: data ?? null,
    isLoading: isLoading,
    isError: isError,
  };
}
