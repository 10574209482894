import * as React from "react";
import { Subscription } from "../service/subscriptions";
import { Contract } from "../service/contracts";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import createStyles from "@mui/styles/createStyles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import type { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useSalesforceAccount } from "../salesforce/accounts";
import { Link } from "react-router-dom";
import { UsageGroup } from "../service/usagegroups";
import { SalesforceLink } from "../components/SalesforceLink";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbContainer: {
      padding: "5px",
      marginBottom: "15px",
    },
    breadcrumbItem: {
      fontSize: "1.1em",
      fontWeight: "bold",
    },
  })
);

interface HeaderProps {
  accountId: string;
  loading?: boolean;
  subscription?: Subscription;
  contract?: Contract;
  usageGroup?: UsageGroup;
  page?: string;
}

export function Header(props: HeaderProps): JSX.Element {
  const { isLoading: sfdcAccountLoading, result: sfdcAccount } = useSalesforceAccount(props.accountId);
  const loading = props.loading || sfdcAccountLoading;

  const classes = useStyles();

  type SimpleItem = { label: string; link?: string };
  type Item = SimpleItem | JSX.Element;

  const defaultComponent = (element: SimpleItem) => {
    return element.link ? (
      <MuiLink
        component={Link}
        to={element.link!}
        key={element.label + element.link}
        className={classes.breadcrumbItem}
      >
        {element.label}
      </MuiLink>
    ) : (
      <Typography key={element.label as any} className={classes.breadcrumbItem}>
        {element.label}
      </Typography>
    );
  };

  let componentsToRender: JSX.Element[] = [];

  if (loading) {
    componentsToRender = [<Skeleton key={"skeleton0"} variant="text" animation="wave" width={400} />];
  } else {
    let items: Item[] = [];
    if (sfdcAccount) {
      items.push(
        <SalesforceLink sfdcReference={props.accountId || null}>
          {defaultComponent({ label: sfdcAccount.name, link: `/accounts/${sfdcAccount.id}` })}
        </SalesforceLink>
      );
    }
    if (props.subscription && sfdcAccount) {
      items.push({
        label: props.subscription.name,
        link: `/accounts/${sfdcAccount.id}/subscriptions/${props.subscription.id}`,
      });
    }
    if (props.contract && sfdcAccount) {
      items.push({ label: props.contract.name, link: `/accounts/${sfdcAccount.id}/contracts/${props.contract.id}` });
    }
    if (props.usageGroup && sfdcAccount) {
      items.push({
        label: props.usageGroup.name,
        link: `/accounts/${sfdcAccount.id}/usage-groups/${props.usageGroup.id}`,
      });
    }
    if (props.page) {
      items.push({ label: props.page });
    }

    componentsToRender = items.map((item, i) => {
      const maybeSimpleItem = item as SimpleItem;
      const component = maybeSimpleItem.label !== undefined ? defaultComponent(maybeSimpleItem) : (item as JSX.Element);
      return <div key={`breadcrumb${i}`}>{component}</div>;
    });
  }

  return (
    <Paper className={classes.breadcrumbContainer}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {componentsToRender}
      </Breadcrumbs>
    </Paper>
  );
}
