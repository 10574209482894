import { SalesforceOpportunityLineItem } from "../salesforce/lineitems";
import { monthsDuration } from "../utils/date";
import {
  getRequestUrl,
  ResourceListResult,
  ResourceModifier,
  ResourceResult,
  useFetcher,
  useServiceResource,
  useServiceResourceList,
  useServiceResourceModifier,
} from "./api";

export interface LineItem {
  id: number | null;
  contractId: number | null;
  subscriptionId: number | null;
  productId: string | null;
  priceBookId: string | null;
  currency: string;
  salesPrice: number;
  startDate: string;
  endDate: string | null;
  licenseType: string;
  usageMetric: string;
  productType: string;
  integrationPath: string;
  userType: string;
  useCase: string | null;
  useCasePack: string | null;
  useCaseSuite: string | null;
  platforms: string[];
  communicationSalesPackage: string[];
  isProductionLicense: boolean;
  deviceTypes: string;
  supportLevel: string;
  analytics: "with_analytics" | "with_analytics_no_scan_content" | "no_analytics";
  anonymizeIp: boolean;
  maxDevicesPerYear: number | null;
  maxDevicesPerMonth: number | null;
  maxDevicesPerStorePerMonth: number | null;
  maxScansPerYear: number | null;
  maxScansPerDevicePerYear: number | null;
  maxScansPerStorePerMonth: number | null;
  maxActivations: number | null;
  maxStores: number | null;
  storeType: string | null;
  storeEngagementTier: string | null;
  avgMonthlyDevicesPerStore: number | null;
  avgScansPerStore: number | null;
  geography: string | null;
  addons: string[];
  symbologies: string[];
  edition:
    | "north_america_light"
    | "north_america_premium"
    | "north_america_travel_premium"
    | "international_travel_and_europe_light"
    | "europe_premium"
    | "global_premium"
    | "mobile_price_and_promotion_label_execution"
    | "autonomous_pallet_localization"
    | "autonomous_product_localization"
    | "autonomous_price_and_promotion_label_execution"
    | "core-mrz"
    | "core-pdf417"
    | "core"
    | "standard"
    | "advanced"
    | "id-validate"
    | "other"
    | null;
  pilot: boolean;
  staged: boolean;
  purposeType: "product" | "maintenance_plan";
  terminated: boolean | null;
  annualFee: number;
  parents: number[];
  children: number[];
}

const LICENSE_TYPE_MAPPING: { [key: string]: string } = {
  Recurring: "recurring",
  Perpetual: "perpetual",
};
const PRODUCT_TYPE_MAPPING: { [key: string]: string } = {
  "Barcode scanning": "barcode_scanner",
  "ID scanning": "id_scanner",
  "Text recognition": "text_recognition",
};
const INTEGRATION_PATH_MAPPING: { [key in SalesforceOpportunityLineItem["integrationPath"]]: string } = {
  "Native SDK": "native_sdk",
  "Web SDK": "web_sdk",
  "Enterprise Browser": "enterprise_browser",
  "Keyboard Wedge": "keyboard_wedge",
  "ID Bolt": "id_bolt",
  "Scandit Express": "express",
  Cloud: "cloud",
};
const PLATFORM_MAPPING: { [key: string]: string } = {
  Android: "android",
  iOS: "ios",
  Windows: "windows",
  Linux: "linux",
  Web: "webassembly",
};
const DEVICE_TYPE_MAPPING: { [key: string]: string } = {
  "Smartphones & Tablets": "smartphones_and_tablets",
  "Stationary Cameras": "stationary_cameras",
  Drones: "drones",
  Robots: "robots",
  Dedicated: "dedicated",
  Other: "other",
};
const SUPPORT_LEVEL_MAPPING: { [key: string]: string } = {
  Basic: "basic",
  Medium: "medium",
  Premium: "premium",
  Custom: "custom",
};
const ADDONS_MAPPING: { [key: string]: string } = {
  "Case Mode": "casemode",
  Matrixscan: "matrixscan",
  "MatrixScan Light": "matrixscan_light",
  "MatrixScan AR Light": "matrixscan_ar_light",
  "MatrixScan AR + Count": "matrixscan_ar",
  "Barcode Clustering": "barcode_clustering",
  "HIBCC Parser": "hibc_parser",
  "GS1 Parser": "gs1_parser",
  "Swiss QR parser": "swiss_qr_parser",
  "VIN parser": "vin_parser",
};

const SYMBOLOGIES_MAPPING: { [key: string]: string } = {
  Aztec: "aztec",
  Codabar: "codabar",
  "Code 11": "code11",
  "Code 25": "code25",
  "Code 32": "code32",
  "Code 39": "code39",
  "Code 93": "code93",
  "Code 128": "code128",
  Datamatrix: "data-matrix",
  "IATA 2 of 5": "iata2of5",
  "GS1 Databar": "databar",
  "GS1 Databar Expanded": "databar-expanded",
  "GS1 Databar Limited": "databar-limited",
  "UPC-A": "upca",
  "UPC-E": "upce",
  "Posi LAPA 4 State Code": "lapa4sc",
  "Interleaved 2 of 5": "itf",
  Maxicode: "maxicode",
  MicroPDF417: "micropdf417",
  "MSI Plessey": "msi",
  PDF417: "pdf417",
  "EAN-13": "ean13",
  "EAN-2": "ean2",
  "EAN-5": "ean5",
  "EAN-8": "ean8",
  "EAN two-digit-add-on": "ean2",
  "EAN five-digit-add-on": "ean5",
  Databar: "databar",
  "DataBar Limited": "databar-limited",
  "DataBar Extended": "databar-expanded",
  "MicroQR Code": "microqr",
  "QR Code": "qr",
  DotCode: "dotcode",
  LAPA4SC: "lapa4sc",
  KIX: "kix",
  RM4SCC: "rm4scc",
  ArUco: "aruco",
  "UPU 4-State": "upu-4state",
  "Australian Post 4-State": "australian-post-4state",
  "French Post": "french-post",
};

const USE_TYPE_MAPPING: { [key in SalesforceOpportunityLineItem["allowedUserTypes"]]: string } = {
  "Worker (B2E)": "b2e_worker",
  "Autonomous System (B2E)": "b2e_autonomous",
  "Business Consumer (B2B)": "b2b_business_consumer",
  "Individual Consumer (B2C)": "b2c_individual_consumer",
  "Self-Scanning [of Individual Consumer (B2C)]": "b2c_self_scanning_individual_consumer",
};
const STORE_TYPE_MAPPING: { [key in SalesforceOpportunityLineItem["storeType"]]: string } = {
  "C-Store & City Market": "c_store_and_city_market",
  "Super Market": "super_market",
  "Large Super Market": "large_super_market",
  Hypermarket: "hypermarket",
};
const STORE_ENGAGEMENT_MAPPING: { [key in SalesforceOpportunityLineItem["engagementTier"]]: string } = {
  Low: "low",
  Middle: "middle",
  High: "high",
  Entry: "entry",
  Starter: "starter",
  Scaler: "scaler",
  Unlimited: "unlimited",
};
const EDITION_MAPPING: { [key in SalesforceOpportunityLineItem["variant"]]: LineItem["edition"] } = {
  "Mobile Price and Promotion Label Execution": "mobile_price_and_promotion_label_execution",
  "Autonomous Pallet Localization": "autonomous_pallet_localization",
  "Autonomous Product Localization": "autonomous_product_localization",
  "Autonomous Price and Promotion Label Execution": "autonomous_price_and_promotion_label_execution",
  "ID Scan Core Edition - MRZ": "core-mrz",
  "ID Scan Core Edition - PDF417": "core-pdf417",
  "ID Scan Standard Edition": "standard",
  "ID Scan Advanced Edition": "advanced",
  "ID Validate": "id-validate",
  "Core Edition": "core",
  "Standard Edition": "standard",
  "Advanced Edition": "advanced",
  Other: "other",
};
const USAGE_METRIC_MAPPING: { [key in SalesforceOpportunityLineItem["usageMetric"]]: string } = {
  "Active devices per year by subscription": "yearly-ad-by-sub",
  Stores: "stores",
  "Active devices per month by subscription": "monthly-ad-by-sub",
  "Scans per year": "scans",
  Activations: "activations",
  "Active devices per year by license key (legacy)": "yearly-ad-by-lk",
  "Active devices per month by license key (legacy)": "monthly-ad-by-lk",
  Custom: "custom",
};
const ANALYTICS_MAPPING: { [key in SalesforceOpportunityLineItem["analyticsSettings"]]: LineItem["analytics"] } = {
  "With analytics": "with_analytics",
  "No analytics": "no_analytics",
  "With analytics, No scan content": "with_analytics_no_scan_content",
};
const ANONYMIZE_IP_MAPPING: { [key in SalesforceOpportunityLineItem["iPAddressSettings"]]: boolean } = {
  Default: false,
  "Anonymized IP": true,
};
const COMMUNICATION_SALES_PACKAGE_MAPPING: { [key: string]: string } = {
  Default: "default",
  "Continuous offline use allowed": "continuous_offline_use_allowed",
  "No SDK-to-server communication": "no_communication",
  "Online activation required": "online_activation_required",
};
export const ASSUMED_PERPETUAL_DURATION = 48; // months

export class SFImportError {
  public message: string;
  constructor(msg: string) {
    this.message = msg;
  }
}

function convertMultiPickList(value: string | null, mapping?: { [key: string]: string }): string[] {
  const values = (value || "").split(";");
  if (mapping) {
    return values
      .map((v) => mapping[v])
      .flat()
      .filter((v) => v != undefined);
  }
  return values;
}

export function isMaintenance(li: LineItem): boolean {
  return li.purposeType == "maintenance_plan";
}

// always ignore None n/a n\a
export function lineItemFromSF(lineItem: SalesforceOpportunityLineItem): LineItem {
  const licenseType = LICENSE_TYPE_MAPPING[lineItem.subscriptionType] || "";
  const productType = PRODUCT_TYPE_MAPPING[lineItem.product2.name] || "";
  const integrationPath = INTEGRATION_PATH_MAPPING[lineItem.integrationPath] || "";
  const platforms = convertMultiPickList(lineItem.platform, PLATFORM_MAPPING);
  const deviceTypes = convertMultiPickList(lineItem.deviceSupport, DEVICE_TYPE_MAPPING)[0] || "";
  const supportLevel = SUPPORT_LEVEL_MAPPING[lineItem.supportPlan] || "";
  const addOns = convertMultiPickList(lineItem.addOns, ADDONS_MAPPING);
  const symbologies = convertMultiPickList(lineItem.symbologies, SYMBOLOGIES_MAPPING);
  const duration = lineItem.endDate ? monthsDuration(lineItem.endDate, lineItem.startDate) : ASSUMED_PERPETUAL_DURATION;
  const userType = USE_TYPE_MAPPING[lineItem.allowedUserTypes] || "";
  const usageMetric = USAGE_METRIC_MAPPING[lineItem.usageMetric] || "";
  const communicationSalesPackage = convertMultiPickList(
    lineItem.serverCommunicationSettings,
    COMMUNICATION_SALES_PACKAGE_MAPPING
  ) || ["default"];
  return {
    id: null,
    contractId: null,
    subscriptionId: null,
    productId: lineItem.product2Id,
    priceBookId: lineItem.pricebookEntryId,
    currency: lineItem.currencyIsoCode,
    // salesPrice:
    // In our SFDC instance, we do not store the total price, but always the annual price.
    // Hence we need to compute “price * #months/12” to get the actual amount the customer pays for over the lifetime of the contract
    // For perpetual licenses, we need to assume 48 months, hence the calculation needs to be “price * 48/12”
    salesPrice: (lineItem.grandTotalFee * duration) / 12,
    startDate: lineItem.startDate,
    endDate: lineItem.endDate,
    licenseType: licenseType,
    usageMetric: usageMetric,
    productType: productType,
    integrationPath: integrationPath,
    userType: userType,
    useCase: lineItem.useCases,
    useCasePack: lineItem.useCasePack,
    useCaseSuite: lineItem.useCaseSuite,
    platforms: platforms,
    communicationSalesPackage: communicationSalesPackage,
    isProductionLicense: lineItem.typeOfAgreement == "Production",
    deviceTypes: deviceTypes,
    supportLevel: supportLevel,
    analytics: ANALYTICS_MAPPING[lineItem.analyticsSettings] || "with_analytics",
    anonymizeIp: ANONYMIZE_IP_MAPPING[lineItem.iPAddressSettings] || false,
    maxDevicesPerYear: lineItem.activeDevicesPerPlatform,
    maxDevicesPerMonth: lineItem.maxActiveDevicesPerMonth,
    maxDevicesPerStorePerMonth: null,
    maxScansPerYear: lineItem.maxScansPerYear,
    maxScansPerDevicePerYear: lineItem.maxscansperactivedeviceperyear,
    maxScansPerStorePerMonth: null,
    maxActivations: lineItem.maxActivations,
    avgMonthlyDevicesPerStore: null,
    avgScansPerStore: null,
    maxStores: lineItem.numberOfStores,
    storeType: STORE_TYPE_MAPPING[lineItem.storeType],
    storeEngagementTier: STORE_ENGAGEMENT_MAPPING[lineItem.engagementTier],
    geography: lineItem.territory ? lineItem.territory.split(";").join(",") : "",
    addons: addOns,
    symbologies: symbologies,
    edition: EDITION_MAPPING[lineItem.variant],
    pilot: lineItem.typeOfAgreement == "Pilot",
    staged: lineItem.lineItemOfStagedDeal == "True",
    purposeType: "product",
    terminated: null,
    annualFee: 0,
    parents: [],
    children: [],
  };
}

function castLineItemFields(lineItem: LineItem): LineItem {
  // some things are sent as strings, we have to parse them
  if (typeof lineItem.salesPrice === "string") lineItem.salesPrice = parseFloat(lineItem.salesPrice);
  return lineItem;
}

export function useLineItems(
  accountId: string,
  page?: number,
  params?: { [key: string]: string }
): ResourceListResult<LineItem> {
  params ||= {};
  const result = useServiceResourceList<LineItem>(`/v1/accounts/${accountId}/lineitems/`, page, params);
  if (result.data) result.data = result.data.map(castLineItemFields);
  return result;
}

export function useLineItem(accountId: string, lineItemId: number): ResourceResult<LineItem> {
  const result = useServiceResource<LineItem>(`/v1/accounts/${accountId}/lineitems/${lineItemId}/`);
  if (result.data) result.data = castLineItemFields(result.data);
  return result;
}

export function useLineItemModifier(accountId: string): ResourceModifier<LineItem> {
  return useServiceResourceModifier<LineItem>(`/v1/accounts/${accountId}/lineitems/`);
}

export interface RecognizedRevenueOverride {
  id: number;
  currency: string;
  lineItem: number;
  startDate: string;
  endDate: string;
  dailyRecognizedRevenue: number;
  comment: string;
}

export function useRecognizedRevenueOverrides(
  accountId: string,
  lineItemId: number,
  page?: number,
  params?: { [key: string]: string }
): ResourceListResult<RecognizedRevenueOverride> {
  params ||= {};
  const result = useServiceResourceList<RecognizedRevenueOverride>(
    `/v1/accounts/${accountId}/lineitems/${lineItemId}/recognized-revenue-overrides/`,
    page,
    params
  );
  return result;
}

export function useRecognizedRevenueOverridesModifier(
  accountId: string,
  lineItemId: number
): ResourceModifier<RecognizedRevenueOverride> {
  return useServiceResourceModifier<RecognizedRevenueOverride>(
    `/v1/accounts/${accountId}/lineitems/${lineItemId}/recognized-revenue-overrides/`
  );
}
