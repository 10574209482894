import * as React from "react";

import { useSalesforce, AuthContext } from "./service/auth";
import { useSelector } from "react-redux";
import { selectUserSessionExpiryDate } from "./store/authSlice";

export function AuthProvider(props: { children: React.ReactNode }): JSX.Element {
  const expiryDate = useSelector(selectUserSessionExpiryDate);
  // Do not remove the line below! The result of the hook is not used, although it sets listeners that are required
  // to authenticate using Salesforce. It is needed only temporarily, SF login should be removed soon.
  const _ = useSalesforce();

  const value = {
    isAuthenticated: Boolean(expiryDate && expiryDate > new Date()),
  };
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
