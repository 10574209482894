import { useDispatch, useSelector } from "react-redux";
import {
  selectPreferences,
  setDateFormat,
  setMoneyFormat,
  setShowContractGraph,
  setFullWidthUI,
} from "../store/preferencesSlice";
import { DateFormat, formatDate } from "../utils/date";
import { formatMoney, MoneyFormat } from "../utils/money";

type Context = {
  dateFormat: DateFormat;
  setDateFormat: (format: DateFormat) => void;
  formatDate: (date: Date | string | null) => string;
  moneyFormat: MoneyFormat;
  setMoneyFormat: (format: MoneyFormat) => void;
  formatMoney: (value: string | number | null | undefined, currency?: string | null) => string;
  showContractGraph: boolean;
  setShowContractGraph: (value: boolean) => void;
  fullWidthUI: boolean;
  setFullWidthUI: (value: boolean) => void;
};

export const usePreferences = (): Context => {
  const { dateFormat, moneyFormat, showContractGraph, fullWidthUI } = useSelector(selectPreferences);
  const dispatch = useDispatch();

  return {
    dateFormat,
    setDateFormat: (f) => dispatch(setDateFormat(f)),
    formatDate: (date) => formatDate(date, dateFormat),
    moneyFormat,
    setMoneyFormat: (f) => dispatch(setMoneyFormat(f)),
    formatMoney: (value, currency) => formatMoney(moneyFormat, value, currency),
    showContractGraph,
    setShowContractGraph: (f) => dispatch(setShowContractGraph(f)),
    fullWidthUI,
    setFullWidthUI: (f) => dispatch(setFullWidthUI(f)),
  };
};
