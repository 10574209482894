import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Select } from "../../../components/Select";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDictionary } from "../../../service/dictionary";
import { Subscription, useSubscriptionModifier } from "../../../service/subscriptions";
import { useNotifications } from "../../../notifications";
import { ValidationError } from "../../../service/api";
import { useState } from "react";
import { ValidationErrors } from "../../../components/ValidationErrors";
import { Header } from "../../Header";
import { ContentBox } from "../../../components/ContentBox";

type FormFields = Pick<Subscription, "name" | "integrationPath" | "userType" | "isProduction" | "usageMetric">;

export function Create() {
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();
  const { dictionary } = useDictionary();
  const { create: createSubscription } = useSubscriptionModifier(accountId);
  const { addNotification } = useNotifications();
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: { isProduction: true },
  });
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);

  const onSubmit: SubmitHandler<FormFields> = async (data: FormFields) => {
    // By license key metrics are legacy: we display a warning to the user.
    if (data.usageMetric.endsWith("by-lk")) {
      addNotification({
        level: "warning",
        text: "The selected usage metric is deprecated (legacy) and should only be used for historic data, not new contracts",
      });
    }

    setErrors(null);
    let subscription: Subscription | null;
    try {
      subscription = await createSubscription({
        accountId: accountId,
        ...data,
      } as Subscription);
    } catch (e) {
      if (e instanceof ValidationError) {
        setErrors(e.errors);
        return;
      }
      throw e;
    }

    addNotification({
      text: "Subscription created",
      level: "success",
    });
    history.push({
      pathname: `/accounts/${accountId}/subscriptions/${subscription.id}/`,
    });
  };

  return (
    <>
      <Header accountId={accountId} page="New subscription" />

      <ContentBox>
        {errors && <ValidationErrors title="Invalid subscription" errors={errors} />}

        <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <TextField
                      error={!!error}
                      helperText={error ? error.message || "Please provide correct value" : null}
                      required
                      label="Name"
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Select
                control={control as any}
                name="integrationPath"
                label="Integration path"
                required
                rules={{ required: true }}
                default=""
              >
                {
                  [
                    <MenuItem key="" value="">
                      -
                    </MenuItem>,
                  ].concat(
                    Object.entries(dictionary.INTEGRATION_PATHS).map((entry) => (
                      <MenuItem key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </MenuItem>
                    ))
                  ) as any
                }
              </Select>
            </Grid>
            <Grid item xs={7}>
              <Select
                control={control as any}
                name="userType"
                label="User type"
                required
                rules={{ required: true }}
                default=""
              >
                {
                  [
                    <MenuItem key="" value="">
                      -
                    </MenuItem>,
                  ].concat(
                    Object.entries(dictionary.USER_TYPES).map((entry) => (
                      <MenuItem key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </MenuItem>
                    ))
                  ) as any
                }
              </Select>
            </Grid>
            <Grid item xs={7}>
              <Select
                control={control as any}
                name="usageMetric"
                label="Usage metric"
                required
                rules={{ required: true }}
                default=""
              >
                {
                  [
                    <MenuItem key="" value="">
                      -
                    </MenuItem>,
                  ].concat(
                    Object.entries(dictionary.USAGE_METRICS).map((entry) => (
                      <MenuItem key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </MenuItem>
                    ))
                  ) as any
                }
              </Select>
            </Grid>
            <Grid item xs={7}>
              <Controller
                name="isProduction"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                    }
                    label="Production subscription"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="text" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ContentBox>
    </>
  );
}
