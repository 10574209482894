import * as React from "react";
import { useEffect, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { usePreferences } from "../service/preferences";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import formatISO from "date-fns/formatISO";
import type { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import IconButton from "@mui/material/IconButton";
import Save from "@mui/icons-material/Save";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editContainer: {
      display: "flex",
    },
    editorForm: {
      flexGrow: 1,
    },
    editDisplayValue: {
      alignSelf: "center",
    },
  })
);

export interface EditableFieldProps<T> {
  value: T;
  label: string;
  editable?: boolean;
  required?: boolean;
  onChange?: (v: T) => void;
  editor?: (value: T, onChange: (v: T) => void, onChanged: () => void, error: string | null) => JSX.Element;
  validate?: (value: T) => string | null;
  render?: (displayValue: string) => React.ReactNode;
}

export function EditableField<T>(props: EditableFieldProps<T> & { displayValue: string }): JSX.Element {
  const classes = useStyles();

  const [value, setValue] = useState<T>(props.value);
  const [edit, setEdit] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const validate = (value: T): string | null => {
    if (props.required && ((typeof value === "string" && value === "") || value == null)) {
      return "Value is required";
    }
    if (props.validate) {
      return props.validate(value);
    }

    return null;
  };

  const onChange = (value: T) => {
    setError(validate(value));
    setValue(value);
  };
  const onChanged = () => {
    if (error) return;
    setEdit(false);
    props.onChange ? props.onChange(value) : null;
  };

  const getFields = () => {
    if (edit && props.editor) {
      return (
        <>
          {props.editor(value, onChange, onChanged, error)}
          <IconButton onClick={onChanged} component="span">
            <Save />
          </IconButton>
        </>
      );
    }

    const render = props.render ?? ((d) => <span>{d}</span>);

    return (
      <>
        <div className={classes.editDisplayValue}>{render(props.displayValue)}</div>
        {props.editable && !edit && (
          <IconButton onClick={() => setEdit(true)}>
            <EditIcon />
          </IconButton>
        )}
      </>
    );
  };

  return (
    <div>
      <Typography variant="h6">
        <b>{props.label}</b>
      </Typography>
      <div className={classes.editContainer}>{getFields()}</div>
    </div>
  );
}

export function StringEditableField(props: EditableFieldProps<string | null>): JSX.Element {
  const classes = useStyles();

  function editor(value: any, onChange: any, onChanged: () => void, error: any): JSX.Element {
    const onChangedWrapper = (e: any) => {
      // Stop event from bubbling up (this stops users from submitting an invalidated form)
      e.preventDefault();
      onChanged();
    };

    return (
      <form className={classes.editorForm} onSubmit={onChangedWrapper}>
        <TextField
          autoFocus
          fullWidth
          error={!!error}
          helperText={error}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      </form>
    );
  }
  return <EditableField {...props} editor={editor} displayValue={props.value || "-"} />;
}

export function ChoiceEditableField(
  props: EditableFieldProps<string | null> & { choices: { [key: string]: string } }
): JSX.Element {
  function editor(value: any, onChange: any, onChanged: () => void, error: any): JSX.Element {
    return (
      <TextField
        value={value || ""}
        select
        error={!!error}
        helperText={error}
        onChange={(e) => onChange(e.target.value)}
      >
        {Object.entries(props.choices).map((entry: [string, string]) => (
          <MenuItem key={entry[0]} value={entry[0]}>
            {entry[1]}
          </MenuItem>
        ))}
      </TextField>
    );
  }
  return (
    <EditableField {...props} editor={editor} displayValue={props.choices[props.value as any] || props.value || "-"} />
  );
}

export function BooleanEditableField(props: EditableFieldProps<boolean | null>): JSX.Element {
  function editor(value: any, onChange: any, error: any): JSX.Element {
    return <Switch checked={!!value} onChange={(e) => onChange(e.target.checked)} />;
  }
  return (
    <EditableField {...props} editor={editor} displayValue={props.value === null ? "-" : props.value ? "Yes" : "No"} />
  );
}

export function DecimalEditableField(
  props: EditableFieldProps<string | number | null> & { currency?: string | null }
): JSX.Element {
  const { formatMoney } = usePreferences();
  const newProps = { ...props };
  newProps.editable = false;
  return <EditableField {...newProps} displayValue={formatMoney(props.value, props.currency)} />;
}

export function DateEditableField(props: EditableFieldProps<string | null>): JSX.Element {
  const { formatDate, dateFormat } = usePreferences();
  function editor(value: any, onChange: any, error: any): JSX.Element {
    return (
      <DesktopDatePicker
        label={props.label}
        value={value || ""}
        onChange={(date: any) => {
          let value;
          try {
            value = formatISO(date as any, { representation: "date" });
          } catch (RangeError) {
            return;
          }
          onChange(value);
        }}
        inputFormat={dateFormat}
        renderInput={(params: any) => <TextField {...params} />}
      />
    );
  }
  return <EditableField displayValue={formatDate(props.value)} editor={editor} {...props} />;
}

export function IntegerEditableField(props: EditableFieldProps<number | null>): JSX.Element {
  function editor(value: any, onChange: (n: number | null) => void, error: any): JSX.Element {
    function onChangeValidate(value: string) {
      if (onChange !== undefined && /^-?\d+$/.test(value)) return onChange(parseInt(value));
    }
    return (
      <TextField
        type="number"
        value={value !== null ? value : ""}
        error={!!error}
        helperText={error}
        onChange={(e) => onChangeValidate(e.target.value)}
        // Disables changes to the value on wheel scrolling (UT-6336)
        // .preventDefault() doesn't work because wheel is a passive event
        onWheel={(_) => (document.activeElement as any).blur()}
      />
    );
  }
  return <EditableField {...props} displayValue={!!props.value ? props.value.toString() : "-"} editor={editor} />;
}
