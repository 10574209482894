import { memo } from "react";
import { animated } from "@react-spring/web";
import { InputLink, InputNode, LinkProps } from "@nivo/network";
import { BIG_NODE_SIZE } from "./NetworkNode";

const NonMemoizedNetworkLink = <Node extends InputNode, Link extends InputLink>({
  link,
  animated: animatedProps,
  blendMode,
}: LinkProps<Node, Link>) => {
  const targetSize = link.target.size;
  const arrowMarkerId = `targetarrow-${link.source.id}-${link.target.id}`;
  let offset: number;
  // Hardcoded offsets because I can't find how to calculate it
  if (targetSize < BIG_NODE_SIZE) {
    offset = 10;
  } else {
    offset = 15;
  }
  return (
    <>
      <defs>
        <defs>
          <marker
            id={arrowMarkerId}
            markerWidth="5"
            markerHeight="4"
            refX={offset}
            refY="2"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 0, 5 2, 0 4" fill={link.source.color} />
          </marker>
        </defs>
      </defs>
      <animated.line
        stroke={animatedProps.color}
        style={{ mixBlendMode: blendMode }}
        strokeWidth={link.thickness}
        strokeLinecap="round"
        opacity={animatedProps.opacity}
        x1={animatedProps.x1}
        y1={animatedProps.y1}
        x2={animatedProps.x2}
        y2={animatedProps.y2}
        markerEnd={`url(#${arrowMarkerId})`}
      />
    </>
  );
};

export const NetworkLink = memo(NonMemoizedNetworkLink) as typeof NonMemoizedNetworkLink;
