import * as React from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { Contract, useContractModifier, useContract } from "../../../service/contracts";
import { LineItem, useLineItemModifier, useLineItems } from "../../../service/lineitems";
import { ContractForm } from "./components/ContractForm";
import { ContractLineItems } from "./components/ContractLineItems";
import { ValidationError } from "../../../service/api";
import { useState } from "react";
import { useNotifications } from "../../../notifications";
import { Header } from "../../Header";
import { useDictionary } from "../../../service/dictionary";

export function Show() {
  const { accountId, contractId } = useParams<{ accountId: string; contractId: string }>();
  const {
    data: contract,
    mutate: mutateContract,
    isLoading: contractLoading,
  } = useContract(accountId, parseInt(contractId));
  const {
    data: lineItems,
    mutate: mutateLineItems,
    isLoading: lineItemsLoading,
  } = useLineItems(accountId, 0, { contract: contractId });
  const contractModifier = useContractModifier(accountId);
  const lineItemModifier = useLineItemModifier(accountId);
  const [contractErrors, setContractErrors] = useState<{ [key: string]: string[] } | null>(null);
  const [lineItemsErrors, setLineItemsErrors] = useState<{ [key: number]: { [key: string]: string } } | null>(null);
  const { addNotification } = useNotifications();
  const { dictionary } = useDictionary();

  async function handleContractChange(contract: Contract): Promise<boolean> {
    setContractErrors(null);
    let updatedContract;
    try {
      updatedContract = await contractModifier.update(contract);
    } catch (error) {
      if (error instanceof ValidationError) {
        setContractErrors(error.errors);
        return false;
      }
      throw error;
    }
    await mutateContract(updatedContract);
    addNotification({
      text: "Contract updated successfully",
      level: "success",
    });
    return true;
  }

  async function handleLineItemsChange(lineItem: LineItem, idx: number): Promise<boolean> {
    setLineItemsErrors(null);
    if (lineItem.useCase != null && !dictionary.USE_CASES.includes(lineItem.useCase)) {
      addNotification({
        level: "warning",
        text: "Entered usecase is not contained in official usecase master list",
      });
    }

    try {
      if (lineItem.id) {
        const updatedLineItem = await lineItemModifier.update(lineItem);
        await mutateLineItems(lineItems!.map((li, liIdx) => (liIdx == idx ? updatedLineItem : li)) as any);
        addNotification({
          text: "Line item updated successfully",
          level: "success",
        });
      } else {
        const newLineItem = await lineItemModifier.create(Object.assign(lineItem, { contractId: contractId }));
        await mutateLineItems(lineItems!.concat([newLineItem]) as any);
        addNotification({
          text: "Line item created successfully",
          level: "success",
        });
      }
      await mutateContract();
      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        setLineItemsErrors({ [idx]: error.errors } as any);
        return false;
      }
      throw error;
    }
  }

  async function handleLineItemsDelete(lineItem: LineItem, idx: number): Promise<boolean> {
    await lineItemModifier.delete(lineItem);
    await mutateLineItems(lineItems!.filter((_, liIdx) => liIdx != idx) as any);
    await mutateContract();
    addNotification({
      text: "Line item deleted successfully",
      level: "success",
    });
    return true;
  }

  return (
    <>
      <Header accountId={accountId} contract={contract} loading={contractLoading} />

      {(lineItemsLoading || contractLoading) && <CircularProgress />}
      {contract && (
        <ContractForm
          errors={contractErrors}
          lineItems={lineItems}
          accountId={accountId}
          contract={contract}
          onChange={handleContractChange}
          shouldShowRenewButton={true}
        />
      )}
      {contract && lineItems != undefined && (
        <ContractLineItems
          errors={lineItemsErrors as any}
          lineItems={lineItems}
          onChange={handleLineItemsChange}
          onDelete={handleLineItemsDelete}
          allowSalesforceRefresh={true}
          contract={contract}
          accountId={accountId}
        />
      )}
    </>
  );
}
