import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DateFormat, DEFAULT_DATE_FORMAT } from "../utils/date";
import { DEFAULT_MONEY_FORMAT, MoneyFormat } from "../utils/money";
import { RootState } from "./store";

export interface PreferencesState {
  dateFormat: DateFormat;
  moneyFormat: MoneyFormat;
  showContractGraph: boolean;
  fullWidthUI: boolean;
}

const initialState: PreferencesState = {
  dateFormat: DEFAULT_DATE_FORMAT,
  moneyFormat: DEFAULT_MONEY_FORMAT,
  showContractGraph: false,
  fullWidthUI: false,
};

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setDateFormat: (state: PreferencesState, action: PayloadAction<DateFormat>) => {
      state.dateFormat = action.payload;
    },
    setMoneyFormat: (state: PreferencesState, action: PayloadAction<MoneyFormat>) => {
      state.moneyFormat = action.payload;
    },
    setShowContractGraph: (state: PreferencesState, action: PayloadAction<boolean>) => {
      state.showContractGraph = action.payload;
    },
    setFullWidthUI: (state: PreferencesState, action: PayloadAction<boolean>) => {
      state.fullWidthUI = action.payload;
    },
  },
});

export const selectPreferences = createSelector(
  (state: RootState) => state.preferences,
  (p: PreferencesState) => p
);

export const { setDateFormat, setMoneyFormat, setShowContractGraph, setFullWidthUI } = preferencesSlice.actions;
export default preferencesSlice.reducer;
