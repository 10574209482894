import * as React from "react";
import { RouteProps } from "react-router";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useNotifications } from "./notifications";

import { Permission, useAuth, usePermissions } from "./service/auth";

interface RequiredPermissionProps {
  requiredPermission?: Permission;
}
type RenderRequiredRouteProps = Required<Pick<RouteProps, "render">>;
type AuthenticatedRouteProps = RouteProps & RequiredPermissionProps & RenderRequiredRouteProps;

type ForwardedPropsType = Parameters<RenderRequiredRouteProps["render"]>[0];
interface HasInnerProps<TForwardedProps> {
  innerProps: TForwardedProps;
}
type InnerAuthenticatedRouteProps<TForwardedProps> = RequiredPermissionProps &
  RenderRequiredRouteProps &
  HasInnerProps<TForwardedProps>;

const InnerAuthenticatedRoute = <TForwardedProps extends ForwardedPropsType>(
  props: InnerAuthenticatedRouteProps<TForwardedProps>
) => {
  const location = useLocation();
  const { addNotification } = useNotifications();
  const { isAuthenticated } = useAuth();
  const { hasPermission } = usePermissions();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/sign-in",
          state: { source: [location.pathname, location.search || "", location.hash || ""].join("") },
        }}
      />
    );
  }

  const isAllowed = () => {
    if (props.requiredPermission) return hasPermission(props.requiredPermission);
    return isAuthenticated;
  };

  if (isAllowed()) {
    return <>{props.render(props.innerProps)}</>;
  }

  addNotification({
    level: "error",
    text: "You do not have the required permissions to access this page.",
  });

  return <Redirect to="/" />;
};

export const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  return <Route {...props} render={(innerProps) => <InnerAuthenticatedRoute {...props} innerProps={innerProps} />} />;
};
