import * as React from "react";
import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import BaseSelect from "@mui/material/Select";

import { Control, Controller } from "react-hook-form";
import { ControllerProps } from "react-hook-form/dist/types/controller";

interface BaseProps {
  label: string;
  helpText?: string;
  default: string;
  required?: boolean;
  multiple?: boolean;
  children: JSX.Element;
  control: Control;
  disabled?: boolean;
}

export declare type Props = BaseProps & Pick<ControllerProps, "rules" | "name">;

export function Select(props: Props): JSX.Element {
  const [id] = useState<string>(uniqueId("select"));

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.default}
      rules={props.rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth required={props.required} error={!!error}>
          <InputLabel id={`${id}-label`}>{props.label}</InputLabel>
          <BaseSelect
            disabled={props.disabled}
            labelId={`${id}-label`}
            label={props.label}
            required={!!props.required}
            multiple={!!props.multiple}
            {...field}
          >
            {props.children}
          </BaseSelect>
          {!error && props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
          {error && <FormHelperText>{error.message || "Please select an option"}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
