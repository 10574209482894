import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDictionary } from "../../../service/dictionary";
import { LineItem } from "../../../service/lineitems";
import { UseCaseAnnotation } from "../../../service/usecaseannotations";
import { UsecaseFormValues } from "./types";
import { UsageAutocomplete } from "./UsageAutocomplete";

export const UseCaseForm = (props: {
  accountId: string;
  lineitem: LineItem;
  onSubmit: (data: UsecaseFormValues) => Promise<void>;
  onDiscard: () => void;
  editValues?: UseCaseAnnotation | undefined;
  usageLimit: number;
}) => {
  if (!props.lineitem.id == null) {
    // should never happen
    return <></>;
  }

  const { dictionary } = useDictionary();
  const { control, handleSubmit } = useForm<UsecaseFormValues>({});

  // Example calculations:
  // * If there is a usageLimit of 40% remaining
  // ** And we're adding a use case (props.editValues?.percentage not set), the maximum should be 60%
  // ** And we're editing a use case (props.editValues?.percentage == 60), the maximum should still be 60%
  const availablePercentage = props.usageLimit + Number(props.editValues?.percentage || 0);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <Card>
        <CardContent>
          <Typography gutterBottom>
            <em>{dictionary.USER_TYPES[props.lineitem.userType] || props.lineitem.userType}</em>
          </Typography>
          <Divider />
          <Grid style={{ paddingTop: "1em" }} container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <UsageAutocomplete
                defaultValue={props.editValues?.useCasePack}
                name="useCasePack"
                options={dictionary.USE_CASE_PACKS}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <UsageAutocomplete
                defaultValue={props.editValues?.useCaseSuite}
                name="useCaseSuite"
                options={dictionary.USE_CASE_SUITES}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <UsageAutocomplete
                defaultValue={props.editValues?.useCase}
                name="useCase"
                options={dictionary.USE_CASES}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="percentage"
                control={control}
                rules={{ min: 1, max: availablePercentage, required: true }}
                defaultValue={props.editValues?.percentage}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth>
                    <TextField
                      {...field}
                      error={!!error}
                      defaultValue={props.editValues?.percentage}
                      helperText={error ? "Please set a percentage." : null}
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                      inputProps={{ min: 1, max: availablePercentage, step: 1 }}
                      placeholder="1"
                      InputLabelProps={{ shrink: true }}
                      label={"Percentage"}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <ButtonGroup fullWidth color="primary">
                <Button color="primary" type="submit" startIcon={<SaveIcon />}>
                  Save
                </Button>
                <Button endIcon={<DeleteIcon />} onClick={props.onDiscard}>
                  Discard
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};
