import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import makeStyles from "@mui/styles/makeStyles";
import type { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ContentBox } from "../../../components/ContentBox";
import { ChoiceEditableField, StringEditableField } from "../../../components/editable";
import { useDictionary } from "../../../service/dictionary";
import { useNotifications } from "../../../notifications";
import { Subscription, useSubscriptionModifier, useSubscriptions } from "../../../service/subscriptions";
import { UsageGroup, useUsageGroup, useUsageGroups, useUsageGroupsModifier } from "../../../service/usagegroups";
import { Header } from "../../Header";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmedButton } from "../../../components/ConfirmedButton";
import { ActiveConfiguration } from "../subscriptions/Show";
import { ValidationError } from "../../../service/api";
import { ValidationErrors } from "../../../components/ValidationErrors";
import uniqBy from "lodash/uniqBy";
import cloneDeep from "lodash/cloneDeep";
import { usePermissions } from "../../../service/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      borderBottom: "1px solid black",
    },
    secondary: {
      color: theme.palette.text.secondary,
    },
  })
);

function SubscriptionSummary(props: { subscription: Subscription }): JSX.Element {
  const { dictionary } = useDictionary();
  const classes = useStyles();
  const products = uniqBy(props.subscription.availableFeatures, (x) => x.productType);

  return (
    <>
      <Grid container item spacing={4} xs={12} justifyContent="center">
        {products.map((feature, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Grid item xs={11} className={classes.border}></Grid>}
            <Grid item xs={11}>
              <Typography variant="h6">{dictionary.PRODUCT_TYPES[feature.productType]}</Typography>
            </Grid>
            <Grid container item xs={11} justifyContent="center">
              <ActiveConfiguration showTitle={false} config={feature} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
}

function UsageGroupSubscriptions(props: {
  loading: boolean;
  subscriptions: Subscription[];
  onRemove: (subscription: Subscription) => Promise<void>;
}): JSX.Element {
  const { hasPermission } = usePermissions();
  const { dictionary } = useDictionary();
  const classes = useStyles();

  return (
    <ContentBox header="Subscriptions included in Usage Group">
      <Grid item container xs={12}>
        {props.loading ? (
          <CircularProgress size="15px" />
        ) : (
          props.subscriptions.map((subscription) => (
            <Accordion key={subscription.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item container xs={12}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1">{subscription.name}</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.secondary}>
                    <strong>Integration Path:</strong>{" "}
                    {dictionary.INTEGRATION_PATHS[subscription.integrationPath] || subscription.integrationPath}
                  </Grid>
                  <Grid item xs={3} className={classes.secondary}>
                    <strong>User Type:</strong> {dictionary.USER_TYPES[subscription.userType] || subscription.userType}
                  </Grid>
                  <Grid item xs={1} className={classes.secondary}>
                    <strong>From:</strong> {subscription.startDate}
                  </Grid>
                  <Grid item xs={1} className={classes.secondary}>
                    <strong>Until:</strong> {subscription.endDate}
                  </Grid>
                  <Grid item container xs={3} justifyContent="center">
                    <ButtonGroup color="primary" variant="outlined">
                      {hasPermission("basicEditing") && (
                        <ConfirmedButton
                          confirmationTitle="Confirmation"
                          confirmationText={`Are you sure you want to remove subscription ${subscription.name} from this usage group?`}
                          onConfirmed={() => props.onRemove(subscription)}
                          disabled={false}
                          startIcon={<DeleteIcon />}
                          clicksStopPropagation
                          size="small"
                        >
                          Remove from Usage Group
                        </ConfirmedButton>
                      )}
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <SubscriptionSummary subscription={subscription} />
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Grid>
    </ContentBox>
  );
}

function GroupOverview(props: {
  loading: boolean;
  usageGroup: UsageGroup | undefined;
  editable: boolean;
  onChange: (changes: { [key: string]: any }) => Promise<void>;
}): JSX.Element {
  const { dictionary } = useDictionary();
  const { hasPermission } = usePermissions();

  return (
    <>
      {props.loading && <CircularProgress size="15px" />}
      {props.usageGroup && (
        <ContentBox header="Usage Group Overview">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StringEditableField
                label="Name"
                value={props.usageGroup.name}
                required
                onChange={(v) => props.onChange({ name: v })}
                editable={hasPermission("basicEditing")}
              />
            </Grid>
            <Grid item xs={12}>
              <ChoiceEditableField
                label="License usage metric"
                value={props.usageGroup.usageMetric}
                choices={dictionary.USAGE_METRICS}
                onChange={(v) => props.onChange({ usageMetric: v })}
                editable={hasPermission("basicEditing") && props.editable}
              />
            </Grid>
          </Grid>
        </ContentBox>
      )}
    </>
  );
}

function SubscriptionAdd(props: {
  loading: boolean;
  subscriptions: Subscription[] | undefined;
  selectedSubscription: Subscription | null;
  setSelectedSubscription: (subscription: Subscription) => void;
  onClick: () => Promise<void>;
}): JSX.Element {
  return (
    <>
      <ContentBox header="Add Subscriptions">
        <Grid container item xs={12}>
          {props.loading ? (
            <CircularProgress size="15px" />
          ) : (props.subscriptions || []).length === 0 ? (
            <Alert severity="warning">No subscriptions matching current usage metric</Alert>
          ) : (
            <>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="subscription-label">Available Subscriptions</InputLabel>
                  <Select
                    value={props.selectedSubscription || ""}
                    onChange={(e) => props.setSelectedSubscription(e.target.value as Subscription)}
                    labelId="subscription-label"
                    label="Available subscriptions"
                  >
                    {[
                      <MenuItem key="" value="">
                        -
                      </MenuItem>,
                    ].concat(
                      (props.subscriptions || []).map((subscription) => (
                        <MenuItem key={subscription.id} value={subscription as any}>
                          {subscription.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button onClick={props.onClick} disabled={props.selectedSubscription == null}>
                  Add
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </ContentBox>
    </>
  );
}

export function Show(): JSX.Element {
  const { accountId, usageGroupId } = useParams<{ accountId: string; usageGroupId: string }>();
  const {
    isLoading: groupsLoading,
    data: usageGroup,
    mutate: mutateGroups,
  } = useUsageGroup(accountId, parseInt(usageGroupId));

  return (
    <>
      <Header accountId={accountId} usageGroup={usageGroup} />
      {!groupsLoading && usageGroup && (
        <SubscriptionAssignment
          mutateGroups={mutateGroups}
          loading={groupsLoading}
          accountId={accountId}
          usageGroup={usageGroup}
        ></SubscriptionAssignment>
      )}
    </>
  );
}

function SubscriptionAssignment(props: {
  accountId: string;
  usageGroup: UsageGroup;
  loading: boolean;
  mutateGroups: any;
}): JSX.Element {
  const { hasPermission } = usePermissions();
  const [usageGroupErrors, setUsageGroupErrors] = useState<{ [key: string]: string[] } | null>(null);
  const { update: updateGroup } = useUsageGroupsModifier(props.accountId);
  const { update: updateSubscription } = useSubscriptionModifier(props.accountId);
  const { addNotification } = useNotifications();
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);

  const {
    isLoading: subscriptionsLoading,
    data: availableSubscriptions,
    mutate: mutateSubscription,
  } = useSubscriptions(props.accountId, 0, {
    usage_metric: props.usageGroup.usageMetric,
    usage_group: "",
  });

  const {
    isLoading: groupSubscriptionsLoading,
    data: groupSubscriptions,
    mutate: mutateGroupSubscriptions,
  } = useSubscriptions(props.accountId, 0, {
    usage_group: props.usageGroup?.id || "",
  });

  const onUsageGroupChange = async (changes: { [key: string]: any }) => {
    setUsageGroupErrors(null);
    const changedGroup: any = cloneDeep<UsageGroup>(props.usageGroup);
    Object.entries(changes).forEach((entry) => {
      changedGroup[entry[0]] = entry[1];
    });
    try {
      const updated = await updateGroup(changedGroup);
      await props.mutateGroups(updated);
    } catch (error) {
      if (error instanceof ValidationError) {
        setUsageGroupErrors(error.errors);
        return;
      }
      throw error;
    }
  };

  const onSubscriptionAdd = async () => {
    if (!selectedSubscription) return;
    const changed = cloneDeep<Subscription>(selectedSubscription!);
    changed.usageGroupId = props.usageGroup?.id;
    try {
      await updateSubscription(changed);
      await mutateSubscription();
      await mutateGroupSubscriptions();
    } catch (error) {
      addNotification({
        text: "Subscription could not be added, please try again.",
        level: "error",
      });
    }
  };

  const onSubscriptionRemove = async (subscription: Subscription) => {
    subscription.usageGroupId = undefined;
    try {
      await updateSubscription(subscription);
      await mutateSubscription();
      await mutateGroupSubscriptions();
    } catch (error) {
      addNotification({
        text: "Subscription could not be removed, please try again.",
        level: "error",
      });
    }
  };
  return (
    <>
      <GroupOverview
        loading={props.loading}
        usageGroup={props.usageGroup}
        onChange={onUsageGroupChange}
        editable={!groupSubscriptions || !groupSubscriptions.length}
      />
      {hasPermission("basicEditing") && (
        <SubscriptionAdd
          loading={subscriptionsLoading}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
          subscriptions={availableSubscriptions}
          onClick={onSubscriptionAdd}
        />
      )}
      <UsageGroupSubscriptions
        loading={groupSubscriptionsLoading}
        subscriptions={groupSubscriptions || []}
        onRemove={onSubscriptionRemove}
      />
      {usageGroupErrors && <ValidationErrors title="Usage group invalid" errors={usageGroupErrors} />}
    </>
  );
}
