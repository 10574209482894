import { ResourceResult, useServiceResource } from "./api";

export interface Dictionary {
  USAGE_METRICS: { [key: string]: string };
  INTEGRATION_PATHS: { [key: string]: string };
  USER_TYPES: { [key: string]: string };
  PRODUCT_TYPES: { [key: string]: string };
  COMMUNICATION_SALES_PACKAGES: { [key: string]: string };
  DEVICE_TYPES: { [key: string]: string };
  SUPPORT_LEVEL_TYPES: { [key: string]: string };
  DEAL_TYPES: { [key: string]: string };
  AUTO_PRICE_INCREASES: { [key: string]: string };
  RENEWAL_TERM_LENGTHS: { [key: string]: string };
  PRICE_INCREASE_ALLOWANCES: { [key: string]: string };
  LICENSE_TYPES: { [key: string]: string };
  ANALYTICS_TYPES: { [key: string]: string };
  PLATFORMS: { [key: string]: string };
  ADDONS: { [key: string]: string };
  EDITIONS: { [short_name: string]: string };
  EDITIONS_PER_PRODUCT_TYPE: { [product_type: string]: string[] };
  INTEGRATION_PATHS_PER_PRODUCT_TYPE: { [product_type: string]: string[] };
  EDITION_PER_INTEGRATION_PATH_PER_PRODUCT_TYPE: {
    [product_type: string]: { [integration_path: string]: string[] };
  };
  ADDONS_PER_INTEGRATION_PATH: { [integration_path: string]: string[] };
  PLATFORMS_PER_PRODUCT_TYPE: { [product_type: string]: string[] };
  PLATFORMS_PER_INTEGRATION_PATH: { [integration_path: string]: string[] };
  USER_TYPES_PER_PRODUCT_TYPE: { [product_type: string]: string[] };
  USER_TYPES_PER_INTEGRATION_PATH: { [integration_path: string]: string[] };
  ANALYTICS_TYPES_PER_COMMUNICATION_SALES_PACKAGE: { [communication_sales_package: string]: string[] };
  DOCUMENT_TYPES: { [key: string]: string };
  CONTRACTING_ENTITIES: string[];
  SYMBOLOGIES: string[];
  SYMBOLOGY_NAMES: { [key: string]: string };
  CURRENCIES: string[];
  COUNTRIES: string[];
  COUNTRIES_IN_AMERICAS: string[];
  STATES: { [key: string]: string[] };
  REGIONS: string[];
  REGIONS_MAPPING: { [key: string]: string };
  STORE_TYPES: { [key: string]: string };
  STORE_ENGAGEMENT_TIERS: { [key: string]: string };
  USE_CASE_SUITES: string[];
  USE_CASE_PACKS: string[];
  USE_CASES: string[];
  LINE_ITEM_PURPOSE_TYPES: { [key: string]: string };

  DEPLOYMENT: {
    VERSION: string;
    TIMESTAMP: string;
  };
}

function useDictionaryResource(): ResourceResult<Dictionary> {
  return useServiceResource<Dictionary>("/v1/dictionary/");
}

const DEFAULT_DICTIONARY: Dictionary = {
  ADDONS: {},
  COMMUNICATION_SALES_PACKAGES: {},
  CONTRACTING_ENTITIES: [],
  DEAL_TYPES: {},
  AUTO_PRICE_INCREASES: {},
  RENEWAL_TERM_LENGTHS: {},
  PRICE_INCREASE_ALLOWANCES: {},
  DEVICE_TYPES: {},
  DOCUMENT_TYPES: {},
  EDITIONS: {},
  EDITIONS_PER_PRODUCT_TYPE: {},
  INTEGRATION_PATHS_PER_PRODUCT_TYPE: {},
  EDITION_PER_INTEGRATION_PATH_PER_PRODUCT_TYPE: {},
  ADDONS_PER_INTEGRATION_PATH: {},
  PLATFORMS_PER_PRODUCT_TYPE: {},
  PLATFORMS_PER_INTEGRATION_PATH: {},
  USER_TYPES_PER_PRODUCT_TYPE: {},
  USER_TYPES_PER_INTEGRATION_PATH: {},
  ANALYTICS_TYPES_PER_COMMUNICATION_SALES_PACKAGE: {},
  INTEGRATION_PATHS: {},
  LICENSE_TYPES: {},
  ANALYTICS_TYPES: {},
  PLATFORMS: {},
  PRODUCT_TYPES: {},
  SUPPORT_LEVEL_TYPES: {},
  SYMBOLOGIES: [],
  SYMBOLOGY_NAMES: {},
  USAGE_METRICS: {},
  USER_TYPES: {},
  CURRENCIES: [],
  COUNTRIES: [],
  COUNTRIES_IN_AMERICAS: [],
  STATES: {},
  REGIONS: [],
  REGIONS_MAPPING: {},
  STORE_TYPES: {},
  STORE_ENGAGEMENT_TIERS: {},
  USE_CASE_SUITES: [],
  USE_CASE_PACKS: [],
  USE_CASES: [],
  LINE_ITEM_PURPOSE_TYPES: {},

  DEPLOYMENT: {
    VERSION: "",
    TIMESTAMP: "",
  },
};

export function useDictionary(): {
  dictionary: Dictionary;
  loadedDictionary: Dictionary | null;
  isError: boolean;
  isLoading: boolean;
} {
  const { data, isError, isLoading } = useDictionaryResource();

  if (data && !isError && !isLoading) {
    return { dictionary: data, loadedDictionary: data, isError: false, isLoading: false };
  }

  // TODO(BA-105): Instead of returning the DEFAULT_DICTIONARY here,
  // instead fix all call sites to correctly handle the case where isLoading === true.
  // (In that case, they should not access the dictionary, which they currently do
  // and which causes a complete crash of the application).
  return { dictionary: data || DEFAULT_DICTIONARY, loadedDictionary: data || null, isError, isLoading };
}
