import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Contract, useContracts } from "../../../service/contracts";

export function OpportunityRedirect(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { opportunityId } = useParams<{ opportunityId: string }>();

  // This should return zero or one result (thus fit first page)
  const { data: contracts } = useContracts(accountId, 1, { opportunity_id: opportunityId });

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    if (contracts !== undefined) {
      const foundContract: Contract | undefined = contracts.find((c) => c.opportunityId === opportunityId);
      setRedirectUrl(
        foundContract !== undefined
          ? `/accounts/${accountId}/contracts/${foundContract.id}`
          : `/accounts/${accountId}/contracts/new`
      );
    }
  }, [contracts, setRedirectUrl]);

  return redirectUrl ? <Redirect to={redirectUrl} /> : <> Redirecting based on opportunity {opportunityId}... </>;
}
