import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      fields: {
        // line item
        productType: { label: "Data capture capability" },
        addons: { label: "Addons" },
        edition: { label: "Edition" },
        integrationPath: { label: "Integration path" },
        platforms: { label: "Platforms" },
        symbologies: { label: "Barcode symbologies" },
        supportLevel: { label: "Support Level" },
        currency: { label: "Currency" },
        salesPrice: { label: "Total price" },
        licenseType: { label: "License type" },
        startDate: { label: "License start date" },
        endDate: { label: "License end date" },
        usageMetric: { label: "License usage metric" },
        maxDevicesPerYear: { label: "Max device per year" },
        maxDevicesPerMonth: { label: "Max devices per month" },
        maxScansPerYear: { label: "Max scans per year" },
        maxActivations: { label: "Max activations" },
        maxScansPerDevicePerYear: { label: "Max scans per device per year" },
        maxStores: { label: "Max stores" },
        storeType: { label: "Store type" },
        storeEngagementTier: { label: "Engagement tier" },
        avgMonthlyDevicesPerStore: { label: "Avg monthly devices per store" },
        avgScansPerStore: { label: "Avg scans per store" },
        userType: { label: "User type" },
        useCasePack: { label: "Usecase Pack" },
        useCaseSuite: { label: "Usecase Suite" },
        useCase: { label: "Usecase" },
        deviceTypes: { label: "Hardware Devices" },
        geography: { label: "Geographic territory" },
        communicationSalesPackage: { label: "Server Communication Settings" },
        analytics: { label: "Analytics settings" },
        anonymizeIp: { label: "IP anonymization" },
        staged: { label: "Staged deal" },
        pilot: { label: "Pilot" },
        purposeType: { label: "Purpose" },
        terminated: { label: "Terminated" },
      },
      lineItem: {
        fieldGroups: {
          product: { label: "Product" },
          support: { label: "Support" },
          fee: { label: "Fee" },
          license: { label: "License" },
          usage: { label: "Usage" },
          useScope: { label: "Use scope" },
          dataTransmission: { label: "Data transmission" },
          additional: { label: "Additional" },
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});
export default i18n;
