import * as React from "react";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import InsightsIcon from "@mui/icons-material/Insights";
import Tooltip from "@mui/material/Tooltip";
import createStyles from "@mui/styles/createStyles";

import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import BusinessIcon from "@mui/icons-material/Business";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom";
import { usePermissions } from "../service/auth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import { config } from "../config";

export const DRAWER_WIDTH: number = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: DRAWER_WIDTH,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
  })
);

interface MainDrawerProps {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}

const MainDrawer = React.memo((props: MainDrawerProps) => {
  const { hasPermission } = usePermissions();
  const classes = useStyles();
  const history = useHistory();

  const handleDrawerClose = () => {
    props.setIsOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !props.isOpen && classes.drawerPaperClose),
      }}
      open={props.isOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button disabled={!hasPermission("readonly")} onClick={() => history.push("/accounts/")}>
          <Tooltip title="Accounts" placement="right">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Accounts" />
        </ListItem>
        <ListItem button disabled={!hasPermission("financialExport")} onClick={() => history.push("/export/")}>
          <Tooltip title="Export" placement="right">
            <ListItemIcon>
              <CloudDownloadIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Export" />
        </ListItem>
        <ListItem button disabled={!hasPermission("readonly")} onClick={() => history.push("/renewals/")}>
          <Tooltip title="Renewals" placement="right">
            <ListItemIcon>
              <InsightsIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Renewals" />
        </ListItem>
        <ListItem button onClick={() => history.push("/profile/")}>
          <Tooltip title="User profile" placement="right">
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="User profile" />
        </ListItem>
        {/* Only available during local development */}
        {config.sentryEnvironment === null && (
          <ListItem button onClick={() => history.push("/debug/")}>
            <Tooltip title="Debug" placement="right">
              <ListItemIcon>
                <PlumbingIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Debug" />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
});

export { MainDrawer };
