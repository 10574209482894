import * as React from "react";
import type { Theme } from "@mui/material/styles";
import type { Variant } from "@mui/material/styles/createTypography";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import type { GridSize } from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import MenuIcon from "@mui/icons-material/Menu";
import TuneIcon from "@mui/icons-material/Tune";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: "10px",
    },
    container: {
      padding: "10px 5px",
      marginBottom: "10px",
    },
  })
);

export function ContentBox(props: {
  children: React.ReactNode;
  header?: string;
  headerVariant?: Variant;
}): JSX.Element {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <CardContent>
        {props.header && (
          <>
            <Typography gutterBottom variant={props.headerVariant || "h5"}>
              {props.header}
            </Typography>
          </>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}

export function ControlContentBox(props: {
  children: React.ReactNode;
  controls?: React.ReactNode;
  controlWidth?: number;
  header?: string;
  headerVariant?: Variant;
}) {
  const classes = useStyles();
  // 4 arbitrarily chosen as the default control width
  const titleWidth: GridSize = props.controls ? ((12 - (props.controlWidth || 4)) as GridSize) : (12 as GridSize);
  const controlWidth: GridSize = props.controls ? ((props.controlWidth || 4) as GridSize) : (12 as GridSize);

  return (
    <Card className={classes.container}>
      <CardContent>
        {props.header && (
          <>
            <Grid container xs={12}>
              <Grid item xs={titleWidth} alignContent="flex-end">
                <Typography gutterBottom variant={props.headerVariant || "h5"}>
                  {props.header}
                </Typography>
              </Grid>
              <Grid className={classes.header} item xs={controlWidth} justifyContent="flex-end">
                {props.controls && <>{props.controls}</>}
              </Grid>
            </Grid>
          </>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}

// todo: remove this from here and use the controlled version as a replacement
export function OptionContentBox(props: {
  children: React.ReactNode;
  header?: string;
  headerVariant?: Variant;
  contractId: string;
  accountId: string;
  shouldShowRenewButton?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget);
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(null);
  const history = useHistory();

  return (
    <Card className={classes.container}>
      <CardContent>
        {props.header && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={10}>
                <Typography gutterBottom variant={props.headerVariant || "h5"}>
                  {props.header}
                </Typography>
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <Grid item>
                  <IconButton aria-haspopup="true" onClick={handleClick} color="inherit">
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    id="contract-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                  >
                    <MenuList>
                      <MenuItem
                        onClick={() =>
                          history.push(`/accounts/${props.accountId}/contracts/${props.contractId}/values/`)
                        }
                      >
                        <ListItemIcon>
                          <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText>Edit Financial Data</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          history.push(`/accounts/${props.accountId}/contracts/${props.contractId}/annotations/`)
                        }
                      >
                        <ListItemIcon>
                          <TuneIcon />
                        </ListItemIcon>
                        <ListItemText>Annotate Use Cases</ListItemText>
                      </MenuItem>
                      {props.shouldShowRenewButton && (
                        <MenuItem
                          onClick={() => {
                            const params = new URLSearchParams();
                            params.set("renewalOnly", "true");
                            params.set("contractId", props.contractId);
                            history.push({
                              pathname: `/accounts/${props.accountId}/contracts/new`,
                              search: `?${params.toString()}`,
                            });
                          }}
                        >
                          <ListItemIcon>
                            <AutorenewIcon />
                          </ListItemIcon>
                          <ListItemText>Renew</ListItemText>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}
