import * as React from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import cloneDeep from "lodash/cloneDeep";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import { useDictionary } from "../../../../service/dictionary";
import { ContractDocuments } from "./ContractDocuments";
import { LineItem } from "../../../../service/lineitems";
import {
  CONTRACTING_ENTITY_MAPPING,
  Contract,
  DEAL_TYPE_MAPPING,
  DealType,
  useContracts,
} from "../../../../service/contracts";
import { ValidationErrors } from "../../../../components/ValidationErrors";
import {
  ChoiceEditableField,
  DateEditableField,
  DecimalEditableField,
  EditableField,
  EditableFieldProps,
  IntegerEditableField,
  StringEditableField,
} from "../../../../components/editable";
import { usePermissions } from "../../../../service/auth";
import { OptionContentBox } from "../../../../components/ContentBox";
import { ContractLink } from "../../../../components/ContractLink";
import { SalesforceLink } from "../../../../components/SalesforceLink";
import { SalesforceOpportunity, useSalesforceOpportunity } from "../../../../salesforce/opportunities";
import Switch from "@mui/material/Switch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import useQueryParams from "../../../../utils/searchparams";

export interface Props {
  accountId: string;
  contract: Contract;
  onChange: (contract: Contract) => Promise<boolean>;
  lineItems?: LineItem[];
  errors?: { [key: string]: string[] } | null;
  shouldShowRenewButton: boolean;
}

function ContractEditableField(
  props: EditableFieldProps<number | null> & { contracts: Contract[] | null }
): JSX.Element {
  const contracts = props.contracts || [];
  function editor(value: any, onChange: any, error: any): JSX.Element {
    return (
      <TextField
        value={value || ""}
        select
        error={!!error}
        helperText={error}
        onChange={(e) => onChange(parseInt(e.target.value))}
      >
        {[<MenuItem value="">-</MenuItem>].concat(
          (contracts || []).map((contract) => (
            <MenuItem key={contract.id} value={contract.id as any}>
              {contract.id}: {contract.name}
            </MenuItem>
          ))
        )}
      </TextField>
    );
  }

  const selected = contracts.find((c) => c.id == props.value);
  return (
    <EditableField displayValue={selected ? `${selected.id}: ${selected.name}` : "-"} editor={editor} {...props} />
  );
}

function OpportunityInfo(props: {
  opportunity: SalesforceOpportunity;
  opportunityLoading: boolean;
  opportunityError: boolean;
}) {
  return (
    <>
      <Typography variant="h6">
        <b>Opportunity info</b>
      </Typography>
      {props.opportunityLoading ? (
        <i>Loading from SFDC...</i>
      ) : props.opportunityError || props.opportunity == null ? (
        <i>Couldn't fetch this Opportunity from SFDC.</i>
      ) : (
        <Typography variant="body1">
          <i>Stage Name:</i> {props.opportunity.stageName}
        </Typography>
      )}
    </>
  );
}

function ContractWarnings(props: { contract: Contract }): JSX.Element {
  const { dictionary } = useDictionary();
  const infos: string[] = [];

  if (
    dictionary.COUNTRIES_IN_AMERICAS.includes(props.contract.deliveryCountry) &&
    props.contract.contractingEntity.startsWith("Scandit AG")
  )
    infos.push(`You have selected a country part of the Americas and the entity selected is Scandit AG. Please verify`);
  if (
    !dictionary.COUNTRIES_IN_AMERICAS.includes(props.contract.deliveryCountry) &&
    props.contract.contractingEntity.startsWith("Scandit Inc")
  )
    infos.push(
      `You have selected a country outside of the Americas and the entity selected is Scandit INC. Please verify`
    );

  return infos.length > 0 ? (
    <Alert severity="info">
      <AlertTitle>Warning</AlertTitle>
      <ul>
        {infos.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    </Alert>
  ) : (
    <></>
  );
}

function CompareSfdcMatch<T>(props: { value: T; expected: T; isRenewal: boolean }): JSX.Element {
  let submsValue = JSON.stringify(props.value);
  let sfdcValue = JSON.stringify(props.expected);
  // don't show status for Renewal contracts' empty fields
  if (props.isRenewal && !props.value) return <></>;
  if (submsValue === sfdcValue)
    return (
      <span style={{ fontSize: "12px", color: "#080" }}>
        <CheckCircleIcon style={{ fontSize: "12px" }} />
        <i> matches Salesforce</i>
      </span>
    );
  else
    return (
      <span style={{ fontSize: "12px", color: "#800" }}>
        <BlockIcon style={{ fontSize: "12px" }} />
        <i> doesn't match Salesforce</i>
      </span>
    );
}

export function ContractForm(props: Props): JSX.Element {
  const { hasPermission } = usePermissions();
  const canChange = hasPermission("basicEditing");
  const { dictionary } = useDictionary();
  const { data: contracts } = useContracts(props.accountId);

  const onChange = async (changes: { [field: string]: any }) => {
    const contract = cloneDeep<Contract>(props.contract);
    Object.entries(changes).forEach((entry) => {
      (contract as any)[entry[0]] = entry[1];
    });
    await props.onChange(contract);
  };

  const lineItems = props.lineItems || [];
  let startDate: Date | null = null;
  let endDate: Date | null = null;
  if (lineItems.length > 0) {
    startDate = new Date(Math.min(...lineItems.map((li) => parseISO(li.startDate).getTime())));
    const withEndDates = lineItems.filter((li) => li.endDate != null);
    if (withEndDates.length > 0) {
      endDate = new Date(Math.max(...withEndDates.map((li) => parseISO(li.endDate!).getTime())));
    }
  }
  const currency = lineItems.length > 0 ? lineItems[0].currency : null;

  // pull Opportunity data when applicable
  const {
    isLoading: opportunityLoading,
    isError: opportunityError,
    result: opportunity,
  } = useSalesforceOpportunity(props.contract.opportunityId);

  const { type } = useQueryParams();
  const isRenewal = props.contract.dealTypes[0] === "renewal" || type === "renewal";

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <OptionContentBox
          header="Contract overview"
          contractId={props.contract.id?.toString() as any}
          accountId={props.contract.accountId}
          shouldShowRenewButton={props.shouldShowRenewButton}
        >
          {props.errors && (
            <Grid item xs={12}>
              <ValidationErrors title="Contract invalid" errors={props.errors} />
            </Grid>
          )}
          {props.contract && (
            <Grid item xs={12}>
              <ContractWarnings contract={props.contract} />
            </Grid>
          )}
          <Grid container spacing={4}>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <StringEditableField
                  label="Name"
                  value={props.contract.name}
                  required
                  onChange={(v) => onChange({ name: v })}
                  editable={canChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StringEditableField
                  label="Opportunity ID"
                  value={props.contract.opportunityId}
                  required
                  onChange={(v) => onChange({ opportunity_id: v })}
                  editable={canChange}
                  render={(displayValue: string) => (
                    <SalesforceLink sfdcReference={props.contract.opportunityId}>{displayValue}</SalesforceLink>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {opportunity ? (
                  <OpportunityInfo
                    opportunity={opportunity}
                    opportunityError={opportunityError}
                    opportunityLoading={opportunityLoading}
                  />
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12}>
                <DateEditableField
                  label="Start date"
                  value={
                    props.contract.startDate || (startDate ? formatISO(startDate, { representation: "date" }) : null)
                  }
                  editable={false}
                />
              </Grid>
              <Grid item xs={12}>
                <DateEditableField
                  label="End date"
                  value={props.contract.endDate || (endDate ? formatISO(endDate, { representation: "date" }) : null)}
                  editable={false}
                />
              </Grid>
              <Grid item xs={12}>
                <DateEditableField
                  label="Close date"
                  value={props.contract.closeDate}
                  onChange={(v) => onChange({ closeDate: v })}
                  editable={canChange}
                />
                <CompareSfdcMatch
                  value={props.contract.closeDate}
                  expected={opportunity?.closeDate}
                  isRenewal={isRenewal}
                />
              </Grid>
              <Grid item xs={12}>
                {/* dealTypes is an array but we currently only allow single selection */}
                <ChoiceEditableField
                  label="Deal type"
                  value={isRenewal ? "renewal" : props.contract.dealTypes[0]}
                  choices={dictionary.DEAL_TYPES}
                  onChange={(v) => onChange({ dealTypes: [v] })}
                  editable={canChange}
                />
                {
                  // don't show Sync for Renewal contracts here (UT-6380)
                  props.contract.dealTypes[0] !== ("renewal" as DealType) && (
                    <CompareSfdcMatch
                      value={props.contract.dealTypes[0] === "upsell" ? "upsell_new" : props.contract.dealTypes[0]}
                      expected={opportunity?.type ? DEAL_TYPE_MAPPING[opportunity?.type] : undefined}
                      isRenewal={isRenewal}
                    />
                  )
                }
              </Grid>
              <Grid item xs={12}>
                <ContractEditableField
                  label="Parent contract"
                  value={props.contract.parentContract}
                  contracts={contracts as any}
                  onChange={(v) => onChange({ parentContract: v })}
                  editable={canChange}
                  render={(displayValue: string) => (
                    <ContractLink accountId={props.accountId} contractId={props.contract.parentContract}>
                      {displayValue}
                    </ContractLink>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StringEditableField
                  label="Comment"
                  value={props.contract.comment}
                  onChange={(v) => onChange({ comment: v })}
                  editable={canChange}
                />
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <ChoiceEditableField
                  label="Delivery country"
                  value={props.contract.deliveryCountry}
                  choices={Object.fromEntries(dictionary.COUNTRIES.map((x) => [x, x]))}
                  onChange={(v) => onChange({ deliveryCountry: v, deliveryState: null })}
                  editable={canChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ChoiceEditableField
                  label="Delivery state"
                  value={props.contract.deliveryState}
                  choices={Object.fromEntries(
                    (dictionary.STATES[props.contract.deliveryCountry] || []).map((x) => [x, x])
                  )}
                  onChange={(v) => onChange({ deliveryState: v })}
                  editable={canChange && dictionary.STATES.hasOwnProperty(props.contract.deliveryCountry)}
                />
              </Grid>
              <Grid item xs={12}>
                <ChoiceEditableField
                  label="Contracting entity"
                  value={props.contract.contractingEntity}
                  choices={Object.fromEntries(dictionary.CONTRACTING_ENTITIES.map((x) => [x, x]))}
                  onChange={(v) => onChange({ contractingEntity: v })}
                  editable={canChange}
                />
                <CompareSfdcMatch
                  value={props.contract.contractingEntity}
                  expected={
                    opportunity?.contractingEntity
                      ? CONTRACTING_ENTITY_MAPPING[opportunity?.contractingEntity]
                      : undefined
                  }
                  isRenewal={isRenewal}
                />
              </Grid>
              <Grid item xs={12}>
                <DecimalEditableField
                  label="Total contract value"
                  value={props.contract.tcv}
                  currency={currency}
                  editable={false}
                />
              </Grid>
              <Grid item xs={12}>
                <DecimalEditableField
                  label="Annual contract value"
                  value={props.contract.acv}
                  currency={currency}
                  editable={false}
                />
              </Grid>
              <CompareSfdcMatch
                value={props.contract.acv ?? "0.00"}
                expected={opportunity?.amount?.toFixed(2) ?? "0.00"}
                isRenewal={isRenewal}
              />
              <Grid item xs={12}>
                <IntegerEditableField
                  label="Notice Period in days"
                  value={props.contract.noticePeriod}
                  editable={canChange}
                  onChange={(v: number | null) => onChange({ noticePeriod: v })}
                />
              </Grid>
              <CompareSfdcMatch
                value={props.contract.noticePeriod}
                expected={
                  opportunity?.syncedQuote.noticePeriod === "None" ? null : opportunity?.syncedQuote.noticePeriod
                }
                isRenewal={isRenewal}
              />
              <Grid item xs={12}>
                <div>
                  <Typography variant="h6">
                    <b>Auto-Renew</b>
                  </Typography>
                  <Switch
                    checked={!!props.contract.autoRenew}
                    disabled={!canChange}
                    onChange={(v) => {
                      onChange({ autoRenew: !props.contract.autoRenew });
                    }}
                  />
                </div>
              </Grid>
              <CompareSfdcMatch
                value={props.contract.autoRenew ? "Yes" : "No"}
                expected={opportunity?.autoRenewal}
                isRenewal={isRenewal}
              />
              <Grid item xs={12}>
                <ChoiceEditableField
                  label="Auto Price Increase"
                  value={props.contract.autoPriceIncrease}
                  choices={dictionary.AUTO_PRICE_INCREASES}
                  onChange={(v) => onChange({ autoPriceIncrease: v })}
                  editable={canChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ChoiceEditableField
                  label="Renewal Term Length"
                  value={props.contract.renewalTermLength}
                  choices={dictionary.RENEWAL_TERM_LENGTHS}
                  onChange={(v) => onChange({ renewalTermLength: v })}
                  editable={canChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ChoiceEditableField
                  label="Price Increase Allowance"
                  value={props.contract.priceIncreaseAllowance}
                  choices={dictionary.PRICE_INCREASE_ALLOWANCES}
                  onChange={(v) => onChange({ priceIncreaseAllowance: v })}
                  editable={canChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </OptionContentBox>
      </Grid>

      <Grid item xs={12}>
        <ContractDocuments
          onNewDocument={(document) =>
            onChange({
              documents: props.contract.documents.concat([document]),
            })
          }
          onEditDocument={() => {
            onChange({
              documents: props.contract.documents,
            });
          }}
          onDeleteDocument={(index) => {
            props.contract.documents.splice(index, 1);
            onChange({
              documents: props.contract.documents,
            });
          }}
          documents={props.contract.documents}
        />
      </Grid>
    </Grid>
  );
}
