import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";

export default function Label(props: {
  label: string;
  color: string;
  filled?: boolean;
  icon?: typeof SvgIcon;
  tooltip?: string;
}): JSX.Element {
  const height = 20;
  const IconComponent = props.icon;
  const label = (
    <div
      style={{
        border: "2px solid",
        borderColor: props.color,
        backgroundColor: props.filled ? props.color : "#fff",
        display: "inline-flex",
        padding: "3px 7px",
        fontWeight: "bold",
        borderRadius: "10px",
        width: "max-content",
        color: props.filled ? "#fff" : props.color,
        margin: "0 1px",
      }}
    >
      {IconComponent && <IconComponent sx={{ color: props.color, height }} />}
      <span style={{ lineHeight: `${height}px`, marginLeft: "3px" }}>{props.label}</span>
    </div>
  );

  if (props.tooltip) {
    return <Tooltip title={props.tooltip}>{label}</Tooltip>;
  } else {
    return label;
  }
}
