import * as React from "react";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { Controller, FieldValues } from "react-hook-form";
import formatISO from "date-fns/formatISO";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import FormControl from "@mui/material/FormControl";
import type { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import { usePreferences } from "../service/preferences";

interface CustomDatePickerProps<TFieldValues> {
  // configuration for nested components which is always required
  control: Required<UseControllerProps<TFieldValues>["control"]>;
  name: string;

  rules?: {
    required?: boolean;
  };

  label?: string;
  default?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  helperText?: string;
}

type DatePickerProps<TFieldValues> = CustomDatePickerProps<TFieldValues> & UseControllerProps<TFieldValues>;

const DatePicker = <TFieldValues extends FieldValues>(props: DatePickerProps<TFieldValues>) => {
  const { dateFormat } = usePreferences();

  const defaultValue: UseControllerProps["defaultValue"] = {
    [props.name]: props.default,
  };

  return (
    <Controller
      name={props.name}
      defaultValue={defaultValue}
      control={props.control}
      rules={props.rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth>
            <DesktopDatePicker
              minDate={props.minDate}
              maxDate={props.maxDate}
              label={props.label}
              value={value}
              disabled={props.disabled}
              onChange={(newValue: Date | null) => {
                if (newValue === null) {
                  onChange("");
                  return;
                }

                try {
                  onChange(formatISO(newValue, { representation: "date" }));
                } catch (e: any) {
                  onChange("");
                }
              }}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  required={props.rules?.required}
                  helperText={error ? error.message || "Please provide a valid value." : props.helperText || null}
                />
              )}
              // Forcibly disable masking - Manually specifying an inputFormat causes it
              // to be disabled anyway, but also throw a lot of warnings.
              mask={undefined}
              inputFormat={dateFormat}
            />
          </FormControl>
        );
      }}
    />
  );
};

export { DatePicker };
