import * as React from "react";
import { Link } from "react-router-dom";
import { ObjectsTable } from "../../../components/ObjectsTable";
import { Account, useAccounts } from "../../../service/accounts";
import { useState } from "react";
import { config } from "../../../config";
import { SalesforceAccount, useSalesforceAccounts, useSalesforceAccountsSearch } from "../../../salesforce/accounts";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";

import { ContentBox } from "../../../components/ContentBox";
import debounce from "lodash/debounce";
import { SalesforceLink } from "../../../components/SalesforceLink";

const SEARCH_DEBOUNCE_MS = 200;

function SearchField(props: { onSearch: (q: string) => void }): JSX.Element {
  const [query, setQuery] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const doSearch = (value: string) => {
    setError(null);
    if (value != "" && value.length < 3) {
      setError("Please enter at least 3 characters.");
      return;
    }

    if (value != query) {
      setQuery(value);
      props.onSearch(value);
    }
  };

  const debounced = debounce(doSearch, SEARCH_DEBOUNCE_MS);

  return (
    <TextField
      onChange={(e) => debounced(e.target.value)}
      InputLabelProps={{ shrink: true }}
      error={!!error}
      helperText={error ? error : null}
      label="Search"
      type="search"
    />
  );
}

interface ResultItem {
  id: string;
  name?: string | null;
}

function makeResultItems(sf: { [id: string]: SalesforceAccount } | null, accounts?: Account[]): ResultItem[] {
  if (accounts) {
    return accounts.map((a) => ({ id: a.id, name: sf ? (sf[a.id] ? sf[a.id].name : null) : undefined }));
  }
  return Object.values(sf || {}).map((a) => ({ id: a.id, name: a.name }));
}

function ResultList(props: {
  page: number;
  setPage: (p: number) => void;
  isLoading: boolean;
  isError: boolean;
  results: ResultItem[];
  totalCount: number;
}): JSX.Element {
  const columns = [
    { id: "id", label: "ID", minWidth: 170 },
    { id: "name", label: "Name", minWidth: 250 },
  ];

  function idColumn(item: ResultItem): JSX.Element | string {
    return (
      <SalesforceLink sfdcReference={item.id}>
        <MuiLink component={Link} color="primary" to={`/accounts/${item.id}`}>
          {item.id}
        </MuiLink>
      </SalesforceLink>
    );
  }

  function nameColumn(item: ResultItem): JSX.Element | string {
    if (item.name === undefined) return <CircularProgress size="15px" />;
    return item.name || "-";
  }

  const rows = props.results
    ? props.results.map((item) => ({
        id: item.id,
        values: [idColumn(item), nameColumn(item)],
      }))
    : [];

  return (
    <ObjectsTable
      isLoading={props.isLoading}
      isError={props.isError}
      columns={columns}
      rows={rows}
      totalRows={props.totalCount}
      pageLimit={config.servicePaginationLimit}
      page={props.page}
      onChange={props.setPage}
    />
  );
}

function SearchedResultList(props: { search: string }): JSX.Element {
  const [page, setPage] = useState<number>(1);
  // if the search is an 18-character long string with letters & numbers, we can match SFDC Id
  // otherwise, there is no way to directly query for "startswith" as the SFDC
  // primitive for Id is not String, but Id.

  const {
    isLoading: accountsLoading,
    isError: accountsError,
    resultById: accountsById,
    count: total,
  } = useSalesforceAccountsSearch(props.search);

  let results;
  if (accountsById) results = makeResultItems(accountsById);
  return (
    <ResultList
      setPage={setPage}
      page={page}
      results={results || []}
      isError={accountsError}
      isLoading={accountsLoading}
      totalCount={total || 0}
    />
  );
}

function DefaultResultList(props: {}): JSX.Element {
  const [page, setPage] = useState<number>(1);

  const {
    isLoading: accountsLoading,
    isError: accountsError,
    resultById: accountsById,
    count: total,
  } = useSalesforceAccounts(page);
  let results;
  if (accountsById) results = makeResultItems(accountsById);
  return (
    <ResultList
      setPage={setPage}
      page={page}
      results={results || []}
      isError={accountsError}
      isLoading={accountsLoading}
      totalCount={total || 0}
    />
  );
}

export function List(): JSX.Element {
  const [search, setSearch] = useState<string>("");

  return (
    <ContentBox header="Accounts">
      <Toolbar>
        <Grid container>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <SearchField onSearch={setSearch} />
            </FormControl>
          </Grid>
        </Grid>
      </Toolbar>
      {search && search.length > 0 ? (
        <SearchedResultList search={search} />
      ) : (
        <>
          <Alert severity="info">
            This list shows only accounts with matching contracts in SubMS. Use search to access all accounts from
            Salesforce.
          </Alert>
          <DefaultResultList />
        </>
      )}
    </ContentBox>
  );
}
